import * as React from 'react';
import InputRange, { Range } from 'react-input-range';
import './tables-filter.css';

export interface InputRangeComponentProps {
  maxValue: number;
  minValue: number;
  value: Range;
  onChange: (value: Range | number) => void;
}

const InputRangeComponent: React.FC<InputRangeComponentProps> = ({ maxValue, minValue, value, onChange }) => {
  const stepValue = React.useMemo(() => {
    if (maxValue < 0.2) return 0.001;
    if (maxValue < 10) return 0.1;
    if (maxValue < 10) return 1000;
  }, [maxValue]);

  return (
    <div className="input-range-container my-3 p-3">
      <div className="labels">
        <span className="upper-label" style={{ left: `${((value.max - minValue) / (maxValue - minValue)) * 100}%` }}>
          {value.max}
        </span>
        <span className="lower-label" style={{ left: `${((value.min - minValue) / (maxValue - minValue)) * 100}%` }}>
          {value.min}
        </span>
      </div>
      <InputRange
        maxValue={maxValue}
        minValue={minValue}
        value={value}
        onChange={onChange}
        step={stepValue}
        formatLabel={() => ''}
      />
    </div>
  );
};

export default InputRangeComponent;
