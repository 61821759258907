import React, { useMemo, useState } from 'react';
import { Alert, Card, CardBody, CardHeader, CardTitle, Col, Container, Input, Label, Row } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchUserByIdRest, fetchUsersRest } from '@/services/users/users-rest';
import { UserInfo } from '@/services/users/user-types';
import agreementsConfig from '@/common/agreements';
import { Agreement, AgreementsStrategyConfig } from '@/common/agreements/agreements-config.interface';
import { postAgreementRest } from '@/services/agreement/agreement-service';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '@/common/showToast';
import { useNavigate } from 'react-router-dom';
import InfoField from '@/common/forms/InfoField';
import FormFields from '@/common/forms/FormFields';
import routes from '@/routes/routes-enums';

const formatISOToDate = (isoString: Date | string | undefined, full = false) => {
  if (!isoString || String(isoString) === '') return '';
  const newDate = new Date(String(isoString));
  return newDate.toISOString().split('T')[0] + (full ? ` ${newDate.toTimeString().split(' ')[0]}` : '');
};

const AgreementsCreate: React.FC = () => {
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState<UserInfo | null>(null);
  const [selectedAgreement, setSelectedAgreement] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    data: userDetails,
    isLoading: isUserLoading,
    refetch: refetchUserDetails,
  } = useQuery({
    queryKey: ['userDetails', selectedUser?.id],
    queryFn: () => (selectedUser ? fetchUserByIdRest(selectedUser.id) : null),
    enabled: !!selectedUser,
  });

  const loadOptions = async (inputValue: string): Promise<{ value: string; label: string; user: UserInfo }[]> => {
    if (inputValue.length < 2) return [];
    const result = await fetchUsersRest({
      skip: 0,
      take: 25,
      alias: inputValue,
    });
    return result.users.map((user) => ({
      value: user.id,
      label: `${user.alias} ${user.email}`,
      user,
    }));
  };

  const handleUserChange = (selectedOption: { value: string; label: string; user: UserInfo } | null): void => {
    setSelectedUser(selectedOption ? selectedOption.user : null);
  };

  const filteredAgreements = useMemo(() => {
    if (!userDetails) return {};
    return Object.entries(agreementsConfig).reduce((acc: AgreementsStrategyConfig, [key, agreement]) => {
      if ((userDetails.isAgent && key.includes('agent')) || (userDetails.isAmbassador && key.includes('ambassador'))) {
        acc[key] = agreement;
      }
      return acc;
    }, {});
  }, [userDetails]);

  const handleCreateAgreement = async () => {
    if (selectedUser && selectedAgreement) {
      setIsSubmitting(true);

      postAgreementRest({ agreement: selectedAgreement, userId: selectedUser.id })
        .then(() => {
          showToast('Agreement created successfully', 'success');
        })
        .catch((error) => {
          showToast(error.response.data.message, 'warning');
        });
      await refetchUserDetails(); // Refetch user details
      await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'agreementData',
      });
      setIsSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="container-fluid">
          <Row className="gy-2 mb-2">
            <div>
              <div>
                <Card className="card mb-2">
                  <CardHeader>
                    <CardTitle>Create User Agreement</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="space-y-4">
                      <Col lg={10}>
                        <div className="mb-5">
                          <Label htmlFor="user-select">Select User by alias</Label>
                          <AsyncSelect
                            cacheOptions
                            loadOptions={loadOptions}
                            onChange={handleUserChange}
                            placeholder="Search for a user..."
                            styles={{
                              control: (base) => ({
                                ...base,
                                color: 'black',
                                background: 'white',
                              }),
                              input: (base) => ({
                                ...base,
                                color: 'black',
                                background: 'white',
                              }),
                              option: (base) => ({
                                ...base,
                                color: 'black',
                                background: 'white',
                              }),
                            }}
                          />
                        </div>
                      </Col>

                      {isUserLoading && <p>Loading user details...</p>}

                      {userDetails && (
                        <Card className="mb-5">
                          <CardHeader>
                            <CardTitle>User Details</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <FormFields className="mb-4">
                              <InfoField name={'Name'} value={`${userDetails.firstName}`} />
                              <InfoField name={'Last Name'} value={`${userDetails.lastName}`} />
                            </FormFields>
                            <FormFields className="mb-4">
                              <InfoField name={'Alias'} value={`${userDetails.alias}`} />
                              <InfoField name={'Email'} value={`${userDetails.email}`} />
                            </FormFields>

                            {!(userDetails.isAgent || userDetails.isAmbassador) ? (
                              <>
                                <button
                                  onClick={() => navigate(`${routes.USER_EDIT}/${userDetails.id}`)}
                                  className="btn btn-primary"
                                >
                                  Update
                                </button>{' '}
                                user profile to assign a role
                              </>
                            ) : (
                              <>
                                <FormFields className="mb-4">
                                  <InfoField
                                    name={'Role'}
                                    value={
                                      userDetails.isAgent
                                        ? ' Agent '
                                        : userDetails.isAmbassador
                                          ? ' Ambassador '
                                          : ' User has no role assigned. '
                                    }
                                  />
                                </FormFields>

                                {userDetails?.userAgreements?.length > 0 ? (
                                  userDetails.userAgreements.map((agreement, index) => (
                                    <FormFields className="mb-1" key={index}>
                                      <InfoField name={'Agreement'} value={agreement.agreement} />
                                      <InfoField name={'Create At'} value={formatISOToDate(agreement.createdAt)} />
                                    </FormFields>
                                  ))
                                ) : (
                                  <FormFields className="mb-1">
                                    <Col lg={5}>
                                      <Alert>No agreements found</Alert>
                                    </Col>
                                  </FormFields>
                                )}

                                <Col lg={10}>
                                  <div className="my-5">
                                    <Label htmlFor="agreement-select">Select Agreement</Label>
                                    <Input
                                      type="select"
                                      id="agreement-select"
                                      value={selectedAgreement}
                                      onChange={(e) => setSelectedAgreement(e.target.value)}
                                      className="mb-3"
                                    >
                                      <option value="">Select an agreement type</option>
                                      {Object.entries(filteredAgreements).map(
                                        ([key, agreement]: [string, Agreement]) => (
                                          <option key={key} value={key}>
                                            {agreement.name}
                                          </option>
                                        ),
                                      )}
                                    </Input>
                                    <div className="w-100 d-flex justify-content-end gap-2">
                                      <button
                                        className="btn btn-primary"
                                        onClick={handleCreateAgreement}
                                        disabled={isSubmitting}
                                      >
                                        {isSubmitting ? 'Creating...' : 'Create'}
                                      </button>
                                    </div>
                                  </div>
                                </Col>
                              </>
                            )}
                          </CardBody>
                        </Card>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default AgreementsCreate;
