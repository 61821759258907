import { useEffect } from 'react';
// import { InheritanceTooltip } from "./InheritanceTooltip";
import { displayTxResult } from './utilsDisplay';
import { Abi, AbiFunction } from 'abitype';
import { Address } from 'viem';
import { useReadContract } from 'wagmi';
import { Spinner } from 'reactstrap';

interface DisplayVariableProps {
  contractAddress: Address;
  abiFunction: AbiFunction;
  refreshDisplayVariables: boolean;
  inheritedFrom?: string;
  abi: Abi;
}

export const DisplayVariable = ({
  contractAddress,
  abiFunction,
  refreshDisplayVariables,
  abi,
  inheritedFrom: _inheritedFrom,
}: DisplayVariableProps) => {
  const {
    data: result,
    isFetching,
    refetch,
  } = useReadContract({
    address: contractAddress,
    functionName: abiFunction.name,
    abi: abi,

    // onError: (error) => {
    //   notification.error(error.message);
    // },
  });

  useEffect(() => {
    refetch();
  }, [refetch, refreshDisplayVariables]);

  return (
    <div className="space-y-1 pb-2">
      <div className="d-flex align-items-center gap-1">
        <p className="mb-0 break-all text-lg font-medium">{abiFunction.name}</p>

        {isFetching ? (
          <Spinner size="sm" />
        ) : (
          <i
            className="ri-refresh-line ri-lg cursor-pointer"
            aria-hidden="true"
            onClick={async () => await refetch()}
          />
        )}

        {/* <InheritanceTooltip inheritedFrom={inheritedFrom} /> */}
      </div>
      <div className="flex flex-col items-start font-medium text-gray-500">
        <div>
          <div className="block break-all bg-transparent transition">{displayTxResult(result)}</div>
        </div>
      </div>
    </div>
  );
};
