import { combineReducers } from 'redux';

import LayoutReducer from './layouts/reducer';

const rootReducer = combineReducers({
  Layout: LayoutReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
