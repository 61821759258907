import React, { FC } from 'react';
import { Input, Col, Label } from 'reactstrap';

interface InfoFieldProps {
  name: string;
  value?: any;
  className?: string;
  lg?: number;
  prefix?: string;
}

const InfoField: FC<InfoFieldProps> = ({ name, value, className, lg = 5, prefix = undefined }) => {
  const formatValue = (val: any): string => {
    if (val === null || val === undefined) {
      return '';
    }
    if (typeof val === 'string' || typeof val === 'number') {
      return String(val);
    }
    if (Array.isArray(val)) {
      return val.map(formatValue).join(', ');
    }
    if (typeof val === 'object') {
      return Object.entries(val)
        .map(([key, value]) => `${key}: ${formatValue(value)}`)
        .join(', ');
    }
    return JSON.stringify(val);
  };

  return (
    <Col lg={lg} sm={12} className={className}>
      <div>
        <Label htmlFor={name} className="form-label">
          {name}
        </Label>
        <div className="input-group">
          {prefix && <span className="input-group-text">{prefix}</span>}
          <Input type="text" className="form-control" id={name} value={formatValue(value)} readOnly />
        </div>
      </div>
    </Col>
  );
};

export default InfoField;
