import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import { fetchUsersCount, fetchNewUsersByDay } from '@/services/users/users-rest';
import { UserQueryDto } from '@/services/users/user-types';

import { AxiosError } from 'axios';
import { showToast } from '@/common/showToast';
import CountUp from 'react-countup';
import UserGrowthChart from './UserGrowthChart';

interface UserGrowthProps {
  fromDate: string;
  toDate: string;
  groupByDate: boolean;
}

const ChartHeader = ({ name, value, theme = '' }: { name: string; value: number; theme?: string }) => {
  return (
    <div className="border-start-0 flex-fill border border-dashed p-3">
      <h5 className={'mb-1 ' + theme}>
        <CountUp start={0} end={value} duration={3} separator="," />
      </h5>
      <p className="text-muted mb-0">{name}</p>
    </div>
  );
};

const UserGrowth: React.FC<UserGrowthProps> = ({ fromDate, toDate, groupByDate }) => {
  const params: UserQueryDto = { fromDate, toDate, groupByDate };

  const { data: usersCount, error: usersCountError } = useQuery({
    queryKey: ['usersCount', params],
    queryFn: () => fetchUsersCount(params),
  });

  const { data: newUsersByDay, error: newUsersByDayError } = useQuery({
    queryKey: ['newUsersByDay', params],
    queryFn: () => fetchNewUsersByDay(params),
  });

  if (usersCountError || newUsersByDayError) {
    const errorMessage =
      (usersCountError as AxiosError)?.message ||
      (newUsersByDayError as AxiosError)?.message ||
      'Failed to fetch data.';
    showToast(`Error when fetching server data: ${errorMessage}`, 'warning');
  }

  return (
    <Card>
      <CardHeader className="d-flex border-0 align-middle">
        <h4 className="card-title flex-grow-1 mb-0">User's Growth</h4>
      </CardHeader>
      <CardHeader className="d-flex border-0 align-middle">
        <Row className="g-0 w-100 text-center">
          <div className="d-flex w-100 flex-row">
            <ChartHeader name="Total Users" value={usersCount?.usersCount ?? 0} />
            <ChartHeader name="Ambassadors" value={usersCount?.ambassadorsCount ?? 0} />
            <ChartHeader name="Agents" value={usersCount?.agentsCount ?? 0} />
            <ChartHeader name="Today's new" value={usersCount?.todayNew ?? 0} theme="text-success" />
          </div>
        </Row>
      </CardHeader>
      <CardBody className="p-0 pb-2">
        <div className="w-100">
          <div dir="ltr">
            <UserGrowthChart data={newUsersByDay ?? []} />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default UserGrowth;
