import { api } from '../api';

export const getTokenBalanceRest = async (balanceAddress: string) => {
  const response = await api.get<{ balance: string }>(`v1/token/balance/${balanceAddress}`);
  return response.data;
};

export const transferPHNMTotreasuryRest = async () => {
  const response = await api.post<{ balance: string }>(`v1/admin/redemption/transfer-phnm-to-treasury`);
  return response.data;
};
