import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from '@/routes/routes-enums';

const Navdata = () => {
  const history = useNavigate();

  const [isAnalytics, setIsAnalytics] = useState<boolean>(true);
  const [isRake, setIsRake] = useState<boolean>(true);
  const [isTables, setIsTables] = useState<boolean>(true);
  const [isUser, setIsUser] = useState<boolean>(true);
  const [isTransactions, setIsTransactions] = useState<boolean>(true);
  const [isTokens, setIsTokens] = useState<boolean>(true);

  const [isCurrentState, setIsCurrentState] = useState('Analytics');

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute('sub-items')) {
      const ul: any = document.getElementById('two-column-menu');
      const iconItems: any = ul.querySelectorAll('.nav-icon.active');
      const activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove('active');
        const id = item.getAttribute('sub-items');
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove('show');
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel');

    if (isCurrentState !== 'Rake') {
      setIsRake(false);
    }
    if (isCurrentState !== 'Analytics') {
      setIsAnalytics(false);
    }
    if (isCurrentState !== 'Tables') {
      setIsTables(false);
    }
    if (isCurrentState !== 'User') {
      setIsUser(false);
    }
    if (isCurrentState !== 'Transactions') {
      setIsTransactions(false);
    }
    if (isCurrentState !== 'Tokens') {
      setIsTokens(false);
    }
  }, [history, isCurrentState, isAnalytics, isRake, isTables, isUser, isTransactions]);

  const menuItems: any = [
    {
      label: 'Menu',
      isHeader: true,
    },
    {
      id: 'anayltics',
      label: 'Analytics',
      icon: 'ri-dashboard-2-line',
      link: '/#',
      stateVariables: isAnalytics,
      click: function (e: any) {
        e.preventDefault();
        // setIsRake(!isAnalytics);
        setIsCurrentState('Analytics');
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'tableAnalytics',
          label: 'Overview',
          link: routes.ROOT,
          parentId: 'dashboard',
        },
      ],
    },
    {
      id: 'tables',
      label: 'Tables',
      icon: 'ri-apps-2-line',
      link: '/#',
      click: function (e: any) {
        e.preventDefault();
        // setIsTables(!isTables);
        setIsCurrentState('Tables');
        updateIconSidebar(e);
      },
      stateVariables: isTables,
      subItems: [
        {
          id: 'tableAnalytics',
          label: 'Overview',
          link: routes.TABLE_ANALYTICS,
          parentId: 'tables',
        },
        {
          id: 'tableHands',
          label: 'Hand history',
          link: routes.TABLE_HANDS,
          parentId: 'tables',
        },
        {
          id: 'tables',
          label: 'Tables',
          link: routes.TABLES,
          parentId: 'tables',
        },
        // {
        //   id: "tables",
        //   label: "Templates",
        //   link: routes.TEMPLATES,
        //   parentId: "tables",
        // },
        {
          id: 'parametersRake',
          label: 'Blind Structures',
          link: routes.PARAMETERS_RAKE_STRUCTURE,
          parentId: 'tables',
        },
        {
          id: 'parametersGameParams',
          label: 'Mixed Games',
          link: routes.PARAMETERS_MIXED_GAMES_STRUCTURE,
          parentId: 'tables',
        },
        {
          id: 'tableSync',
          label: 'Table Sync',
          link: routes.TABLE_SYNC,
          parentId: 'tables',
        },
        {
          id: 'contractSync',
          label: 'Contract Sync',
          link: routes.CONTRACT_SYNC,
          parentId: 'tables',
        },
      ],
    },
    {
      id: 'users',
      label: 'Users',
      icon: 'ri-account-circle-line',
      link: '/#',
      click: function (e: any) {
        e.preventDefault();
        // setIsUser(!isUser);
        setIsCurrentState('User');
        updateIconSidebar(e);
      },
      stateVariables: isUser,
      subItems: [
        {
          id: 'userAnalytics',
          label: 'Overview',
          link: routes.USER_ANALYTICS,
          parentId: 'users',
        },
        {
          id: 'userList',
          label: 'Users',
          link: routes.USERS,
          parentId: 'users',
        },
        {
          id: 'propPlayers',
          label: 'Prop Players',
          link: routes.PROP_PLAYERS,
          parentId: 'users',
        },
        {
          id: 'agreemments',
          label: 'Agreements',
          link: routes.AGREEMENTS,
          parentId: 'users',
        },
        {
          id: 'deals',
          label: 'Deals',
          link: routes.USER_DEALS,
          parentId: 'users',
        },
      ],
    },
    {
      id: 'rake',
      label: 'Rake',
      icon: 'ri-money-dollar-circle-line',
      link: '/#',
      stateVariables: isRake,
      click: function (e: any) {
        e.preventDefault();
        // setIsRake(!isRake);
        setIsCurrentState('Rake');
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'rakeList',
          label: 'Overview',
          link: routes.RAKE_ANALYTICS,
          parentId: 'rake',
        },
        {
          id: 'rakeList',
          label: 'Table Rake',
          link: routes.RAKE_TABLE,
          parentId: 'rake',
        },
        {
          id: 'rakeList',
          label: 'Player Rake',
          link: routes.RAKE_PLAYER,
          parentId: 'rake',
        },
        {
          id: 'rakeList',
          label: 'Rake Share',
          link: routes.RAKE_SHARE,
          parentId: 'rake',
        },
        {
          id: 'agreemments',
          label: 'Rake Back',
          link: routes.RAKE_BACK,
          parentId: 'rake',
        },
        {
          id: 'agreemments',
          label: 'Rewards',
          link: routes.RAKE_REWARDS,
          parentId: 'rake',
        },
      ],
    },
    {
      id: 'transactions',
      label: 'Transactions',
      icon: 'ri-exchange-box-line',
      link: '/#',
      stateVariables: isTransactions,
      click: function (e: any) {
        e.preventDefault();
        // setIsTransactions(!isTransactions);
        setIsCurrentState('Transactions');
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'transactiosnList',
          label: 'Transactions',
          link: routes.TRANSACTIONS,
          parentId: 'transactions',
        },
      ],
    },
    {
      id: 'tokens',
      label: 'Tokens',
      icon: 'ri-nft-line',
      link: '/#',
      stateVariables: isTokens,
      click: function (e: any) {
        e.preventDefault();
        // setIsTransactions(!isTransactions);
        setIsCurrentState('Tokens');
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'tokenFlowOrchestrator',
          label: 'Token Flow Wizard',
          link: routes.TOKEN_FLOW_ORCHESTRATOR_WIZARD,
          parentId: 'tokens',
        },
        {
          id: 'contractBalances',
          label: 'Contract Balances',
          link: routes.CONTRACT_BALANCES,
          parentId: 'tokens',
        },
      ],
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
