import React from 'react';
import { Card, CardHeader, CardBody, Spinner } from 'reactstrap';
import RakeOverviewChart from '@/pages/analytics/components/RakeOverviewChart';
import { useQuery } from '@tanstack/react-query';
import { fetchAggregatedRakeRest } from '@/services/rake/rake-service';
import { QueryPeriods } from '@/common/QueryPeriods';

const DailyGeneratedRake: React.FC<{
  fromDate: string;
  toDate: string;
  period: QueryPeriods;
  groupByDate: boolean;
}> = ({ fromDate, toDate, period, groupByDate }) => {
  const { data: rake, error } = useQuery({
    queryKey: ['rakeData', { fromDate, toDate, period, groupByDate }],
    queryFn: () =>
      fetchAggregatedRakeRest({
        fromDate,
        toDate,
        period,
        groupByDate,
      }),
  });

  return (
    <Card>
      <CardHeader className="d-flex flex-rows justify-content-between align-middle">
        <h4 className="card-title mb-0">Daily generated rake</h4>
      </CardHeader>
      <CardBody>
        {rake ? <RakeOverviewChart dataColors={['#A046D3']} ts={rake} /> : <Spinner />}
        {error && <div className="text-danger">Failed to load data</div>}
      </CardBody>
    </Card>
  );
};

export default DailyGeneratedRake;
