import { DisplayVariable } from './DisplayVariable';
import { Abi, AbiFunction } from 'abitype';
import { Contract, ContractName, GenericContract, InheritedFunctions } from '@/common/utils/contract';

export const ContractVariables = ({
  refreshDisplayVariables,
  deployedContractData,
  variablesToShow,
}: {
  refreshDisplayVariables: boolean;
  deployedContractData: Contract<ContractName>;
  variablesToShow?: string[];
}) => {
  if (!deployedContractData) {
    return null;
  }

  const functionsToDisplay = (
    (deployedContractData.abi as Abi).filter((part) => part.type === 'function') as AbiFunction[]
  )
    .filter((fn) => {
      const isQueryableWithNoParams =
        (fn.stateMutability === 'view' || fn.stateMutability === 'pure') && fn.inputs.length === 0;
      const isInVariablesToShow = !variablesToShow || variablesToShow.includes(fn.name);
      return isQueryableWithNoParams && isInVariablesToShow;
    })
    .map((fn) => {
      return {
        fn,
        inheritedFrom: ((deployedContractData as GenericContract)?.inheritedFunctions as InheritedFunctions)?.[fn.name],
      };
    })
    .sort((a, b) => (b.inheritedFrom ? b.inheritedFrom.localeCompare(a.inheritedFrom) : 1));

  if (!functionsToDisplay.length) {
    return <>No contract variables</>;
  }

  return (
    <>
      {functionsToDisplay.map(({ fn, inheritedFrom }) => (
        <DisplayVariable
          abi={deployedContractData.abi as Abi}
          abiFunction={fn}
          contractAddress={deployedContractData.address}
          key={fn.name}
          refreshDisplayVariables={refreshDisplayVariables}
          inheritedFrom={inheritedFrom}
        />
      ))}
    </>
  );
};
