import React from 'react';
import { useBalance } from 'wagmi';
import TokenBalance from './TokenBalance';
import { Address } from 'viem';

interface NativeTokenBalanceProps {
  contractAddress: Address;
  tokenAddress?: Address;
  tokenSymbol: string;
  simpleFormat?: boolean;
  decimals: number;
}

const NativeTokenBalance: React.FC<NativeTokenBalanceProps> = ({
  contractAddress,
  tokenAddress,
  simpleFormat = false,
}) => {
  const {
    data: balance,
    isLoading,
    refetch,
  } = useBalance({
    address: contractAddress,
    token: tokenAddress,
  });

  return (
    <TokenBalance
      balance={balance?.value}
      tokenSymbol={balance?.symbol}
      tokenDecimals={balance?.decimals}
      formatDecimals={2}
      isLoading={isLoading}
      onClickRefresh={() => {
        refetch();
      }}
      simpleFormat={simpleFormat}
    />
  );
};

export default NativeTokenBalance;
