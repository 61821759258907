import React from 'react';
import { formatUnits } from '@ethersproject/units'; // Corrected import for formatUnits
import { Spinner } from 'reactstrap'; // Removed unused import Placeholder
import { useReadContract } from 'wagmi';
import tables from '@/common/abis/tables.json'; // Added import for table ABI.
import { Address } from 'abitype/dist/types/abi';

const formatBalance = (balance: bigint | undefined, decimals: number) => {
  if (balance === undefined) {
    return '0';
  }
  return formatUnits(balance.toString(), decimals); // Corrected to convert bigint to string for formatUnits
};

interface TableTokenBalanceProps {
  // Corrected type name for consistency
  tableContractId: string | undefined;
  contractAddress: string | undefined;
  tokenSymbol?: string; // Made tokenSymbol optional
  decimals: number;
}

const TableTokenBalance: React.FC<TableTokenBalanceProps> = ({
  tableContractId,
  contractAddress,
  tokenSymbol: _tokenSymbol = 'USDT', // Corrected default value assignment
  decimals,
}) => {
  const contractUsdtBalance = useReadContract({
    address: contractAddress as Address,
    abi: tables.abi,
    functionName: 'tableBalance',
    args: [tableContractId],
  });

  return (
    <>
      {contractUsdtBalance.isLoading ? (
        <Spinner size="sm" />
      ) : (
        <span>{formatBalance(contractUsdtBalance.data as bigint, decimals)}</span>
      )}
    </>
  );
};

export default TableTokenBalance; // Corrected export name for consistency
