import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PrivyProvider } from '@privy-io/react-auth';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '@/services/slices';
import logo from './assets/phenom-logo.svg';
import './assets/scss/themes.scss';

import { Buffer } from 'buffer';
import { WagmiProvider } from 'wagmi';
import { config } from './wagmiConfig';
global.Buffer = global.Buffer || Buffer;

// Create a query client
const queryClient = new QueryClient();

const store = configureStore({ reducer: rootReducer, devTools: true });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <PrivyProvider
    appId={process.env.REACT_APP_PRIVY_APP_ID || ''}
    config={{
      // Display email and wallet as login methods
      loginMethods: ['email'],
      // Customize Privy's appearance in your app
      appearance: {
        theme: 'dark',
        accentColor: '#A958D7',
        logo: logo,
      },
      // Create embedded wallets for users who don't have a wallet
      embeddedWallets: {
        createOnLogin: 'off',
      },
    }}
  >
    <Provider store={store}>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <React.Fragment>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <App />
            </BrowserRouter>
          </React.Fragment>
        </QueryClientProvider>
      </WagmiProvider>
    </Provider>
  </PrivyProvider>,
);

reportWebVitals();
