import { api } from '../api';
import { BlindStructureDto, GameDto, GameParamDto } from './parameters-types';

export const blindCreateStructureRest = async (blindDto: BlindStructureDto) => {
  const response = await api.post<BlindStructureDto>(`/v1/blind-structures`, blindDto);
  return response.data;
};

export const blindUpdateStructureRest = async (rakeDto: BlindStructureDto): Promise<BlindStructureDto> => {
  const response = await api.put<BlindStructureDto>(`/v1/blind-structures/${rakeDto.id}`, rakeDto);
  return response.data;
};

export const blindStructureRest = async (rakeStructureId: string): Promise<BlindStructureDto> => {
  const response = await api.get<BlindStructureDto>(`/v1/blind-structures/${rakeStructureId}`);
  return response.data;
};

export const blindStructuresRest = async (): Promise<BlindStructureDto[]> => {
  const response = await api.get<BlindStructureDto[]>(`/v1/blind-structures`);
  return response.data;
};

export const createGameParamRest = async (params: Omit<GameParamDto, 'id'>) => {
  return await api.get<GameParamDto>(`/v1/game-params-structures`, { params });
};

export const createGameRest = async (params: Omit<GameDto, 'id'>) => {
  return await api.post<GameDto>(`/v1/mixed-games`, { ...params });
};

export const updateGameRest = async (params: GameDto) => {
  return await api.put<GameDto>(`/v1/mixed-games/${params.id}`, { ...params });
};

export const getGameRest = async (gameId: string) => {
  const response = await api.get<GameDto>(`/v1/mixed-games/${gameId}`);
  return response.data;
};

export const getGamesRest = async () => {
  const response = await api.get<GameDto[]>(`/v1/mixed-games`);
  return response.data;
};
