import React, { FC } from 'react';
import { Input, Col, InputProps } from 'reactstrap';

interface FormFieldProps {
  name: string;
  value: string | number | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  label?: string;
  error?: string;
  type?: InputProps['type'];
  options?: { value: string | number; label: string }[];
  prefix?: string;
  className?: string;
}

const FormField: FC<FormFieldProps> = ({
  name,
  value,
  onChange,
  type = 'text',
  label,
  error,
  options,
  prefix,
  className,
}) => (
  <Col lg={5} sm={12} className={`mb-4 ${className}`}>
    {label && <label htmlFor={name}>{label}</label>}
    <div className="input-group">
      {prefix && <span className="input-group-text">{prefix}</span>}
      {type === 'select' ? (
        <select
          name={name}
          value={value}
          onChange={onChange}
          className={`form-select ${error ? 'is-invalid' : ''}`}
          aria-label={label}
        >
          <option value="">{`Select ${label}`}</option>
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <Input
          name={name}
          value={value}
          onChange={onChange}
          type={type}
          className={`form-control ${error ? 'is-invalid' : ''}`}
          aria-label={label}
        />
      )}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  </Col>
);

export default FormField;
