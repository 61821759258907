import React from 'react';
import { Routes, Route } from 'react-router-dom';
import VerticalLayout from '@/layouts/index';
import AuthLayout from '@/layouts/AuthLayout';

import { authRoutes, authProtectedRoutes, publicRoutes } from './routes-path';
import AuthGuard from '@/features/auth/components/AuthGuard';

const Index = () => {
  return (
    <React.Fragment>
      <Routes>
        {authRoutes.map((route: { path: string | undefined; component: any }, idx: React.Key | null | undefined) => (
          <Route
            path={route.path}
            element={<AuthLayout>{route.component}</AuthLayout>}
            key={idx}
            // exact={true}
          />
        ))}
        {publicRoutes.map((route: { path: string | undefined; component: any }, idx: React.Key | null | undefined) => (
          <Route
            path={route.path}
            element={<VerticalLayout>{route.component}</VerticalLayout>}
            key={idx}
            // exact={true}
          />
        ))}
        <Route element={<AuthGuard />}>
          {authProtectedRoutes.map(
            (route: { path: string | undefined; component: any }, idx: React.Key | null | undefined) => (
              <Route
                path={route.path}
                element={<VerticalLayout>{route.component}</VerticalLayout>}
                key={idx}
                // exact={true}
              />
            ),
          )}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default Index;
