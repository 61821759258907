import React, { FC } from 'react';
import { Input, Col, InputProps } from 'reactstrap';
import { Control, Controller } from 'react-hook-form';

interface FormFieldProps {
  name: string;
  control: Control<any>;
  label?: string;
  error?: string;
  type?: InputProps['type'];
  options?: { value: string | number; label: string }[];
  prefix?: string;
  className?: string;
  lg?: number;
}

const FormField: FC<FormFieldProps> = ({
  name,
  control,
  type = 'text',
  label,
  error,
  options,
  prefix,
  className,
  lg = 5,
}) => (
  <Col lg={lg} sm={12} className={`mb-4 ${className}`}>
    {label && <label htmlFor={name}>{label}</label>}
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className="input-group">
          {prefix && <span className="input-group-text">{prefix}</span>}
          {type === 'select' ? (
            <select {...field} className={`form-select ${error ? 'is-invalid' : ''}`} aria-label={label}>
              <option value="">{`Select ${label}`}</option>
              {options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          ) : (
            <Input {...field} type={type} className={`form-control ${error ? 'is-invalid' : ''}`} aria-label={label} />
          )}
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      )}
    />
  </Col>
);

export default FormField;
