import React, { ComponentProps, ReactNode } from 'react';
import '@/assets/scss/themes.scss';

import { Container } from 'reactstrap';

interface SplashScreenProps extends ComponentProps<'div'> {
  children: ReactNode;
}

const SplashScreen = ({ children }: SplashScreenProps) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <div>{children}</div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SplashScreen;
