import React from 'react';
import config from '@/config';
import { abi as usdtABI } from '@/common/abis/usdt';
import { abi as phnmABI } from '@/common/abis/phnm';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';
import Address from './Address';
import { ContractVariables } from './ContractVariables';
import { useDeployedContractInfo } from '@/common/hooks/useDeployedContractInfo';
import { ContractName } from '@/common/utils/contract';
import ContractTokenBalance from './ContractTokenBalance';

interface ContractInfoProps {
  contractName: ContractName;
  contractAddress: `0x${string}`;
  contractAbi?: readonly unknown[];
  infoConfig?: {
    showUsdtBalance: boolean;
    showPhnmBalance: boolean;
    variablesToShow?: string[];
  };
  className?: string;
  style: React.CSSProperties;
}

export const ContractInfo: React.FC<ContractInfoProps> = ({
  contractAbi: _contractAbi,
  contractAddress,
  contractName,
  infoConfig = { showUsdtBalance: true, showPhnmBalance: true },
  className,
  style,
}) => {
  const { data: deployedContractData, isLoading: deployedContractLoading } = useDeployedContractInfo(contractName);

  return (
    <Card className={className} style={style}>
      <CardBody>
        <CardTitle tag="h3" className="fs-4">
          {contractName} Contract
        </CardTitle>

        <CardText>
          <Address contractAddress={contractAddress} />
        </CardText>

        <div>
          {infoConfig.showUsdtBalance && (
            <ContractTokenBalance
              tokenAddress={config.contracts.usdt}
              contractAddress={contractAddress}
              abi={usdtABI}
              decimals={config.usdtDecimals}
              tokenSymbol="USDT"
            />
          )}
          {infoConfig.showPhnmBalance && (
            <ContractTokenBalance
              tokenAddress={config.contracts.phnm}
              contractAddress={contractAddress}
              abi={phnmABI}
              decimals={config.phnmDecimals}
              tokenSymbol="PHNM"
            />
          )}
        </div>
        {deployedContractLoading !== undefined &&
          !!infoConfig.variablesToShow &&
          infoConfig.variablesToShow.length > 0 && (
            <div className="mt-3">
              <CardTitle tag="h4" className="fs-5">
                Variables
              </CardTitle>
              <ContractVariables
                deployedContractData={deployedContractData!}
                refreshDisplayVariables
                variablesToShow={infoConfig.variablesToShow}
              />
            </div>
          )}
      </CardBody>
    </Card>
  );
};
