import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { fetchUsersLogInByDate } from '@/services/users/users-rest';
import { UserQueryDto } from '@/services/users/user-types';
import { AxiosError } from 'axios';
import { showToast } from '@/common/showToast';
import UserOverviewChart from './UserOverviewChart';

interface DailyUniqueLoginsProps {
  fromDate: string;
  toDate: string;
  groupByDate: boolean;
}

const DailyUniqueLogins: React.FC<DailyUniqueLoginsProps> = ({ fromDate, toDate, groupByDate }) => {
  const params: UserQueryDto = { fromDate, toDate, groupByDate };

  const { data: usersLogInByDate, error: usersLogInByDateError } = useQuery({
    queryKey: ['usersLogInByDate', params],
    queryFn: () => fetchUsersLogInByDate(params),
  });

  if (usersLogInByDateError) {
    const errorMessage = (usersLogInByDateError as AxiosError)?.message || 'Failed to fetch data.';
    showToast(`Error when fetching server data: ${errorMessage}`, 'warning');
  }

  return (
    <Card>
      <CardHeader>
        <h4 className="card-title mb-0">Daily unique logins per day</h4>
      </CardHeader>
      <CardBody>
        <UserOverviewChart dataColors={['#A046D3']} ts={usersLogInByDate ?? []} />
      </CardBody>
    </Card>
  );
};

export default DailyUniqueLogins;
