import React, { useEffect, useState } from 'react';
import { tableActivePlayersStatsRest, tablePlayersPerDayRest } from '@/services/table/table-service';
import { useQuery } from '@tanstack/react-query';
import { Card, CardBody, CardHeader, Container, Row } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import KeyFigureStatic from '@/common/charts/KeyFigureStatic';
import { TableQueryDto } from '@/services/table/table-types';
import TableOverviewChart from './components/TableOverviewChart';
import { QueryPeriods } from '@/common/QueryPeriods';
import { getDateMinusDays } from '@/common/query/QueryPeriods';
import RakeFigures from '@/pages/analytics/components/RakeFigures';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '@/common/showToast';
import DailyGeneratedRake from './components/UserGrowth';
import DailyUniqueLogins from './components/DailyUniqueVisitors';
import UserGrowth from './components/DailyUniqueUsers';
import { getDepositStatsRest } from '@/services/transactions/transactions-service';

const AnalyticsOverview = () => {
  const today = new Date().toISOString().split('T')[0];

  const [days, setDays] = useState<number>(30);
  const [toDate, setToDate] = useState(today);
  const [fromDate, setFromDate] = useState(() => getDateMinusDays(days));

  const setRange = (range: number) => () => {
    setDays(range);
    setFromDate(getDateMinusDays(range));
    setToDate(today); // Assuming 'today' is defined elsewhere in your code
  };

  const handleDateChange = (dates: Date[]) => {
    if (dates.length === 2) {
      const [start, end] = dates.map((date) => date.toISOString().split('T')[0]);
      setFromDate(start);
      setToDate(end);
    }
  };

  const [params, setParams] = useState<TableQueryDto>({
    fromDate: fromDate,
    toDate: toDate,
    groupByDate: true,
    period: QueryPeriods.day,
  });

  useEffect(() => {
    const differenceInDays =
      Math.abs(new Date(toDate).getTime() - new Date(fromDate).getTime()) / (24 * 60 * 60 * 1000);
    if (differenceInDays < 0) return showToast('Invalid date range', 'warning');

    setDays(differenceInDays);

    setParams((prevState) => ({
      ...prevState,
      fromDate,
      toDate,
    }));
  }, [fromDate, toDate]);

  const { data: playersPerDay } = useQuery({
    queryKey: ['playersPerDay', params],
    queryFn: ({ queryKey }) => {
      const [, params] = queryKey as [string, TableQueryDto];
      return tablePlayersPerDayRest(params);
    },
  });

  const { data: activePlayersData } = useQuery({
    queryKey: ['activePlayers'],
    queryFn: tableActivePlayersStatsRest,
  });

  const { data: transacitonStatsData } = useQuery({
    queryKey: ['depositData'],
    queryFn: getDepositStatsRest,
  });

  const itemGenerator = (value: number, label: string, prefix = '', icon = 'bx bx-dollar-circle', decimals = 2) => {
    return {
      id: 1,
      cardColor: 'primary',
      label: label,
      badge: 'ri-arrow-right-up-line',
      badgeClass: 'success',
      counter: value,
      link: 'View daily rake',
      bgcolor: 'secondary',
      icon,
      decimals,
      prefix: prefix,
      suffix: '',
      separator: ',',
    };
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="container-fluid">
          <Row className="gy-2 d-flex justify-content-between mb-2 flex-row">
            <KeyFigureStatic
              item={itemGenerator(parseInt(activePlayersData?.totalTables, 10), 'Tables Running', '', 'bx bx-table', 0)}
            />
            <KeyFigureStatic item={itemGenerator(activePlayersData?.totalStack, 'USDT in play', '$')} />
            <KeyFigureStatic
              item={itemGenerator(
                parseInt(activePlayersData?.totalPlayers, 10),
                'Active Players',
                '',
                'bx bx-user-circle',
                0,
              )}
            />
          </Row>

          <RakeFigures />

          <Row className="gy-2 d-flex justify-content-between mb-2 flex-row">
            <KeyFigureStatic
              item={itemGenerator(
                parseInt(transacitonStatsData?.todayDeposits, 10),
                "Today's USDT Deposits",
                '$',
                'bx bx-dollar-circle',
                0,
              )}
            />
            <KeyFigureStatic
              item={itemGenerator(
                parseInt(transacitonStatsData?.weekDeposits, 10),
                '7 days USDT Deposits',
                '$',
                'bx bx-dollar-circle',
                0,
              )}
            />

            <KeyFigureStatic
              item={itemGenerator(
                parseInt(transacitonStatsData?.monthDeposits, 10),
                '30 days USDT Deposits',
                '$',
                'bx bx-dollar-circle',
                0,
              )}
            />
          </Row>

          <Row className="gy-2 mb-2">
            <Row className="g-3 d-flex justify-content-end mb-2 mt-5">
              <div className="col-sm-auto">
                <div className="input-group">
                  <Flatpickr
                    className="form-control dash-filter-picker border-0 shadow"
                    options={{
                      mode: 'range',
                      dateFormat: 'Y-m-d',
                      defaultDate: [fromDate, toDate],
                    }}
                    onChange={handleDateChange}
                  />
                  <div className="input-group-text bg-primary border-primary text-white">
                    <i className="ri-calendar-2-line"></i>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-rows justify-content-end gap-2">
                <button className={'btn' + (days === 30 ? ' btn-primary' : ' btn-dark')} onClick={setRange(30)}>
                  30
                </button>
                <button className={'btn' + (days === 60 ? ' btn-primary' : ' btn-dark')} onClick={setRange(60)}>
                  60
                </button>
                <button className={'btn' + (days === 90 ? ' btn-primary' : ' btn-dark')} onClick={setRange(90)}>
                  90
                </button>
              </div>
            </Row>
          </Row>
          <Row>
            <DailyGeneratedRake fromDate={fromDate} toDate={toDate} period={QueryPeriods.day} groupByDate={true} />
          </Row>
          <Row>
            <Card>
              <CardHeader className="d-flex border-0 align-middle">
                <h4 className="card-title flex-grow-1 mb-0">Players per day</h4>
              </CardHeader>
              <CardBody>
                <TableOverviewChart dataColors={['#FF5733', '#33FF57', '#3357FF']} ts={playersPerDay} />
              </CardBody>
            </Card>
          </Row>
          <Row>
            <UserGrowth fromDate={fromDate} toDate={toDate} groupByDate={true} />
          </Row>
          <Row>
            <DailyUniqueLogins fromDate={fromDate} toDate={toDate} groupByDate={true} />
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  );
};

export default AnalyticsOverview;
