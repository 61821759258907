import { polygon } from 'viem/chains';

import { Config } from './config.interface';

export const baseConfig: Config = {
  environment: 'local',
  isDevelopment: true,
  isProduction: false,
  urls: {
    appBaseUrl: 'http://localhost:3000/',
    phenomApi: 'http://localhost:3000/',
    phenomApp: 'https://localhost:30001',
  },
  contracts: {
    usdt: '0x',
    phnm: '0x',
    tables: '0x',
    revenueShareManager: '0x',
    rakeManager: '0x',
    redemptionManager: '0x',
    redemptionVault: '0x',
    redemption: '0x',
    rewardsManager: '0x',
    orchestrator: '0x',
    safeTreasury: '0x',
    smartAccount: '0x',
    poolAccount: '0x',
    backendDeployer: '0x',
  },
  chain: polygon,
  blockExplorerUrl: 'https://polygonscan.com',
  usdtDecimals: 6,
  phnmDecimals: 18,
  nativeDecimals: 18,
};
