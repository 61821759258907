import { usePrivy } from '@privy-io/react-auth';
import { Navigate } from 'react-router-dom';
import AuthButton from './components/AuthButton';
import ROUTES from '@/routes/routes-enums';
import SplashScreen from '@/pages/auth/SplashScreen/SplashScreen';

const Authentication = () => {
  const { authenticated } = usePrivy();

  return authenticated ? (
    <Navigate to={ROUTES.TABLE_ANALYTICS} />
  ) : (
    <SplashScreen>
      <AuthButton />
    </SplashScreen>
  );
};

export default Authentication;
