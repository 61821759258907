import React from 'react';
import Chart from 'react-apexcharts';
import { StreamQ } from '../types/dataTypes';

const DonutChart: React.FC<{ data: StreamQ[] }> = ({ data }) => {
  // Sort the data by count in descending order
  const sortedData = [...data].sort((a, b) => b.count - a.count);

  // Get the top six items
  const topSixData = sortedData.slice(0, 6);

  // Calculate the sum of the remaining items
  const othersCount = sortedData.slice(6).reduce((acc, item) => acc + item.count, 0);

  // Add the "Others" item if there are more than six groups
  if (othersCount > 0) {
    topSixData.push({ group: 'Others', count: othersCount });
  }

  const series = topSixData.map((item) => item.count);
  const labels = topSixData.map((item) => item.group);

  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut',
    },
    labels: labels,
    legend: {
      position: 'bottom',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  return (
    <div>
      <Chart options={chartOptions} series={series} type="donut" height={350} />
    </div>
  );
};

export default DonutChart;
