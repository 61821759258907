export enum PokerCategory {
  ALL = 'All',
  STUD = 'Stud',
  OMAHA = 'Omaha',
  HOLDEM = 'Holdem',
  DRAW = 'Draw',
  CHINESE = 'Chinese',
  MIXED = 'Mixed',
  CUSTOM = 'Custom',
}

export interface CardType {
  rank: string;
  suit: string;
}

export enum TableType {
  CASH = 'CASH',
  TOURNAMENT = 'TOURNAMENT',
  SNG = 'SNG',
}

export enum Currency {
  USDT = 'USDT',
}

export enum BettingStructure {
  NO_LIMIT = 'No Limit',
  POT_LIMIT = 'Pot Limit',
  FIXED_LIMIT = 'Fixed Limit',
}

export enum TableStatus {
  INITIALIZING = 'INITIALIZING',
  STOPPING = 'STOPPING',
  STOPPED = 'STOPPED',
  RUNNING = 'RUNNING',
  RESTARTING = 'RESTARTING',
}

export interface TableInfo {
  id: string;
  type: string;
  name: string;
  variant: PokerVariant;
  ante: number;
  smallBlind: number;
  bigBlind: number;
  minBuyin: number;
  maxBuyin: number;
  smallBet: number;
  bigBet: number;
  bringIn: number;
  bettingCap: number;
  players: number;
  maxPlayers: number;
  waitlist: number;
  avgPot: number;
}

export interface PokerVariant {
  id: string;
  name: string;
  bettingStructure: BettingStructure;
  category: PokerCategory;
  maxPlayers: number;
  reshuffleRequired: boolean;
  capped: boolean;
}

export enum PokerVariantIds {
  // Texas
  NoLimitTexasHoldem = 'NoLimitTexasHoldem',
  NoLimitShortDeckHoldem = 'NoLimitShortDeckHoldem',
  FixedLimitTexasHoldem = 'FixedLimitTexasHoldem',
  NoLimitTexasHoldemCapped = 'NoLimitTexasHoldemCapped',

  // Omaha
  PotLimitOmaha = 'PotLimitOmaha',
  PotLimitOmahaCapped = 'PotLimitOmahaCapped',
  PotLimitOmahaEightOrBetter = 'PotLimitOmahaEightOrBetter',
  FixedLimitOmahaHigh = 'FixedLimitOmahaHigh',
  FixedLimitOmahaEightOrBetter = 'FixedLimitOmahaEightOrBetter',
  PotLimitFiveCardOmaha = 'PotLimitFiveCardOmaha',
  PotLimitFiveCardOmahaEightOrBetter = 'PotLimitFiveCardOmahaEightOrBetter',
  PotLimitFiveCardOmahaCapped = 'PotLimitFiveCardOmahaCapped',
  PotLimitFiveCardOmahaEightOrBetterCapped = 'PotLimitFiveCardOmahaEightOrBetterCapped',
  NoLimitOmahaEightOrBetter = 'NoLimitOmahaEightOrBetter',
  FixedLimitFiveCardOmahaEightOrBetter = 'FixedLimitFiveCardOmahaEightOrBetter',

  // Stud
  FixedLimitSevenCardStud = 'FixedLimitSevenCardStud',
  FixedLimitStudEightOrBetter = 'FixedLimitStudEightOrBetter',
  FixedLimitStudHighLowRegular = 'FixedLimitStudHighLowRegular',
  FixedLimitRazz = 'FixedLimitRazz',
  FixedLimitDeuceToSevenRazz = 'FixedLimitDeuceToSevenRazz',

  // Draw
  FixedLimitBadugi = 'FixedLimitBadugi',
  FixedLimitBadeucy = 'FixedLimitBadeucy',
  FixedLimitBadacey = 'FixedLimitBadacey',
  FixedLimitAceToFiveLowballTripleDraw = 'FixedLimitAceToFiveLowballTripleDraw',
  FixedLimitDeuceToSevenLowballTripleDraw = 'FixedLimitDeuceToSevenLowballTripleDraw',
  FixedLimitArchieSixesHighEightOrBetter = 'FixedLimitArchieSixesHighEightOrBetter',
  FixedLimitArchieNinesHighEightOrBetter = 'FixedLimitArchieNinesHighEightOrBetter',
  FixedLimitDramahaBadugi = 'FixedLimitDramahaBadugi',
  FixedLimitDramahaHigh = 'FixedLimitDramahaHigh',
  FixedLimitDeuceToSevenDramaha = 'FixedLimitDeuceToSevenDramaha',
  NoLimitDeuceToSevenLowballSingleDraw = 'NoLimitDeuceToSevenLowballSingleDraw',
  NoLimitDeuceToSevenLowballSingleDrawCapped = 'NoLimitDeuceToSevenLowballSingleDrawCapped',
}
