import { UserAggregate } from '@/services/users/user-types';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const UserOverviewChart: React.FC<{
  dataColors: string[];
  ts: UserAggregate[];
}> = ({ dataColors, ts }) => {
  if (!ts) return <div>No data</div>;

  // Extract categories and series data from the ts array
  const categories = ts.map((t) => new Date(t.dataRange).toISOString().split('T')[0]);
  const seriesData = ts.map((t) => t.totalAmount);

  const options = {
    chart: {
      height: 350,
      type: 'area' as const, // Add 'as const' here
      toolbar: { show: true },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth' as const,
    },
    xaxis: {
      categories: categories ? categories : [],
    },
    yaxis: {
      labels: {
        formatter: function (value: any) {
          return value.toFixed(2);
        },
      },
      tickAmount: 5,
      min: 0,
      max: Math.max(...seriesData) + 20,
    },
    colors: ['#A046D3'],
    fill: {
      opacity: 0.06,
      colors: dataColors,
      type: 'solid',
    },
  };

  return (
    <div style={{ overflow: 'hidden', padding: '10px', maxWidth: '100%' }}>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={[{ name: 'Total Amount', data: seriesData }]}
        type="area"
        height="290"
        className="apex-charts"
      />
    </div>
  );
};

export default UserOverviewChart;
