import { SortOrder } from '@/common/sortOrder';
import { User } from '@/services/users/user-types';

import { api } from '../api';

export class GetHandsDto {
  handId?: string;
  userId?: string;
  tableName?: string;
  userAlias?: string;
  skip?: number;
  take?: number;
  sorts?: { field: string; order: SortOrder }[];
}

export interface HandStateHistory {
  alias: string;
  cards: string[];
  createdAt: string;
  id: string;
  tableId: string;
  userId: string;
}

export interface HandHistoryList {
  handHistory: HandStateHistory[];
}

export interface HandHistory {
  id: string;
  handId?: string;
  tableId?: string;
  tableName?: string;
  users: User[];
  hand: HandHistoryList;
  createdAt?: string;
}

export interface HandsResultsDto {
  hands: HandHistory[];
  totalCount: number;
  take: number;
  skip: number;
}

export const handsRest = async (getHandsDto: GetHandsDto) => {
  const response = await api.get<HandsResultsDto>(`/v1/admin/hands/`, { params: { ...getHandsDto } });
  return response.data;
};
