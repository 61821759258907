import DebouncedInput from '@/components/DebounceInput';
import { GetHandsDto, handsRest } from '@/services/hands/hands-service';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {
  ButtonGroup,
  Card,
  CardBody,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import Paginator from '../common/paginator';
import routes from '@/routes/routes-enums';
import { FaRegEye } from 'react-icons/fa';

import { useReactTable, getCoreRowModel, getFilteredRowModel, getSortedRowModel } from '@tanstack/react-table';

const TableHands = () => {
  const navigate = useNavigate();

  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [getHandsDto, setHandsDto] = useState<GetHandsDto>({ skip, take });
  const [isDebouncing, setIsDebouncing] = useState<boolean>(false);

  const {
    data,
    isLoading,
    isError: _isError,
    error: _error,
  } = useQuery({
    queryKey: ['handsData', getHandsDto],
    queryFn: () => handsRest(getHandsDto),
    staleTime: 60000,
  });

  const handleSearch = (value: string | number) => {
    if (value === getHandsDto.handId) return;

    setIsDebouncing(true);
    setSkip(0);
    setHandsDto((prev: GetHandsDto) => ({ ...prev, handId: value.toString() }));
    setIsDebouncing(false);
  };

  const columns = [
    { accessorKey: 'handId', header: 'HandId', type: 'string' },
    { accessorKey: 'tableId', header: 'Table Id', type: 'string' },
    { accessorKey: 'userId', header: 'User Id', type: 'string' },
  ];

  const tableInstance = useReactTable({
    data: data?.hands || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="container-fluid">
          <Row className="gy-2 mb-2">
            <div>
              <div>
                <div className="d-flex mb-3 flex-row gap-3">
                  <DebouncedInput
                    type="text"
                    className="form-control"
                    placeholder="Search by hand Id..."
                    onChange={handleSearch}
                    value={getHandsDto?.handId ? getHandsDto?.handId : ''}
                    isLoading={isDebouncing}
                  />
                </div>

                <Card className="card mb-2">
                  <CardBody className="card-body">
                    <table className="table-hover table-centered table-nowrap mb-0 table align-middle">
                      <thead>
                        {tableInstance.getHeaderGroups().map((headerGroup) => (
                          <tr key={headerGroup.id}>
                            <th>Hand Id</th>
                            <th>Table Id</th>
                            <th>Table Name</th>
                            <th>Users</th>
                            <th>Actions</th>
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {!isLoading &&
                          tableInstance.getRowModel().rows.map((row) => (
                            <tr key={row.original.id}>
                              <td>{row.original.handId}</td>
                              <td>{row.original.tableId}</td>
                              <td>{row.original.tableName}</td>
                              <td>
                                <ul>
                                  {row.original.users?.map((user) => (
                                    <li key={user.id}>
                                      <a onClick={() => navigate(`${routes.USER_EDIT}/${user.id}`)}>{user.alias}</a>
                                    </li>
                                  ))}
                                </ul>
                              </td>

                              <td>
                                <ButtonGroup>
                                  <UncontrolledDropdown>
                                    <DropdownToggle tag="button" className="btn btn-soft-primary">
                                      <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdownmenu-primary">
                                      <DropdownItem header>Play Hand</DropdownItem>
                                      {row.original?.users?.map((user) => (
                                        <DropdownItem
                                          className="d-flex align-items-center gap-2"
                                          key={user.id}
                                          onClick={() => {
                                            const url = `${process.env.REACT_APP_PHENOM_APP}/hand-history?tableId=${row.original.tableId}&userId=${user.id}&handId=${row.original.handId}`;
                                            const width = 1440;
                                            const height = 900;

                                            window.open(url, row.original.handId, `height=${height},width=${width}`);
                                          }}
                                        >
                                          <FaRegEye />
                                          {user.alias}
                                        </DropdownItem>
                                      ))}
                                      <DropdownItem onClick={() => navigate(`${routes.TABLE}/${row.original.tableId}`)}>
                                        Go to Table Details
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </ButtonGroup>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <Row className="g-3 text-sm-start mt-2 text-center align-middle">
            <Paginator
              take={take}
              skip={skip}
              showing={data?.hands.length || 0}
              totalCount={data?.totalCount || 0}
              setTake={setTake}
              setSkip={setSkip}
            />
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default TableHands;
