import React from 'react';
import { Container, Row } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RewardsPayoutsComponents from './rewards-payouts-components';

const RewardsPayouts = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="container-fluid">
          <Row className="gy-2 mb-2">
            <div>
              <h1 className="mb-4 text-2xl font-bold">Rewards Pending Payouts</h1>

              <RewardsPayoutsComponents />
            </div>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default RewardsPayouts;
