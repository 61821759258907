import { polygon } from 'viem/chains';

import { baseConfig } from './base.config';
import { Config } from './config.interface';

export const developConfig: Config = {
  ...baseConfig,
  environment: 'development',
  isDevelopment: true,
  isProduction: false,
  urls: {
    appBaseUrl: 'https://dev.admin.phenompoker.com/',
    phenomApi: 'https://dev.api.phenompoker.com/',
    phenomApp: 'https://dev.app.phenompoker.com/',
  },
  chain: polygon,
  blockExplorerUrl: 'https://polygonscan.com',
  usdtDecimals: 18,
  phnmDecimals: 18,
  contracts: {
    usdt: '0x2A1e0E339355899f5F7f7c1F4590a79D6B49D4F2',
    phnm: '0x35F4a9FdcBddBaF7C3c3FF45f78Dc5BF70EeFDA1',
    tables: '0xe789D2724f29D8De7FA00a4e133b9072d6A492D9',
    revenueShareManager: '0xa20Ad31fd23C7e4aD9f27C18d4740c8Baac6F599',
    rakeManager: '0xdA6883699f031F438ceBac69163aD7636490929E',
    redemptionManager: '0xF02D67E04DBd800020cD6a584c910647aea554DB',
    redemption: '0x746c1e55d1FBeb0f6081C8b6805395E9f3811dDE',
    redemptionVault: '0x99810bF501E1122b209353AE0234a3cA94a73522',
    rewardsManager: '0xd22D159A35172017F9897752a38d78E5E34Fa5FD',
    orchestrator: '0x0F6740eE5a77F07f6493B6bd4aF5EC2FF32a6AFf',
    safeTreasury: '0x03e4a8d78a23Bf8C0ef1DA6B16b18716d2477f3c',
    smartAccount: '0x8310a1bBB27f29935008BDc8BAc970d86B329d0a',
    poolAccount: '0x0000000000000000000000000000000000000000',
    backendDeployer: '0x03e4a8d78a23Bf8C0ef1DA6B16b18716d2477f3c',
  },
};
