import React from 'react';
import config from '@/config/index';
import { abi as usdtABI } from '@/common/abis/usdt';
import { abi as phnmABI } from '@/common/abis/phnm';
import { Card, CardBody, CardHeader, Container, Row, Table } from 'reactstrap';
import { Address } from 'viem';
import ContractTokenBalance from '@/components/ContractTokenBalance';
import NativeTokenBalance from '@/components/NativeTokenBalance';
import CopyField from '@/common/forms/CopyField';

const contracts: Record<string, Address | undefined> = {
  smartAccount: config.contracts.smartAccount,
  poolAccount: config.contracts.poolAccount,
  backendDeployer: config.contracts.backendDeployer,
  tables: config.contracts.tables,
  oldTables: config.contracts.oldTables,
  revenueShareManager: config.contracts.revenueShareManager,
  rakeManager: config.contracts.rakeManager,
  oldRevenueShareManager: config.contracts.oldRevenueShareManager,
  oldRakeManager: config.contracts.oldRakeManager,
  oldRedemptionVault: config.contracts.oldRedemptionVault,
  redemptionManager: config.contracts.redemptionManager,
  redemptionVault: config.contracts.redemptionVault,
  redemption: config.contracts.redemption,
  rewardsManager: config.contracts.rewardsManager,
  orchestrator: config.contracts.orchestrator,
  safeTreasury: config.contracts.safeTreasury,
} as const;

const ContractBalances: React.FC = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="container-fluid">
          <Row className="gy-2 mb-2">
            <Card>
              <CardHeader>
                <h1 className="text-2xl font-bold">Contracts Balances</h1>
              </CardHeader>
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th>Contract</th>
                      <th>Address</th>
                      <th className="text-end">MATIC</th>
                      <th className="text-end">USDT</th>
                      <th className="text-end">PHNM</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(contracts).map(
                      ([name, address]) => address && <ContractBalanceRow key={name} name={name} address={address} />,
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

interface ContractBalanceRowProps {
  name: string;
  address: Address;
}

const ContractBalanceRow: React.FC<ContractBalanceRowProps> = ({ name, address }) => {
  return (
    <tr>
      <td className="font-medium">{name}</td>
      <td>
        <CopyField
          name={'address'}
          label={false}
          value={address}
          link={`${config.blockExplorerUrl}/address/${address}`}
        />
      </td>
      <td>
        <NativeTokenBalance
          contractAddress={address}
          tokenSymbol="MATIC"
          simpleFormat={true}
          decimals={config.phnmDecimals}
        />
      </td>
      <td>
        <ContractTokenBalance
          tokenAddress={config.contracts.usdt}
          contractAddress={address}
          abi={usdtABI}
          decimals={config.usdtDecimals}
          tokenSymbol="USDT"
          simpleFormat={true}
        />
      </td>
      <td>
        <ContractTokenBalance
          tokenAddress={config.contracts.phnm}
          contractAddress={address}
          abi={phnmABI}
          decimals={config.phnmDecimals}
          tokenSymbol="PHNM"
          simpleFormat={true}
        />
      </td>
    </tr>
  );
};

export default ContractBalances;
