import { api } from '@/services/api';

export const updatePhnmTokenPrice = async () => {
  const response = await api.post(`/v1/admin/revenue-share/update-phnm-price`);
  return response.data;
};

export const distributeRevenueRest = async () => {
  const response = await api.post(`/v1/admin/revenue-share/distribute-revenue`);
  return response.data;
};

export const transferLeftoverRevenueToTreasuryRest = async () => {
  const response = await api.post(`/v1/admin/revenue-share/transfer-leftover-revenue`);
  return response.data;
};
