import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { fetchTableByIdRest, restartTableRest, startTableRest, stopTableRest } from '@/services/table/table-service';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '@/common/showToast';
import checkVariants from '@/common/forms/table-handling/checkVariants';
import { TableInfoDto } from '@/services/table/table-types';
import LoadingPage from '@/common/LoadingPage';
import { AxiosError } from 'axios';
import { TableStatus } from '@/common/types/phenomTypes';
import routes from '@/routes/routes-enums';
import CustomSpinner from '@/components/spinner/CustomSpinner';
import TableDetailsInfo from './table-details-details';
import TableDetailsGame from './table-details-game';

const TableDetails: React.FC = () => {
  const { tableId } = useParams<{ tableId: string }>();
  const navigate = useNavigate();

  const [table, setTable] = useState<TableInfoDto | undefined>(undefined);
  const [isLimitGame, setIsLimitGame] = useState<boolean>(false);
  const [isStudGame, setIsStudGame] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<boolean>(false);
  const [restartTableConfirmOpen, setRestartTableConfirmOpen] = useState(false);

  const loadTable = useCallback(async () => {
    if (!tableId) return;
    try {
      const response = await fetchTableByIdRest(tableId);
      setTable(response);

      if (response.variants && response.variants.length > 0) {
        const { stud, limit } = checkVariants(response.variants);
        setIsStudGame(stud);
        setIsLimitGame(limit);
      }
    } catch (error) {
      showToast(`Error when fetching server data: ${(error as AxiosError).message}`, 'warning');
    }
  }, [tableId]);

  useEffect(() => {
    loadTable();

    setInterval(() => {
      loadTable();
    }, 7000);
  }, []);

  const refreshTable = async () => {
    await loadTable();
  };

  const handleRestartTableConfirm = async () => {
    if (!tableId) return;
    setIsLoading(true);
    try {
      await restartTableRest(tableId);
      showToast('Request to restart table was made', 'success');

      setTable((prevTable) => ({ ...prevTable!, status: TableStatus.RESTARTING }));
      setRestartTableConfirmOpen(false);
    } catch (error) {
      showToast(`Error restarting table: ${(error as Error).message}`, 'warning');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartTable = async () => {
    if (!tableId) return;
    setIsLoading(true);
    try {
      await startTableRest(tableId);
      showToast('Request to start table was made', 'success');

      setTable((prevTable) => ({ ...prevTable!, status: TableStatus.INITIALIZING }));
    } catch (error) {
      showToast(`Error starting table: ${(error as Error).message}`, 'warning');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStopTable = async () => {
    if (!tableId) return;
    setIsLoading(true);
    try {
      await stopTableRest(tableId, true);
      showToast('Request to stop table was made', 'success');

      setTable((prevTable) => ({ ...prevTable!, status: TableStatus.STOPPING }));
    } catch (error) {
      showToast(`Error stopping table: ${(error as Error).message}`, 'warning');
    } finally {
      setIsLoading(false);
    }
  };

  const getStatusColor = () => {
    switch (table?.status.toUpperCase()) {
      case TableStatus.RUNNING:
        return 'success';
      case TableStatus.STOPPED:
        return 'warning';
      case TableStatus.INITIALIZING:
      case TableStatus.STOPPING:
        return 'success';
      default:
        return 'secondary';
    }
  };

  const accessTable = () => {
    if (!tableId) return;
    const url = `${process.env.REACT_APP_PHENOM_APP}/table/${tableId}`;
    window.open(url, tableId, `height=900,width=1440`);
  };

  const goToTableEdit = () => {
    if (!tableId) return;
    navigate(`${routes.TABLE_EDIT}/${tableId}`);
  };

  if (!table) {
    return <LoadingPage />;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={12}>
              <Card className="justify-content-center p-3">
                <div className="mb-4">
                  <div className={`bg-${getStatusColor()}-subtle position-relative`}>
                    <CardBody className="p-5">
                      <div className="text-center">
                        <h3>{table.name}</h3>
                        <p className="text-muted mb-0">{table.id}</p>
                      </div>
                    </CardBody>
                    <div className="shape">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="1440"
                        height="60"
                        preserveAspectRatio="none"
                        viewBox="0 0 1440 60"
                      >
                        <g mask='url("#SvgjsMask1001")' fill="none">
                          <path
                            d="M 0,4 C 144,13 432,48 720,49 C 1008,50 1296,17 1440,9L1440 60L0 60z"
                            style={{ fill: 'var(--vz-secondary-bg)' }}
                          ></path>
                        </g>
                        <defs>
                          <mask id="SvgjsMask1001">
                            <rect width="1440" height="60" fill="#ffffff"></rect>
                          </mask>
                        </defs>
                      </svg>
                    </div>
                  </div>

                  {table.status.toUpperCase() && (
                    <div className="d-flex flex-rows justify-content-between mt-3">
                      <Alert color={getStatusColor()}>
                        <div className="d-flex flex-rows justify-content-center gap-2 align-middle">
                          <span>Table {table.status.toUpperCase()}</span>
                          <div className="form-check form-switch form-switch-right form-switch-md d-flex justify-content-center ms-2 align-middle">
                            {(table.status.toUpperCase() === TableStatus.RUNNING ||
                              table.status.toUpperCase() === TableStatus.STOPPED) && (
                              <Input
                                className="form-check-input code-switcher ms-4"
                                type="checkbox"
                                checked={table.status.toUpperCase() === TableStatus.RUNNING}
                                onChange={
                                  table.status.toUpperCase() === TableStatus.RUNNING
                                    ? handleStopTable
                                    : handleStartTable
                                }
                                disabled={isLoading}
                              />
                            )}
                            {(table.status.toUpperCase() === TableStatus.INITIALIZING ||
                              table.status.toUpperCase() === TableStatus.STOPPING) && (
                              <CustomSpinner color="warning" size="15px" />
                            )}
                          </div>
                        </div>
                      </Alert>

                      <div>
                        <div className="d-flex flex-rows items-align-center gap-4">
                          <Button onClick={() => setRestartTableConfirmOpen(true)}>
                            <i className="ri-error-warning-fill me-2"></i>
                            Restart
                          </Button>
                          <Button color="primary" onClick={accessTable}>
                            <i className="ri-link me-2"></i>
                            Open
                          </Button>
                          {details ? (
                            <Button color="primary" onClick={() => setDetails(false)}>
                              <i className="ri-nft-line me-2"></i>
                              Contract
                            </Button>
                          ) : (
                            <Button color="primary" onClick={() => setDetails(true)}>
                              <i className="ri-collage-fill me-2"></i>
                              Details
                            </Button>
                          )}
                          {table.status.toUpperCase() === TableStatus.STOPPED && (
                            <Button color="primary" onClick={goToTableEdit}>
                              <i className="ri-edit-2-line"></i>
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {details ? (
                  <TableDetailsInfo table={table} isStudGame={isStudGame} isLimitGame={isLimitGame} />
                ) : (
                  <TableDetailsGame
                    table={table}
                    isStudGame={isStudGame}
                    isLimitGame={isLimitGame}
                    refreshTable={refreshTable}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer containerId="table-details" />

      <Modal
        isOpen={restartTableConfirmOpen}
        toggle={() => setRestartTableConfirmOpen(false)}
        id="confirm-restart-table"
        centered
      >
        <ModalHeader>Are you sure you want to restart this table?</ModalHeader>
        <ModalBody>This will affect all current players on the table</ModalBody>
        <ModalFooter>
          <Button color="grey" onClick={() => setRestartTableConfirmOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleRestartTableConfirm} disabled={isLoading}>
            <i className="ri-error-warning-fill me-2"></i>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default TableDetails;
