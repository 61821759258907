import React, { FC } from 'react';
import { Col, Input, Label } from 'reactstrap';

interface InfoFieldBooleanProps {
  name: string;
  value: boolean;
  className?: string;
}

const InfoFieldBoolean: FC<InfoFieldBooleanProps> = ({ name, value, className }) => (
  <Col lg={5} sm={12} className={className}>
    <div>
      <Label htmlFor={name} className="form-label">
        {name}
      </Label>
      <Input name={name} className={`form-check-input} ms-4`} type="checkbox" checked={value} aria-label={name} />
    </div>
  </Col>
);

export default InfoFieldBoolean;
