import { SortOrder } from '@/common/sortOrder';

export const TransactionType = {
  CONFIRMED: 'CONFIRMED',
  CREATE_TABLE: 'CREATE_TABLE',
  JOIN_TABLE: 'JOIN_TABLE',
  LEAVE_TABLE: 'LEAVE_TABLE',
  REBUY: 'REBUY',
  END_TABLE: 'END_TABLE',
  PLAYER_DEPOSIT: 'PLAYER_DEPOSIT',
  PLAYER_WITHDRAWAL: 'PLAYER_WITHDRAWAL',
  TRANSFER: 'TRANSFER',
  ADD_WHITELIST: 'ADD_WHITELIST',
  REMOVE_WHITELIST: 'REMOVE_WHITELIST',
  PAY_REWARDS: 'PAY_REWARDS',
  START_DISTRIBUTING_AGENT_RAKE: 'START_DISTRIBUTING_AGENT_RAKE',
  PAY_RAKE_SHARE: 'PAY_RAKE_SHARE',
  PAY_RAKE_SHARE_BATCH: 'PAY_RAKE_SHARE_BATCH',
  START_DISTRIBUTING_NET_RAKE: 'START_DISTRIBUTING_NET_RAKE',
  DISTRIBUTE_NET_RAKE: 'DISTRIBUTE_NET_RAKE',
  START_PHNM_PRICE_UPDATE: 'START_PHNM_PRICE_UPDATE',
  UPDATE_PHNM_PRICE: 'UPDATE_PHNM_PRICE',
  SET_ORCHESTRATOR_STATE: 'SET_ORCHESTRATOR_STATE',
  START_PHNM_TRANSFER: 'START_PHNM_TRANSFER',
  TRANSFER_PHNM_TO_TREASURY: 'TRANSFER_PHNM_TO_TREASURY',
  GRANT_INTERNAL_TOKEN_DEAL: 'GRANT_INTERNAL_TOKEN_DEAL',
  REVOKE_INTERNAL_TOKEN_DEAL: 'REVOKE_INTERNAL_TOKEN_DEAL',
  START_REVENUE_DISTRIBUTION: 'START_REVENUE_DISTRIBUTION',
  DISTRIBUTE_REVENUE: 'DISTRIBUTE_REVENUE',
  START_LEFTOVER_REVENUE_TRANSFER: 'START_LEFTOVER_REVENUE_TRANSFER',
  TRANSFER_LEFTOVER_REVENUE_TO_TREASURY: 'TRANSFER_LEFTOVER_REVENUE_TO_TREASURY',
  START_REWARDS_FUNDING: 'START_REWARDS_FUNDING',
  START_REWARDS_DISTRIBUTION: 'START_REWARDS_DISTRIBUTION',
  END_REWARDS_DISTRIBUTION: 'END_REWARDS_DISTRIBUTION',
  COLLECT_RAKE: 'COLLECT_RAKE',
};

export const TransactionStatus = {
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  FAILED: 'FAILED',
  RESOLVED: 'RESOLVED',
  PERMANENTLY_FAILED: 'PERMANENTLY_FAILED',
  REVERTED: 'REVERTED',
};

export interface GetTransactionDto {
  txId?: number;
  contractTableId?: number;
  tableId?: string;
  tableName?: string;
  userId?: string;
  userAlias?: string;
  type?: string;
  hash?: string;
  skip: number;
  take: number;
  sorts?: { field: string; order: SortOrder }[];
}

export interface TransactionsQueryDto {
  transactions: TransactionsDto[];
  skip: number;
  take: number;
  totalCount: number;
}

export interface TransactionsDto {
  id: string;
  txId: string;
  userId: string;
  userAlias: string;
  tableId: string;
  tableName: string;
  contractTableId: string;
  type: string;
  status: string;
  amount: string;
  createdAt: string;
  updatedAt: string;
  addressFrom?: string;
  addressTo?: string;
  message?: string;
}

export interface UpdateTransactionDto {
  status: string;
  txId?: string;
  message: string;
}

export interface TransactionDisplayDto extends TransactionsDto {
  userName: string;
  tableName: string;
}
