import React, { useEffect, useState } from 'react';
import { Alert, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import DataTable from '@/common/tableData/DataTable';
import Flatpickr from 'react-flatpickr';
import { fetchAggregatedRakeRest, fetchPlayerRakeRest, fetchTableRakeRest } from '@/services/rake/rake-service';
import ROUTES from '@/routes/routes-enums';
import RakeFigures from '@/pages/analytics/components/RakeFigures';

import { RakeAggregate } from '@/services/rake/rake-types';
import { TableData } from '@/common/tableData/types';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '@/common/showToast';
import { AxiosError } from 'axios';
import { QueryPeriods } from '@/common/QueryPeriods';
import { getDateMinusDays } from '@/common/query/QueryPeriods';
import DailyGeneratedRake from '../analytics/components/UserGrowth';

const RakeAnalytics = () => {
  const [error, _setError] = useState<string | null>(null);

  const today = new Date().toISOString().split('T')[0];
  const [days, setDays] = useState<number>(30);
  const [toDate, setToDate] = useState(today);
  const [fromDate, setFromDate] = useState(() => getDateMinusDays(days));

  const setRange = (range: number) => () => {
    setDays(range);
    setFromDate(getDateMinusDays(range));
    setToDate(today); // Assuming 'today' is defined elsewhere in your code
  };

  const [_rake, setRake] = useState<RakeAggregate[]>([]);
  const [rakeByTable, setRakeByTable] = useState<TableData[]>([]);
  const [rakeByUser, setRakeByUser] = useState<TableData[]>([]);

  useEffect(() => {
    if (!(fromDate && toDate)) return;

    const fetchData = async () => {
      const differenceInDays =
        Math.abs(new Date(toDate).getTime() - new Date(fromDate).getTime()) / (24 * 60 * 60 * 1000);
      if (differenceInDays < 0) return showToast('Invalid date range', 'warning');

      setDays(differenceInDays);

      fetchAggregatedRakeRest({
        fromDate,
        toDate,
        period: QueryPeriods.day,
        groupByDate: true,
      })
        .then((response) => {
          setRake(response);
        })
        .catch((error: AxiosError) => {
          showToast(`Error when fetching server data: ${error.message}`, 'warning');
        });

      fetchTableRakeRest({
        tableId: true,
        fromDate,
        period: QueryPeriods.day,
        toDate,
      })
        .then((response) => {
          setRakeByTable(
            response.map((table) => ({
              totalAmount: Math.round(table.totalAmount),
              tableId: table.tableId,
              alias: table.alias,
            })),
          );
        })
        .catch((error: AxiosError) => {
          showToast(`Error when fetching server data: ${error.message}`, 'warning');
        });

      fetchPlayerRakeRest({
        userId: true,
        fromDate,
        period: QueryPeriods.day,
        toDate,
      })
        .then((response) => {
          setRakeByUser(
            response.map((user) => ({
              totalAmount: Math.round(user.totalAmount),
              userId: user.userId,
              alias: user.alias,
            })),
          );
        })
        .catch((error: AxiosError) => {
          showToast(`Error when fetching server data: ${error.message}`, 'warning');
        });
    };

    fetchData();
  }, [fromDate, toDate]);

  const handleDateChange = (dates: Date[]) => {
    if (dates.length === 2) {
      const [start, end] = dates.map((date) => date.toISOString().split('T')[0]);
      setFromDate(start);
      setToDate(end);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="container-fluid">
          {error && <Alert color="danger">{error}</Alert>}
          <RakeFigures />
          <Row className="gy-2 mb-2">
            <Row className="g-3 d-flex justify-content-end mb-2 mt-5">
              <div className="col-sm-auto">
                <div className="input-group">
                  <Flatpickr
                    className="form-control dash-filter-picker border-0 shadow"
                    options={{
                      mode: 'range',
                      dateFormat: 'Y-m-d',
                      defaultDate: [fromDate, toDate],
                    }}
                    onChange={handleDateChange}
                  />
                  <div className="input-group-text bg-primary border-primary text-white">
                    <i className="ri-calendar-2-line"></i>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-rows justify-content-end gap-2">
                <button className={'btn' + (days === 30 ? ' btn-primary' : ' btn-dark')} onClick={setRange(30)}>
                  30
                </button>
                <button className={'btn' + (days === 60 ? ' btn-primary' : ' btn-dark')} onClick={setRange(60)}>
                  60
                </button>
                <button className={'btn' + (days === 90 ? ' btn-primary' : ' btn-dark')} onClick={setRange(90)}>
                  90
                </button>
              </div>
            </Row>
          </Row>
          <Row>
            <DailyGeneratedRake fromDate={fromDate} toDate={toDate} period={QueryPeriods.day} groupByDate={true} />
          </Row>

          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Rake by table</h4>
                </CardHeader>
                <CardBody>
                  <DataTable
                    data={rakeByTable}
                    columns={[
                      { header: 'Table Name', accessorKey: 'alias' },
                      { header: 'Rake USD', accessorKey: 'totalAmount' },
                    ]}
                    route={ROUTES.TABLE}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Rake by User</h4>
                </CardHeader>
                <CardBody>
                  <DataTable
                    data={rakeByUser}
                    columns={[
                      { header: 'Alias', accessorKey: 'alias' },
                      {
                        header: 'Rake USD',
                        accessorKey: 'totalAmount',
                      },
                    ]}
                    route={ROUTES.USER}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  );
};

export default RakeAnalytics;
