import { RakeAggregate } from '@/services/rake/rake-types';
import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

import './overviewChart.css';

const RakeOverviewChart: React.FC<{
  dataColors: string[];
  ts: RakeAggregate[];
}> = ({ dataColors, ts }) => {
  const [categories, setCategories] = React.useState<string[]>([]);
  const [seriesData, setSeriesData] = React.useState<number[]>([]);
  const [rangeMax, setRangeMax] = React.useState<number>(20);

  useEffect(() => {
    try {
      setCategories(ts.map((t) => new Date(t.dataRange).toISOString().split('T')[0]));
      setSeriesData(ts.map((t) => t.totalAmount));
      setRangeMax(Math.max(...ts.map((t) => t.totalAmount)) + 10);
    } catch (_e) {
      console.log('Failed creating categories', ts);
    }
  }, [ts]);

  if (!ts) return <div>No data</div>;

  const options = {
    chart: {
      height: 350,
      type: 'area' as const, // Add 'as const' here
      toolbar: { show: true },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth' as const,
    },
    xaxis: {
      categories: categories ? categories : [],
    },
    yaxis: {
      labels: {
        formatter: function (value: any) {
          return '$' + value.toFixed(2);
        },
      },
      tickAmount: 5,
      min: 0,
      max: rangeMax,
    },
    colors: dataColors,
    fill: {
      opacity: 0.06,
      colors: dataColors,
      type: 'solid',
    },
  };

  return (
    <div style={{ overflow: 'hidden', padding: '10px', maxWidth: '100%' }}>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={[{ name: 'Total Amount', data: seriesData }]}
        type="area"
        height="290"
        className="apex-charts"
      />
    </div>
  );
};

export default RakeOverviewChart;
