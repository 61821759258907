import { payRewardsRest } from '@/services/rewards/rewards-manager-service';
import { Card, CardBody, CardHeader } from 'reactstrap';

const EndRewardsDistribution = () => {
  const _endRewardsDistribution = () => {
    payRewardsRest()
      .then(() => {
        // Left empty intentionally
      })
      .catch(() => {
        // TODO: handle error
      });
  };

  return (
    <Card>
      <CardHeader>
        <h1>Process ended</h1>
      </CardHeader>
      <CardBody>
        <p>Revenuew, Rewards and share payment process was successfully completed.</p>
      </CardBody>
    </Card>
  );
};

export default EndRewardsDistribution;
