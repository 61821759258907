import React from 'react';
import Route from './routes';
import { useSwitchChain } from 'wagmi';
import config from './config';

function App() {
  const { chains: _chains, switchChain } = useSwitchChain();

  React.useEffect(() => {
    switchChain({ chainId: config.chain.id });
  }, []);

  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
