import { fundsRewardsManagerRest } from '@/services/rewards/rewards-manager-service';
import { useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { WizardTransition } from '../token-flow-orchestrator';
import RewardsPayoutsComponents from '@/pages/rake/rewards-payouts-components';
import RakeBackPayoutComponents from '@/pages/rake/rake-back-payouts-components';
import { ContractInfo } from '@/components/ContractInfo';
import config from '@/config';

const FundsRewardsManager: React.FC<WizardTransition> = ({
  currentState: _currentState,
  setState,
  setToast,
  enabled,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [show, setShow] = useState<string | undefined>(undefined);

  const fundsRewards = () => {
    setSubmitting(true);
    fundsRewardsManagerRest()
      .then(() => {
        setToast('Rewards Manager Funded', 'success');
        setState(8);
      })
      .catch((error) => {
        setToast(`Funding rewards manager failed ${error}`, 'warning');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      {' '}
      {show ? (
        <Card>
          <CardHeader>
            <h2>
              <span className="btn btn-light mx-2">7</span>Funds Rewards Manager
            </h2>
          </CardHeader>
          <CardBody>
            <div className="d-flex justify-content-end mb-3">
              <button className="btn btn-light" onClick={() => setShow(undefined)}>
                x
              </button>
            </div>
            {show === 'rewards' && <RewardsPayoutsComponents />}
            {show === 'rakeback' && <RakeBackPayoutComponents />}
          </CardBody>
        </Card>
      ) : (
        <Card>
          <CardHeader>
            <h2>
              <span className="btn btn-light mx-2">7</span>Funds Rewards Manager
            </h2>
          </CardHeader>
          <CardBody>
            <ContractInfo
              className="col-sm-12 col-lg-3"
              style={{ borderWidth: 1, minWidth: 'fit-content' }}
              contractAddress={config.contracts.rewardsManager}
              contractName="RewardsManager"
              infoConfig={{
                showUsdtBalance: false,
                showPhnmBalance: true,
                variablesToShow: [],
              }}
            />
            <p>
              Calculate the total USDT rakeback that needs to be distributed and determine the amount of PHNM tokens
              required by dividing the total USDT rakeback by the PHNM token price. Send a notification to the
              designated Slack channel with the amount of PHNM needed for rakeback and transfer the necessary PHNM
              tokens from the Treasury to the RewardsContract, ensuring the transition to the next state.
            </p>
          </CardBody>
          <CardFooter>
            <div className="d-flex justify-content-end gap-2">
              <button
                className="btn btn-soft-primary text-white"
                onClick={() => {
                  setShow('rakeback');
                }}
              >
                Rake Back
              </button>
              <button
                className="btn btn-soft-primary text-white"
                onClick={() => {
                  setShow('rewards');
                }}
              >
                Rewards
              </button>
              <button
                disabled={submitting || !enabled}
                className="btn btn-primary mx-1"
                onClick={() => {
                  if (!enabled) {
                    setToast('Please follow Orchestrator steps', 'warning');
                    return;
                  }
                  fundsRewards();
                }}
              >
                {submitting ? 'Funding...' : 'Fund Rewards Manager'}
              </button>
            </div>
          </CardFooter>
        </Card>
      )}
    </>
  );
};

export default FundsRewardsManager;
