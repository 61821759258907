import { TableData } from '@/common/tableData/types';
import { api } from '@/services/api';

import {
  RakeAggregate,
  RakeBackPayoutPerPlayerDto,
  RakeDisplay,
  RakeQueryDto,
  RakeSharePayoutDetailDto,
  RakeSharePayoutPerPlayerDto,
  RewardsPayoutDetailDto,
  RewardsPayoutPerPlayerDto,
} from './rake-types';

export const fetchRawRakeRest = async (query: RakeQueryDto) => {
  const response = await api.get<RakeDisplay[]>(`/v1/admin/rake/`, {
    params: query,
  });
  return response.data;
};

export const fetchAggregatedRakeRest = async (query: RakeQueryDto) => {
  const response = await api.get<RakeAggregate[]>(`/v1/admin/rake/aggregated`, {
    params: query,
  });

  return response.data;
};

export const fetchTableRakeRest = async (query: RakeQueryDto) => {
  const response = await api.get<TableData[]>(`/v1/admin/rake/table`, {
    params: query,
  });
  return response.data;
};

export const fetchPlayerRakeRest = async (query: RakeQueryDto) => {
  const response = await api.get<TableData[]>(`/v1/admin/rake/user`, {
    params: query,
  });
  return response.data;
};

export const fetchRakesharePayouts = async (paid: boolean) => {
  const response = await api.get<RakeSharePayoutPerPlayerDto[]>(`/v1/admin/rake/share-payout/?paid=${paid}`);
  return response.data;
};

export const fetchRakeSharePerPlayer = async (userId: string, paid: boolean) => {
  const response = await api.get<RakeSharePayoutDetailDto[]>(`/v1/admin/rake/share-payout-per-player`, {
    params: { userId, paid },
  });
  return response.data;
};

export const fetchRakeShareRest = async () => {
  const response = await api.get<TableData[]>(`/v1/admin/rake/share-payout`);
  return response.data;
};

export const fetchRakebackPayouts = async (paid: boolean) => {
  const response = await api.get<RakeBackPayoutPerPlayerDto[]>(`/v1/admin/rake/back-payout/?paid=${paid}`);
  return response.data;
};

export const fetchRakeBackPerPlayerRest = async (userId: string, paid: boolean) => {
  const response = await api.get<RakeSharePayoutDetailDto[]>(`/v1/admin/rake/back-payout-per-player`, {
    params: { userId, paid },
  });
  return response.data;
};

export const fetchRewardPayouts = async (paid: boolean) => {
  const response = await api.get<RewardsPayoutPerPlayerDto[]>(`/v1/admin/rewards/payout/?paid=${paid}`);
  return response.data;
};

export const fetchRewardsPerPlayerRest = async (userId: string, paid: boolean) => {
  const response = await api.get<RewardsPayoutDetailDto[]>(`/v1/admin/rewards/payout-per-player`, {
    params: { userId, paid },
  });
  return response.data;
};

export const payRakeShareRest = async () => {
  const response = await api.post(`/v1/admin/rake/pay-rake-share`);
  return response.data;
};

export const distributeNetRakeRest = async () => {
  const response = await api.post(`/v1/admin/rake/distribute-net-rake`);
  return response.data;
};
