// toastUtils.ts
import { Bounce, toast } from 'react-toastify';

export const showToast = (message: string, type: 'success' | 'warning' = 'warning') => {
  toast[type](message, {
    position: 'bottom-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'light',
    transition: Bounce,
  });
};
