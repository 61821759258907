import { useQuery } from '@tanstack/react-query';

import { getAvatarModel } from '../avaturn.service';

interface UseGetAvatarModel {
  url: string;
}

const useGetAvatarModel = ({ url }: UseGetAvatarModel) => {
  return useQuery({
    queryKey: ['avatar-model', url],
    queryFn: () => getAvatarModel(url),
    retry: false,
  });
};

export default useGetAvatarModel;
