enum ROUTES {
  ROOT = '/',

  TABLES = '/tables',
  TABLE = '/table/details',
  TABLE_CREATE = '/tables/create',
  TABLE_EDIT = '/tables/edit',

  TABLE_SYNC = '/tables/sync',
  CONTRACT_SYNC = '/contract/sync',

  TABLE_ANALYTICS = '/tables/analytics',
  TABLE_HANDS = '/tables/hands',

  TEMPLATES = '/templates',
  TEMPLATE = '/template',
  TEMPLATE_CREATE = '/templates/create',

  MIXED_GAME_CREATE = '/mixed-game/create',

  PARAMETERS_RAKE_STRUCTURE = '/structure/blinds',
  PARAMETERS_RAKE_STRUCTURE_CREATE = '/structure/blinds/create',
  PARAMETERS_RAKE_STRUCTURE_UPDATE = '/structure/blinds/update',

  PARAMETERS_GAME_CONFIGS_CREATE = '/structure/game-params/create',

  PARAMETERS_MIXED_GAMES_STRUCTURE = '/template/mixed-games',
  PARAMETERS_MIXED_GAMES_STRUCTURE_CREATE = '/template/mixed-games/create',
  PARAMETERS_MIXED_GAMES_STRUCTURE_UPDATE = '/template/mixed-games/update',

  PROP_PLAYERS = '/prop-players',

  USERS = '/users',
  USER = '/user/details',
  USER_EDIT = '/user/edit',
  USER_ANALYTICS = '/user/analytics',

  USER_DEALS = '/user/deals',

  AGREEMENTS = '/agreements',
  AGREEMENTS_CREATE = '/agreements/create',

  RAKE_SHARE = '/rake-share/payments',
  RAKE_SHARE_PER_PLAYER = '/rake-share/payments/player',

  RAKE_REWARDS = '/rake-rewards/payments',
  RAKE_REWARDS_PER_PLAYER = '/rake-rewards/payments/player',

  RAKE_BACK = '/rake-back/payments',
  RAKE_BACK_PER_PLAYER = '/rake-back/payments/player',

  RAKE_ANALYTICS = '/rake/analytics',

  RAKE_TABLE = '/rake/table',
  RAKE_PLAYER = '/rake/player',

  TOKEN_FLOW_ORCHESTRATOR_WIZARD = '/token-flow-orchestrator/wizard',
  CONTRACT_BALANCES = '/contract-balances',
  TRANSACTIONS = '/transactions',

  TOKENS_BALANCE = '/tokens/balance',

  AUTHENTICATION = '/authentication',
  LOGOUT = '/logout',
}

export default ROUTES;
