import { distributeRevenueRest } from '@/services/revenue-share/revenue-share-service';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { WizardTransition } from '../token-flow-orchestrator';
import { useState } from 'react';
import RakeSharePayout from '@/pages/rake/rake-share-payouts';
import { ContractInfo } from '@/components/ContractInfo';
import config from '@/config';

const RevenueDistribution: React.FC<WizardTransition> = ({
  currentState: _currentState,
  setState,
  setToast,
  enabled,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const revenueDistribution = () => {
    setSubmitting(true);
    distributeRevenueRest()
      .then(() => {
        setToast('Revenue successfully distributted', 'success');
        setState(6);
      })
      .catch((error) => {
        setToast(`Revenue distribution failed  ${error}`, 'warning');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      {' '}
      {show ? (
        <Card>
          <CardHeader>
            <h2>
              <span className="btn btn-light mx-2">5</span>Revenue Distribution
            </h2>
          </CardHeader>
          <CardBody>
            <button className="btn previestab" onClick={() => setShow(false)}>
              x
            </button>
            <RakeSharePayout />
          </CardBody>
        </Card>
      ) : (
        <Card>
          <CardHeader>
            <h2>
              <span className="btn btn-light mx-2">5</span>Revenue Distribution
            </h2>
          </CardHeader>
          <CardBody>
            <ContractInfo
              className="col-sm-12 col-lg-3"
              style={{ borderWidth: 1, minWidth: 'fit-content' }}
              contractAddress={config.contracts.revenueShareManager}
              contractName="RevenueShareManager"
              infoConfig={{
                showUsdtBalance: true,
                showPhnmBalance: false,
                variablesToShow: ['weeklyNetRake'],
              }}
            />
            <p>
              Distribute the revenue by creating as many transactions as necessary. Ensure that the distributions are
              complete and transition to the next state, with an option for admin override in case of any errors.
            </p>
          </CardBody>
          <CardFooter>
            <div className="d-flex justify-content-end gap-2">
              {/* <button className="btn btn-light" onClick={()=>{setShow(true)}}>Revenue</button> */}
              <button
                disabled={submitting || !enabled}
                className="btn btn-primary"
                onClick={() => {
                  if (!enabled) {
                    setToast('Please follow Orchestrator steps', 'warning');
                    return;
                  }
                  revenueDistribution();
                }}
              >
                {submitting ? 'Distributing...' : 'Distribute'}
              </button>
            </div>
          </CardFooter>
        </Card>
      )}{' '}
    </>
  );
};

export default RevenueDistribution;
