import { transferLeftoverRevenueToTreasuryRest } from '@/services/revenue-share/revenue-share-service';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { WizardTransition } from '../token-flow-orchestrator';
import { useState } from 'react';
import { ContractInfo } from '@/components/ContractInfo';
import config from '@/config';

const TransferLeftOverRakeToTreasury: React.FC<WizardTransition> = ({
  currentState: _currentState,
  setState,
  setToast,
  enabled,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const transferLeftOver = () => {
    setSubmitting(true);

    transferLeftoverRevenueToTreasuryRest()
      .then(() => {
        setToast('Transfering left over rake', 'success');
        setState(7);
      })
      .catch((error) => {
        setToast(`Transfering left over rake failed ${error}`, 'warning');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Card>
      <CardHeader>
        <h2>
          <span className="btn btn-light mx-2">6</span>Transfer leftover rake to Treasury
        </h2>
      </CardHeader>
      <CardBody>
        <ContractInfo
          className="col-sm-12 col-lg-3"
          style={{ borderWidth: 1, minWidth: 'fit-content' }}
          contractAddress={config.contracts.revenueShareManager}
          contractName="RevenueShareManager"
          infoConfig={{
            showUsdtBalance: true,
            showPhnmBalance: false,
            variablesToShow: [],
          }}
        />
        <p>
          Transfer any remaining revenue left in the Revenue Share contract to the SafeTreasury. This step ensures that
          all leftover funds are securely moved to the treasury.
        </p>
      </CardBody>
      <CardFooter>
        <div className="d-flex justify-content-end gap-2">
          <button
            disabled={submitting || !enabled}
            className="btn btn-primary"
            onClick={() => {
              if (!enabled) {
                setToast('Please follow Orchestrator steps', 'warning');
                return;
              }
              transferLeftOver();
            }}
          >
            {submitting ? 'Transferring...' : 'Transfer'}
          </button>
        </div>
      </CardFooter>
    </Card>
  );
};

export default TransferLeftOverRakeToTreasury;
