import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Container, Row } from 'reactstrap';

import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '@/common/showToast';

import { AxiosError } from 'axios';
import { fetchAgreementRest } from '@/services/agreement/agreement-service';
import { useQuery } from '@tanstack/react-query';

import { useNavigate } from 'react-router-dom';
import routes from '@/routes/routes-enums';
import { GetUserAgreementDto, UserAgreementResponseDto } from '@/services/agreement/agreement-types';
import DebouncedInput from '@/components/DebounceInput';
import Paginator from '../common/paginator';
import { SortOrder } from '@/common/sortOrder';

const Agreements: React.FC = () => {
  const navigate = useNavigate();

  const [take, setTake] = useState(25);
  const [skip, setSkip] = useState(0);
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const [isDebouncing, setIsDebouncing] = useState<boolean>(false);
  const [getUserAgreementDto, setGetUserAgreementDto] = useState<GetUserAgreementDto>({ skip, take });

  const { data, isLoading, error } = useQuery<UserAgreementResponseDto, AxiosError>({
    queryKey: ['agreementData', getUserAgreementDto],
    queryFn: () => fetchAgreementRest(getUserAgreementDto),
    staleTime: 60000,
  });

  useEffect(() => {
    setGetUserAgreementDto((prev) => ({ ...prev, skip, take }));
  }, [skip, take]);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'user.alias',
        header: 'Alias',
        type: 'string',
      },
      {
        accessorKey: 'qualifiedReferralsCount',
        header: 'Referrals count',
        type: 'number',
      },
      {
        accessorKey: 'agreement',
        header: 'Agreement',
        type: 'string',
      },
      {
        accessorKey: 'updatedAt',
        header: 'Last Update',
        type: 'date',
      },
    ],
    [],
  );

  const tableInstance = useReactTable({
    data: data?.userAgreements || [],
    columns,
    state: {
      sorting: sortingState,
    },
    onSortingChange: setSortingState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  const handleSearch = useCallback((value: string | number) => {
    setIsDebouncing(true);
    setSkip(0);
    setGetUserAgreementDto((prev: GetUserAgreementDto) => ({ ...prev, userAlias: value.toString() }));
    setIsDebouncing(false);
  }, []);

  const _handleSort = useCallback((columnId: string) => {
    setGetUserAgreementDto((prev) => {
      const newSorts = [...(prev.sorts || [])];
      const existingSortIndex = newSorts.findIndex((sort) => sort.field === columnId);
      if (existingSortIndex !== -1) {
        if (newSorts[existingSortIndex].order === SortOrder.asc) {
          newSorts[existingSortIndex].order = SortOrder.desc;
        } else {
          newSorts.splice(existingSortIndex, 1);
        }
      } else {
        newSorts.push({ field: columnId, order: SortOrder.asc });
      }
      return { ...prev, sorts: newSorts };
    });
  }, []);

  function formatDate(dateString: string) {
    return new Date(dateString).toLocaleDateString();
  }

  if (error) {
    showToast(`Error when fetching agreements data: ${error.message}`, 'warning');
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="container-fluid">
          <Row className="gy-2 mb-2">
            <div>
              <div>
                <div className="d-flex justify-content-between mb-3 flex-row">
                  <DebouncedInput
                    type="text"
                    className="form-control"
                    placeholder="Search by alias..."
                    onChange={handleSearch}
                    value={getUserAgreementDto?.userAlias || ''}
                    isLoading={isDebouncing}
                  />
                  <button
                    type="button"
                    className="btn btn-soft-primary text-white"
                    onClick={() => navigate(routes.AGREEMENTS_CREATE)}
                  >
                    <i className="ri-add-circle-line me-1 align-middle"></i> Create Agreement
                  </button>
                </div>

                <Card className="card mb-2">
                  <CardBody className="card-body">
                    <table className="table-hover table-centered table-nowrap mb-0 table align-middle">
                      <thead>
                        {tableInstance.getHeaderGroups().map((headerGroup) => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                              <th key={header.id}>
                                {header.isPlaceholder ? null : <span>{header.column.columnDef.header as string}</span>}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {!isLoading &&
                          tableInstance.getRowModel().rows.map((row) => (
                            <tr key={row.id}>
                              <td>{row.original?.user?.alias}</td>
                              <td>{row.original.qualifiedReferralsCount}</td>
                              <td>{row.original.agreement}</td>
                              <td>{formatDate(row.original.updatedAt)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <Row className="g-3 text-sm-start mt-2 text-center align-middle">
            <Paginator
              take={take}
              skip={skip}
              showing={data?.userAgreements?.length || 0}
              totalCount={data?.totalCount || 0}
              setTake={setTake}
              setSkip={setSkip}
            />
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default Agreements;
