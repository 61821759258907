import { api } from '@/services/api';

export const fundsRewardsManagerRest = async () => {
  const response = await api.post(`/v1/admin/rewards/fund-rewards-manager`);
  return response.data;
};

export const payRewardsRest = async () => {
  const response = await api.post(`/v1/admin/rewards/pay-rewards`);
  return response.data;
};

export const endRewardsDistributionRest = async () => {
  const response = await api.post(`/v1/admin/rewards/end-rewards-distribution`);
  return response.data;
};
