import React, { useEffect, useState, useCallback, useMemo, forwardRef, useImperativeHandle } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { PokerVariantIds } from '@/common/types/phenomTypes';
import FormField from '../template/form-field';
import FormFields from '@/common/forms/FormFields';
import checkVariants from '@/common/forms/table-handling/checkVariants';
import { GameParamDto, BlindStructureDto } from '@/services/game-params/parameters-types';

interface TableGameParamProps {
  blindStructures: BlindStructureDto[];
  variant: PokerVariantIds;
  initialValues?: Partial<GameParamDto>;
  mixedGame: boolean;
  onValidConfig: (config: GameParamDto) => void;
  onInvalidConfig: () => void;
  isDataLoaded: boolean;
}

const TableGameParamEdit = forwardRef<{ validate: () => Promise<boolean> }, TableGameParamProps>(
  ({ blindStructures, variant, initialValues, mixedGame, onValidConfig, onInvalidConfig, isDataLoaded }, ref) => {
    const { stud: isStud, limit: isLimitGame, capped: isCapped } = useMemo(() => checkVariants([variant]), [variant]);
    const [selectedRakeStructure, setSelectedRakeStructure] = useState<BlindStructureDto | null>(null);

    const validationSchema = Yup.object().shape({
      variantId: Yup.mixed<PokerVariantIds>()
        .oneOf(Object.values(PokerVariantIds), 'Invalid poker variant ID')
        .required(),
      bringIn: isStud
        ? Yup.number().min(0, 'Bring In must be non-negative').required('Bring In is required for stud games')
        : Yup.number().nullable(),
      ante: Yup.number().min(0, 'Ante must be non-negative').notRequired(),
      sbAnte:
        mixedGame && isLimitGame
          ? Yup.number().min(0, 'sb Ante must be non-negative').required('sb Ante is required')
          : Yup.number().min(0, 'sb Ante must be non-negative').notRequired(),
      bbAnte:
        mixedGame && isLimitGame
          ? Yup.number().min(0, 'bb Ante must be non-negative').required('bb Ante is required')
          : Yup.number().min(0, 'bb Ante must be non-negative').notRequired(),
      smallBet: Yup.number().when('isLimitGame', ([isLimitGame], schema) =>
        isLimitGame
          ? schema.required('Small Bet is required').min(0, 'Small Bet must be at least 0')
          : schema.transform(() => undefined),
      ),
      bigBet: Yup.number().when('isLimitGame', ([isLimitGame, _smallBet], schema) =>
        isLimitGame
          ? schema.required('Big Bet is required').min(Yup.ref('smallBet'), 'Big Bet must be greater than Small Bet')
          : schema.transform(() => undefined),
      ),
      bettingCap: Yup.number().when('isLimitGame', ([isLimitGame], schema) =>
        isLimitGame
          ? schema.required('Betting Cap is required').min(0, 'Big Bet must be at least 0')
          : schema.transform(() => undefined),
      ),
      smallBlind: isLimitGame
        ? Yup.number().required('Small Blind is required').min(0, 'Small Blind must be at least 0')
        : Yup.number().notRequired().min(0, 'Small Blind must be at least 0'),
      bigBlind: isLimitGame
        ? Yup.number().notRequired().min(Yup.ref('smallBlind'), 'Big Blind must be greater than Small Blind')
        : Yup.number()
            .required('Big Blind is required')
            .min(Yup.ref('smallBlind'), 'Big Blind must be greater than Small Blind'),
      rakeStructureId: Yup.string().required('Blind Structure is required'),
    });

    type ValidationSchema = Yup.InferType<typeof validationSchema>;

    function parseToIntOrDefault(initialValue: any, defaultValue: number | undefined): number | undefined {
      if (initialValue === undefined) return defaultValue;
      const parsedValue = parseInt(String(initialValue), 10);
      return isNaN(parsedValue) ? defaultValue : parsedValue;
    }

    const {
      control,
      formState: { errors, isValid },
      reset: _reset,
      watch,
      setValue,
      trigger,
    } = useForm<ValidationSchema>({
      resolver: yupResolver(validationSchema),
      defaultValues: {
        variantId: variant,
        rakeStructureId: initialValues?.rakeStructureId ?? '',
        bringIn: parseToIntOrDefault(initialValues?.bringIn, undefined),
        ante: parseToIntOrDefault(initialValues?.ante, 0),
        bbAnte: parseToIntOrDefault(initialValues?.bbAnte, 0),
        sbAnte: parseToIntOrDefault(initialValues?.sbAnte, 0),
        smallBet: parseToIntOrDefault(initialValues?.smallBet, 0),
        bigBet: parseToIntOrDefault(initialValues?.bigBet, 0),
        bettingCap: parseToIntOrDefault(initialValues?.bettingCap, 0),
        smallBlind: parseToIntOrDefault(initialValues?.smallBlind, 0),
        bigBlind: parseToIntOrDefault(initialValues?.bigBlind, 0),
      },
      mode: 'onChange',
    });

    useImperativeHandle(
      ref,
      () => ({
        validate: async () => {
          if (!isDataLoaded) return true; // Skip validation if data isn't loaded yet

          const result = await trigger();
          if (result) {
            onValidConfig(watch() as GameParamDto);
          } else {
            onInvalidConfig();
          }
          return result;
        },
      }),
      [trigger, watch, onValidConfig, onInvalidConfig, variant, isDataLoaded],
    );

    const filteredBlindStructures = useMemo(() => {
      if (isLimitGame) {
        return blindStructures.filter((structure: BlindStructureDto) => structure.bettingStructure);
      } else {
        return blindStructures.filter((structure: BlindStructureDto) => structure.blinds);
      }
    }, [blindStructures, isLimitGame]);

    useEffect(() => {
      if (isDataLoaded && blindStructures.length > 0 && initialValues?.rakeStructureId) {
        const initialBlindStructure = blindStructures.find(
          (structure: BlindStructureDto) => structure.id === initialValues.rakeStructureId,
        );
        if (initialBlindStructure) {
          setSelectedRakeStructure(initialBlindStructure);
          setValue('rakeStructureId', initialBlindStructure.id || '');
        }
      }
    }, [blindStructures, initialValues, setValue, isDataLoaded]);

    const watchedValues = watch();

    useEffect(() => {
      if (isDataLoaded && isValid && selectedRakeStructure) {
        onValidConfig({
          ...watchedValues,
          variantId: variant,
        } as GameParamDto);
      }
    }, [watchedValues, isValid, onValidConfig, onInvalidConfig, variant, selectedRakeStructure, isDataLoaded]);

    const handleRakeStructureChange = useCallback(
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        const rakeStructureId = e.target.value;
        const selectedStructure = blindStructures.find(
          (structure: BlindStructureDto) => structure.id === rakeStructureId,
        );

        if (selectedStructure) {
          setSelectedRakeStructure(selectedStructure);
          setValue('rakeStructureId', rakeStructureId);

          if (selectedStructure.blinds) {
            const [smallBlind, bigBlind] = selectedStructure.blinds.split('/').map(parseFloat);
            setValue('smallBlind', smallBlind);
            setValue('bigBlind', bigBlind);
            setValue('sbAnte', mixedGame ? smallBlind : undefined);
            setValue('bbAnte', mixedGame ? bigBlind : undefined);
            setValue('smallBet', undefined);
            setValue('bigBet', undefined);
            setValue('bettingCap', undefined);
          } else if (selectedStructure.bettingStructure) {
            const [smallBet, bigBet] = selectedStructure.bettingStructure.split('/').map(parseFloat);
            setValue('smallBet', smallBet);
            setValue('bigBet', bigBet);
            setValue('smallBlind', smallBet / 2);
            setValue('bigBlind', smallBet);
            setValue('bettingCap', bigBet * (isCapped ? 10 : 20));
          } else {
            setValue('smallBlind', 0);
            setValue('bigBlind', 0);
            setValue('smallBet', undefined);
            setValue('bigBet', undefined);
            setValue('bettingCap', undefined);
          }

          trigger();
        }
      },
      [blindStructures, setValue, trigger, mixedGame, isCapped],
    );

    return (
      <Row className="justify-content-center">
        <Col lg={8}>
          <Card className="card mb-2 mb-5 rounded p-3 shadow-lg" style={{ backgroundColor: '#d4d4d6', color: 'black' }}>
            <CardBody className="card-body">
              <h4 className="mb-3" style={{ color: 'black' }}>
                Params for {variant}
              </h4>
              <form>
                <Controller
                  name="rakeStructureId"
                  control={control}
                  render={({ field }) => (
                    <div>
                      <select
                        onChange={(e) => {
                          field.onChange(e);
                          handleRakeStructureChange(e);
                        }}
                        value={field.value}
                        className={`form-select ${errors.rakeStructureId ? 'is-invalid' : ''}`}
                      >
                        <option value="" selected={selectedRakeStructure === null}>
                          Select a blind structure
                        </option>
                        {filteredBlindStructures.map((structure: BlindStructureDto) => (
                          <option value={structure.id} key={structure.id} selected={field.value === structure.id}>
                            {`${structure.blinds ? 'Blinds :' + structure.blinds : 'Bets :' + structure.bettingStructure} | Rake: ${structure.rakePercent * 100}% (Cap: $${structure.rakeCap}, HU Cap: $${structure.rakeCapHU})`}
                          </option>
                        ))}
                      </select>
                      {errors.rakeStructureId && (
                        <div className="invalid-feedback">{errors.rakeStructureId.message}</div>
                      )}
                    </div>
                  )}
                />
                {selectedRakeStructure && (
                  <>
                    {isLimitGame ? (
                      <FormFields>
                        <FormField
                          name="smallBet"
                          control={control}
                          label="Small Bet"
                          error={errors.smallBet?.message}
                          prefix="$"
                          type="number"
                          lg={6}
                        />
                        <FormField
                          name="bigBet"
                          control={control}
                          label="Big Bet"
                          error={errors.bigBet?.message}
                          prefix="$"
                          type="number"
                          lg={6}
                        />
                      </FormFields>
                    ) : (
                      <FormFields>
                        <FormField
                          name="smallBlind"
                          control={control}
                          label="Small Blind"
                          error={errors.smallBlind?.message}
                          prefix="$"
                          type="number"
                          lg={6}
                        />
                        <FormField
                          name="bigBlind"
                          control={control}
                          label="Big Blind"
                          error={errors.bigBlind?.message}
                          prefix="$"
                          type="number"
                          lg={6}
                        />
                      </FormFields>
                    )}
                    {isLimitGame && (
                      <FormFields>
                        <FormField
                          name="bettingCap"
                          control={control}
                          label="Betting Cap"
                          error={errors.bettingCap?.message}
                          prefix="$"
                          type="number"
                          lg={6}
                        />
                      </FormFields>
                    )}
                  </>
                )}
                {isStud && (
                  <FormFields>
                    <FormField
                      name="bringIn"
                      control={control}
                      label="Bring In"
                      error={errors.bringIn?.message}
                      prefix="$"
                      type="number"
                      lg={6}
                    />
                  </FormFields>
                )}
                <FormFields>
                  <FormField
                    name="ante"
                    control={control}
                    label="Ante"
                    error={errors.ante?.message}
                    prefix="$"
                    type="number"
                    lg={6}
                  />
                </FormFields>
                {mixedGame && !isLimitGame && (
                  <FormFields>
                    <FormField
                      name="sbAnte"
                      control={control}
                      label="SB Ante"
                      error={errors.sbAnte?.message}
                      prefix="$"
                      type="number"
                      lg={6}
                    />
                    <FormField
                      name="bbAnte"
                      control={control}
                      label="BB Ante"
                      error={errors.bbAnte?.message}
                      prefix="$"
                      type="number"
                      lg={6}
                    />
                  </FormFields>
                )}
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  },
);

export default React.memo(TableGameParamEdit);
