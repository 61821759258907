import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Stream } from '@/common/types/dataTypes';

import './overviewChart.css';

interface UserGrowthChartProps {
  data: Stream[];
}

const UserGrowthChart: React.FC<UserGrowthChartProps> = ({ data }) => {
  if (!Array.isArray(data) || data.length === 0) {
    return <div>No data available</div>;
  }

  // Fill missing dates in the data
  const fillMissingDates = (data: Stream[]) => {
    const filledData: Stream[] = [];
    const startDate = new Date(data[0].dataRange);
    const endDate = new Date(data[data.length - 1].dataRange);
    const dateMap = new Map(data.map((item) => [new Date(item.dataRange).toLocaleDateString(), item.totalAmount]));

    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      const dateString = new Date(d).toLocaleDateString();
      filledData.push({
        dataRange: new Date(d),
        totalAmount: dateMap.get(dateString) || 0,
      });
    }

    return filledData;
  };

  const filledData = fillMissingDates(data);
  const categories = filledData.map((item) => new Date(item.dataRange).toLocaleDateString());
  const seriesData = filledData.map((item) => item.totalAmount);

  const chartOptions: ApexOptions = {
    chart: {
      type: 'bar',
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      labels: {
        formatter: function (value: any) {
          return value.toFixed(2);
        },
      },
      tickAmount: 5,
      min: 0,
      max: Math.max(...seriesData) + 10,
    },
    title: {
      text: 'User Growth by Day',
      align: 'center',
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#A046D3'],
  };

  const series = [
    {
      name: 'Total Users',
      data: seriesData,
    },
  ];

  return (
    <div>
      <Chart options={chartOptions} series={series} type="bar" height={350} />
    </div>
  );
};

export default UserGrowthChart;
