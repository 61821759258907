import { useEffect } from 'react';
import { useLogin, usePrivy, User } from '@privy-io/react-auth';
import { useDispatch } from 'react-redux';
import { authActionCreator, AuthEvents } from '@/services/auth/authActions';
import { UserCreatePrivy } from '@/features/auth/interfaces/privy.interface';

export default function AuthButton() {
  const dispatch = useDispatch();
  const { ready, authenticated } = usePrivy();
  // Disable login when Privy is not ready or the user is already authenticated
  const disableLogin = !ready || (ready && authenticated);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !disableLogin && login();
  }, [disableLogin]);

  const loginHandler = async (user: User, isNewUser: boolean, _wasAlreadyAuthenticated: boolean) => {
    dispatch(authActionCreator(AuthEvents.AUTHENTICATION_SUCCESS));
    // Any logic you'd like to execute if the user is/becomes authenticated while this
    // component is mounted

    if (isNewUser) {
      const newUser: UserCreatePrivy = {
        privyId: user.id,
        email: user.email?.address || user.google?.email || '',
      };
      dispatch(authActionCreator(AuthEvents.REGISTER_USER, newUser));
    } else {
      // For an existing user, directly fetch the user data
      dispatch(authActionCreator(AuthEvents.FETCH_AUTHENTICATED_USER_DATA));
    }
  };

  const errorHandler = (error: unknown) => {
    console.error(error);
  };

  const { login } =
    useLogin({
      onComplete: loginHandler,
      onError: errorHandler,
    }) || {};

  return <></>;
}
