import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';
import { useReactTable, getCoreRowModel, getSortedRowModel, ColumnDef, SortingState } from '@tanstack/react-table';
import { fetchRakeBackPerPlayerRest } from '@/services/rake/rake-service';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '@/common/showToast';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import routes from '@/routes/routes-enums';
import FormFields from '@/common/forms/FormFields';
import InfoField from '@/common/forms/InfoField';
import { fetchUserByIdRest } from '@/services/users/users-rest';
import { UserInfo } from '@/services/users/user-types';
import LinkField from '@/common/forms/LinkField';
import { RakeBackPayoutDetailDto } from '@/services/rake/rake-types';

const RakeBackPayoutPerPlayer: React.FC = () => {
  const { userId } = useParams();
  const _navigate = useNavigate();
  const [data, setData] = useState<RakeBackPayoutDetailDto[]>([]);
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const [paid, setPaid] = useState<boolean>(false);

  const [rakeBackAmount, setRakeBackAmount] = useState(0);

  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);

  useEffect(() => {
    if (!userId) return;
    fetchRakeBackPerPlayerRest(userId, paid)
      .then((response: RakeBackPayoutDetailDto[]) => {
        setData(response);
        const totalAmount = response.reduce((acc, item) => acc + item.amount, 0);
        setRakeBackAmount(totalAmount);
      })
      .catch((error: AxiosError) => {
        showToast(`Error when fetching server data: ${error.message}`, 'warning');
        setRakeBackAmount(0);
      });

    fetchUserByIdRest(userId)
      .then((response: any) => {
        setUserInfo(response);
      })
      .catch((error: AxiosError) => {
        showToast(`Error when fetching server data: ${error.message}`, 'warning');
      });
  }, [paid]);

  const columns: ColumnDef<RakeBackPayoutDetailDto>[] = [
    {
      accessorKey: 'amount',
      header: 'Amount',
      cell: (info) => info.getValue<number>().toFixed(2),
    },
    {
      accessorKey: 'count',
      header: 'Count',
    },
    {
      accessorKey: 'rakeBackRecipient.alias',
      header: 'Alias',
    },
    {
      accessorKey: 'rakeBackRecipient.isAgent',
      header: 'Is Agent',
      cell: (info) => (info.getValue() ? 'Yes' : 'No'),
    },
    {
      accessorKey: 'rakeBackRecipient.isAmbassador',
      header: 'Is Ambassador',
      cell: (info) => (info.getValue() ? 'Yes' : 'No'),
    },
  ];

  function formatISOToDate(isoString: Date | string | undefined, full = false) {
    if (!isoString || String(isoString) === '') return '';
    const newDate = new Date(String(isoString));
    return newDate.toISOString().split('T')[0] + (full ? ` ${newDate.toTimeString().split(' ')[1]}` : '');
  }

  const tableInstance = useReactTable({
    data,
    columns,
    state: {
      sorting: sortingState,
    },
    onSortingChange: setSortingState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="container-fluid">
          <Row className="gy-2 mb-2">
            <div>
              <Card>
                <CardHeader>
                  <h3>User Data</h3>
                </CardHeader>
                <CardBody>
                  <FormFields className="mb-4">
                    <LinkField
                      name="User Alias"
                      value={userInfo?.alias}
                      url={userInfo && `${routes.USER_EDIT}/${userInfo.id}`}
                    />
                    <InfoField
                      name="Agreements"
                      value={userInfo?.userAgreements?.map((agreement) => `${agreement.agreement}`)}
                    />
                  </FormFields>

                  {!paid ? (
                    <FormFields className="f-flex mb-4 flex-row">
                      <InfoField name="Rake Back Amount to pay" value={rakeBackAmount} />
                      <Col md={5} className="pt-4">
                        <button
                          onClick={() => showToast('Payouts are not implemented yet', 'warning')}
                          className="btn btn-primary"
                        >
                          Pay rake back
                        </button>
                      </Col>
                    </FormFields>
                  ) : (
                    <FormFields className="f-flex mb-4 flex-row">
                      <InfoField name="Rake Back Amount paid" value={rakeBackAmount} />
                    </FormFields>
                  )}
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h3>
                    Rake Back
                    <button onClick={() => setPaid(!paid)} className="btn btn-primary m-2">
                      {paid ? 'Paid ' : 'Unpaid'}
                    </button>
                  </h3>
                </CardHeader>
                <CardBody>
                  <table className="table-hover table-centered table-nowrap mb-0 table align-middle">
                    <thead>
                      {tableInstance.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                          <th>Rake Payout Id</th>
                          <th>Original Rake Id</th>
                          <th>Strategy</th>
                          <th>Date</th>
                          <th>Amount</th>
                          {/* {headerGroup.headers.map((header) => (
                            <th key={header.id} className="cursor-pointer">
                              {header.isPlaceholder ? null : (
                                <span onClick={() => header.column.toggleSorting()}>
                                  {header.column.columnDef.header as string}
                                  {header.column.getIsSorted() ? (
                                    header.column.getIsSorted() === "desc" ? (
                                      <i className="ri-arrow-down-s-line" />
                                    ) : (
                                      <i className="ri-arrow-up-s-line" />
                                    )
                                  ) : (
                                    <i className="ms-1 ri-arrow-up-down-line" />
                                  )}
                                </span>
                              )}
                            </th>
                          ))} */}
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      {tableInstance.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                          <td>{row.original.id}</td>
                          <td>{row.original.generatedRakeId}</td>
                          <td>{row.original.strategy}</td>
                          <td>{formatISOToDate(row.original.createdAt)}</td>
                          <td>{row.original.amount?.toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default RakeBackPayoutPerPlayer;
