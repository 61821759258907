import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import logoSm from '@/assets/images/logo-sm.svg';
import logoDark from '@/assets/images/logo-dark.svg';
import logoLight from '@/assets/images/logo-dark.svg';

import { changeSidebarVisibility } from '@/services/slices/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import ProfileDropdown from '@/pages/auth/ProfileDropdown';

const Header = ({ onChangeLayoutMode: _onChangeLayoutMode, layoutModeType: _layoutModeType, headerClass }: any) => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const selectRakeData = createSelector(
    (state: any) => state.Layout,
    (state: any) => ({
      sidebarVisibilitytype: state.sidebarVisibilitytype,
    }),
  );

  const { sidebarVisibilitytype } = useSelector(selectRakeData);

  const toogleMenuBtn = () => {
    const windowSize = document.documentElement.clientWidth;
    const humberIcon = document.querySelector('.hamburger-icon') as HTMLElement;
    dispatch(changeSidebarVisibility('show'));

    if (windowSize > 767) humberIcon.classList.toggle('open');

    //For collapse horizontal menu
    if (document.documentElement.getAttribute('data-layout') === 'horizontal') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      document.body.classList.contains('menu')
        ? document.body.classList.remove('menu')
        : document.body.classList.add('menu');
    }

    //For collapse vertical menu
    if (
      sidebarVisibilitytype === 'show' &&
      (document.documentElement.getAttribute('data-layout') === 'vertical' ||
        document.documentElement.getAttribute('data-layout') === 'semibox')
    ) {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove('vertical-sidebar-enable');
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        document.documentElement.getAttribute('data-sidebar-size') === 'sm'
          ? document.documentElement.setAttribute('data-sidebar-size', '')
          : document.documentElement.setAttribute('data-sidebar-size', 'sm');
      } else if (windowSize > 1025) {
        document.body.classList.remove('vertical-sidebar-enable');
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        document.documentElement.getAttribute('data-sidebar-size') === 'lg'
          ? document.documentElement.setAttribute('data-sidebar-size', 'sm')
          : document.documentElement.setAttribute('data-sidebar-size', 'lg');
      } else if (windowSize <= 767) {
        document.body.classList.add('vertical-sidebar-enable');
        document.documentElement.setAttribute('data-sidebar-size', 'lg');
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute('data-layout') === 'twocolumn') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      document.body.classList.contains('twocolumn-panel')
        ? document.body.classList.remove('twocolumn-panel')
        : document.body.classList.add('twocolumn-panel');
    }
  };
  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div className="layout-width">
          <div className="navbar-header justify-content-between">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="17" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="17" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm fs-16 header-item vertical-menu-btn topnav-hamburger px-3"
                id="topnav-hamburger-icon"
                onClick={toogleMenuBtn}
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>

            <div className="d-flex flex-rows gap-2">
              <button
                onClick={() => navigate(-1)}
                type="button"
                className="btn btn-sm fs-16 header-item vertical-menu-btn px-3"
              >
                <span className="ri-arrow-go-back-line"></span>
              </button>
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
