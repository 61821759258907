import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Col, Alert, Card, CardBody, Container, Row, Button } from 'reactstrap';
import { formatUnits } from '@ethersproject/units'; // Corrected import for formatUnits
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  flexRender,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchUsersRest } from '@/services/users/users-rest';
import DebouncedInput from '@/components/DebounceInput';
import { useNavigate } from 'react-router-dom';
import { UserInfo, UserRoles } from '@/services/users/user-types';
import UserEditRole from './users-edit-role';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetUserDto } from '@/services/users/user-filters';
import { format } from 'date-fns';
import UsersFilter from './user-filters';
import routes from '@/routes/routes-enums';
import CopyField from '@/common/forms/CopyField';
import config from '@/config/index';
import { abi as usdtABI } from '@/common/abis/usdt';
import { abi as phnmABI } from '@/common/abis/phnm';
import { useReadContracts } from 'wagmi';

interface BackCols {
  accessorKey?: string;
  accessorFn?: (row: any) => any;
  header: string;
  type?: string;
  isVisible?: boolean;
  cell?: (cell: any) => any;
}

const UserTable: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [take, _setTake] = useState(25);
  const [skip, setSkip] = useState(0);

  const [isDebouncing, setIsDebouncing] = useState<boolean>(false);
  const [getUsereDto, setGetUserDto] = useState<GetUserDto>({ skip, take });
  const [editItem, setEditItem] = useState<any>();
  const [modal, setModal] = useState<boolean>(false);

  const [modal_backdrop, setmodal_backdrop] = useState<boolean>(false);

  const { data, isLoading, error } = useQuery({
    queryKey: ['userData', getUsereDto],
    queryFn: () => fetchUsersRest(getUsereDto),
    staleTime: 60000,
  });

  useEffect(() => {
    setGetUserDto((prev) => ({ ...prev, skip, take }));
  }, [take, skip]);

  const { data: userUsdtBalances, isLoading: isLoadingUserUsdtBalances } = useReadContracts({
    contracts: (data?.users || []).reduce((acc: any, curr: any) => {
      if (curr.smartWalletAddress) {
        acc.push({
          address: config.contracts.usdt,
          abi: usdtABI,
          functionName: 'balanceOf',
          args: [curr?.smartWalletAddress],
        });
      }
      return acc;
    }, []),
  });
  const { data: userPHNMBalances, isLoading: isLoadingUserPHNMBalances } = useReadContracts({
    contracts: (data?.users || []).reduce((acc: any, curr: any) => {
      if (curr.smartWalletAddress) {
        acc.push({
          address: config.contracts.phnm,
          abi: phnmABI,
          functionName: 'balanceOf',
          args: [curr?.smartWalletAddress],
        });
      }
      return acc;
    }, []),
  });

  const userListWithBalances = useMemo(() => {
    if (!isLoadingUserPHNMBalances && !isLoadingUserUsdtBalances) {
      return (data?.users || []).map((user, index) => {
        return {
          ...user,
          usdtBalance: Number(
            formatUnits(userUsdtBalances?.[index]?.result || (0 as any), config.usdtDecimals),
          ).toFixed(2),
          phnmBalance: Number(
            formatUnits(userPHNMBalances?.[index]?.result || (0 as any), config.usdtDecimals),
          ).toFixed(2),
        };
      });
    }

    return [];
  }, [data?.users, userUsdtBalances, userPHNMBalances, isLoadingUserPHNMBalances, isLoadingUserUsdtBalances]);

  const refetch = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['userData', getUsereDto] });
  }, [queryClient, getUsereDto]);

  const columns: BackCols[] = useMemo(
    () => [
      {
        id: 'actions',
        header: 'Actions',
        cell: ({ row }) => {
          return (
            <button onClick={() => navigate(`${routes.USER_EDIT}/${row.original.id}`)} className="btn btn-primary">
              <i className="ri-edit-2-line"></i>
            </button>
          );
        },
        enableSorting: false,
        size: 75,
      },
      { accessorKey: 'alias', header: 'Alias', type: 'string', enableSorting: true },
      {
        accessorKey: 'firstName',
        header: 'First Name',
        type: 'string',
        enableSorting: true,
        size: 150,
      },
      { accessorKey: 'lastName', header: 'Last Name', type: 'string', enableSorting: true },
      {
        accessorKey: 'totalGeneratedRake',
        header: 'Total Rake',
        type: 'string',
        enableSorting: true,
        cell: (cell: any) => `$${cell.getValue().toFixed(2)}`,
      },
      {
        accessorKey: 'createdAt',
        header: 'Registered on',
        type: 'string',
        cell: (cell: any) => format(cell.getValue(), 'yyyy-MM-dd'),
        enableSorting: true,
      },
      {
        accessorKey: 'lastLogin',
        header: 'Last Login',
        type: 'string',
        cell: (cell: any) => (cell.getValue() ? format(cell.getValue(), 'yyyy-MM-dd') : null),
        enableSorting: true,
      },
      {
        enableSorting: false,
        accessorKey: 'smartWalletAddress',
        header: 'Wallet Address',
        cell: ({ row }) => {
          return (
            <CopyField name="Smart Wallet Address" lg={12} label={false} value={row.original?.smartWalletAddress} />
          );
        },
      },
      {
        accessorKey: 'email',
        header: 'Email',
        type: 'string',
        enableSorting: true,
        size: 200,
      },
      {
        enableSorting: true,
        id: 'userRole',
        accessorFn: (row) => {
          const isAgent = row.isAgent;
          const isAmbassador = row.isAmbassador;

          if (!isAgent && !isAmbassador) {
            return UserRoles.NO_ROLE;
          }

          if (isAgent) {
            return UserRoles.AGENT;
          }

          if (isAmbassador) {
            return UserRoles.AMBASSADOR;
          }
        },
        cell: (cell) => {
          return (
            <select
              name="role"
              id="roleSelect"
              value={cell.getValue()}
              onChange={(event) => {
                handleRoleChange(cell.row.original, event.target.value);
              }}
              className={'p-2'}
            >
              <option>{UserRoles.AGENT}</option>
              <option>{UserRoles.AMBASSADOR}</option>
              <option>{UserRoles.NO_ROLE}</option>
            </select>
          );
        },
        header: 'User Role',
      },
      {
        enableSorting: true,
        header: 'USDT Balance',
        type: 'string',
        accessorKey: 'usdtBalance',
        cell: (cell) => `$${cell.getValue()}`,
      },
      {
        header: 'PHNM Balance',
        type: 'string',
        enableSorting: true,
        accessorKey: 'phnmBalance',
      },
      { accessorKey: 'referrerCode', header: 'Referrer Code', type: 'string', enableSorting: true },
      { accessorKey: 'referralCode', header: 'Referral Code', type: 'string', enableSorting: true },
    ],
    [userListWithBalances],
  );

  const tableInstance = useReactTable({
    data: userListWithBalances,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
  });

  const handleModal = useCallback(() => {
    setModal(!modal);
    setEditItem(undefined);
  }, [modal]);

  const handleRoleChange = (user: UserInfo, role: string) => {
    setEditItem({ user, role });
    setModal(true);
  };

  const modalCallback = (success: boolean, message: string) => {
    const toastType = success ? toast.success : toast.warning;
    toastType(message, {
      position: 'bottom-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: 'light',
      transition: Bounce,
    });
  };

  const handleSearch = useCallback((value: string | number) => {
    setIsDebouncing(true);
    setSkip(0);
    setGetUserDto((prev: GetUserDto) => ({ ...prev, searchTerm: value.toString() }));
    setIsDebouncing(false);
  }, []);

  if (error) {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Alert color="danger">Error when fetching user data</Alert>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="gy-2 mb-2">
            <div className="d-flex mb-3 flex-row gap-3">
              <DebouncedInput
                type="text"
                className="form-control"
                placeholder="Search..."
                onChange={handleSearch}
                value={getUsereDto?.email || ''}
                isLoading={isDebouncing}
              />
              <div className="d-flex col-auto me-auto flex-row gap-4">
                <button
                  type="button"
                  className="btn btn-soft-primary text-white"
                  onClick={() => setmodal_backdrop(!modal_backdrop)}
                >
                  <i className="ri-filter-3-line me-1 align-middle"></i> Filter
                </button>
              </div>
            </div>
            <UsersFilter
              modal_backdrop={modal_backdrop}
              tog_backdrop={() => setmodal_backdrop(!modal_backdrop)}
              getUsereDto={getUsereDto}
              setGetUserDto={setGetUserDto}
            />
            <Card className="card mb-2">
              <CardBody className="card-body overflow-auto">
                <table className="table-hover table-centered table-nowrap mb-0 table align-middle">
                  <thead className="thead-light">
                    {tableInstance.getHeaderGroups().map((headerGroup) => {
                      return (
                        <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header) => {
                            return (
                              <th
                                role="button"
                                key={header.id}
                                colSpan={header.colSpan}
                                onClick={header.column.getToggleSortingHandler()}
                                style={{ width: `${header.getSize()}px` }}
                              >
                                <div className="d-flex justify-content-start align-items-start gap-2">
                                  <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                                  {header.column.columnDef.enableSorting && (
                                    <div>
                                      {
                                        {
                                          asc: <FaArrowDown />,
                                          desc: <FaArrowUp />,
                                          default: (
                                            <span>
                                              <FaArrowDown />
                                              <FaArrowUp />
                                            </span>
                                          ),
                                        }[(header.column.getIsSorted() as string) || 'default']
                                      }
                                    </div>
                                  )}
                                </div>
                              </th>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </thead>
                  <tbody>
                    {!isLoading &&
                      tableInstance.getRowModel().rows.map((row) => {
                        return (
                          <tr key={row.id}>
                            {row.getVisibleCells().map((cell: any) => (
                              <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                            ))}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Row>
          <Row className="g-3 text-sm-start mt-2 text-center align-middle">
            <Col>
              <Col>
                <div>Page</div>
                <strong>
                  {tableInstance.getState().pagination.pageIndex + 1} of {tableInstance.getPageCount().toLocaleString()}
                </strong>
              </Col>
              <Col className="my-2">
                <Button
                  color="primary"
                  onClick={() => tableInstance.previousPage()}
                  disabled={!tableInstance.getCanPreviousPage()}
                >
                  Prev
                </Button>
                <Button
                  className="mx-2"
                  color="primary"
                  onClick={() => tableInstance.nextPage()}
                  disabled={!tableInstance.getCanNextPage()}
                >
                  Next
                </Button>
              </Col>
            </Col>
          </Row>
          {editItem && (
            <UserEditRole
              show={modal}
              userId={editItem.user.id}
              user={editItem?.user}
              role={editItem?.role}
              refetch={refetch}
              modalCallback={modalCallback}
              handleClose={handleModal}
            />
          )}
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  );
};

export default UserTable;
