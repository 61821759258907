import { api } from '../api';
import { GetTableTemplateDto, TableTemplateResponseDto } from './templateFilters';
import { CreateTemplateDto, TemplateDto } from './templateTypes';

export const fetchAllTemplatesRest = async (): Promise<TemplateDto[]> => {
  // const response = await api.get<TemplateDto[]>(`/v1/admin/table-templates/all`);
  return []; // response.data;
};

export const fetchTemplatesRest = async (
  getTableTemplateDto: GetTableTemplateDto,
): Promise<TableTemplateResponseDto> => {
  const response = await api.get<TableTemplateResponseDto>(`/v1/admin/table-templates`, {
    params: { ...getTableTemplateDto },
  });
  return response.data;
};

export const fetchTemplateByIdRest = async (id: string) => {
  return await api.get<TemplateDto>(`/v1/admin/table-templates/${id}`);
};

export const createTemplateRest = async (params: CreateTemplateDto) => {
  return await api.post<TemplateDto>('v1/admin/table-templates/all', {
    params: params,
  });
};

export const updateTemplateRest = async (params: TemplateDto) => {
  return await api.put<TemplateDto>(`/v1/admin/table-templates/${params.id}`, {
    params: params,
  });
};
