/**
 * This file is autogenerated by Scaffold-ETH.
 * You should not edit it manually or your changes might be overwritten.
 */
import { GenericContractsDeclaration } from '@/common/utils/contract';

const deployedContracts = {
  137: {
    MockUSDT: {
      address: '0xB0234bD55BAd7C768c1C340A690339170586C9d2',
      abi: [
        {
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'allowance',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'needed',
              type: 'uint256',
            },
          ],
          name: 'ERC20InsufficientAllowance',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'balance',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'needed',
              type: 'uint256',
            },
          ],
          name: 'ERC20InsufficientBalance',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'approver',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidApprover',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'receiver',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidReceiver',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidSender',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidSpender',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'Approval',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'Transfer',
          type: 'event',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
          ],
          name: 'allowance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'approve',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'balanceOf',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'decimals',
          outputs: [
            {
              internalType: 'uint8',
              name: '',
              type: 'uint8',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'name',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'symbol',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'totalSupply',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'transfer',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'transferFrom',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        allowance: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        approve: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        balanceOf: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        decimals: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        name: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        symbol: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        totalSupply: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        transfer: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        transferFrom: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
      },
    },
    PHNMToken: {
      address: '0x35d4F6D876d6c14775DF8A74b8dE3A699cF458AC',
      abi: [
        {
          inputs: [
            {
              internalType: 'address',
              name: 'initialHolder',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Blacklisted',
          type: 'error',
        },
        {
          inputs: [],
          name: 'CheckpointUnorderedInsertion',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ECDSAInvalidSignature',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'length',
              type: 'uint256',
            },
          ],
          name: 'ECDSAInvalidSignatureLength',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          name: 'ECDSAInvalidSignatureS',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'increasedSupply',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'cap',
              type: 'uint256',
            },
          ],
          name: 'ERC20ExceededCap',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'increasedSupply',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'cap',
              type: 'uint256',
            },
          ],
          name: 'ERC20ExceededSafeSupply',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'allowance',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'needed',
              type: 'uint256',
            },
          ],
          name: 'ERC20InsufficientAllowance',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'balance',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'needed',
              type: 'uint256',
            },
          ],
          name: 'ERC20InsufficientBalance',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'approver',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidApprover',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'cap',
              type: 'uint256',
            },
          ],
          name: 'ERC20InvalidCap',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'receiver',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidReceiver',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidSender',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidSpender',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'deadline',
              type: 'uint256',
            },
          ],
          name: 'ERC2612ExpiredSignature',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'signer',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
          ],
          name: 'ERC2612InvalidSigner',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'timepoint',
              type: 'uint256',
            },
            {
              internalType: 'uint48',
              name: 'clock',
              type: 'uint48',
            },
          ],
          name: 'ERC5805FutureLookup',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ERC6372InconsistentClock',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'currentNonce',
              type: 'uint256',
            },
          ],
          name: 'InvalidAccountNonce',
          type: 'error',
        },
        {
          inputs: [],
          name: 'InvalidShortString',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
          ],
          name: 'NotWhitelisted',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
          ],
          name: 'OwnableInvalidOwner',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'OwnableUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'str',
              type: 'string',
            },
          ],
          name: 'StringTooLong',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'expiry',
              type: 'uint256',
            },
          ],
          name: 'VotesExpiredSignature',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'Approval',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'delegator',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'fromDelegate',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'toDelegate',
              type: 'address',
            },
          ],
          name: 'DelegateChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'delegate',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'previousVotes',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newVotes',
              type: 'uint256',
            },
          ],
          name: 'DelegateVotesChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'EIP712DomainChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'previousOwner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'OwnershipTransferred',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'Transfer',
          type: 'event',
        },
        {
          inputs: [],
          name: 'CLOCK_MODE',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'DOMAIN_SEPARATOR',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'addToBlacklist',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'addToWhitelist',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'adminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
          ],
          name: 'allowance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'approve',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'balanceOf',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cap',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'uint32',
              name: 'pos',
              type: 'uint32',
            },
          ],
          name: 'checkpoints',
          outputs: [
            {
              components: [
                {
                  internalType: 'uint48',
                  name: '_key',
                  type: 'uint48',
                },
                {
                  internalType: 'uint208',
                  name: '_value',
                  type: 'uint208',
                },
              ],
              internalType: 'struct Checkpoints.Checkpoint208',
              name: '',
              type: 'tuple',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'clock',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'decimals',
          outputs: [
            {
              internalType: 'uint8',
              name: '',
              type: 'uint8',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'delegatee',
              type: 'address',
            },
          ],
          name: 'delegate',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'delegatee',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'nonce',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'expiry',
              type: 'uint256',
            },
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          name: 'delegateBySig',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'delegates',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'eip712Domain',
          outputs: [
            {
              internalType: 'bytes1',
              name: 'fields',
              type: 'bytes1',
            },
            {
              internalType: 'string',
              name: 'name',
              type: 'string',
            },
            {
              internalType: 'string',
              name: 'version',
              type: 'string',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'verifyingContract',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'salt',
              type: 'bytes32',
            },
            {
              internalType: 'uint256[]',
              name: 'extensions',
              type: 'uint256[]',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'timepoint',
              type: 'uint256',
            },
          ],
          name: 'getPastTotalSupply',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'timepoint',
              type: 'uint256',
            },
          ],
          name: 'getPastVotes',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'getVotes',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'governor',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'isBlacklisted',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'isWhitelisted',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'mintAdditionalSupply',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'name',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
          ],
          name: 'nonces',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'numCheckpoints',
          outputs: [
            {
              internalType: 'uint32',
              name: '',
              type: 'uint32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'deadline',
              type: 'uint256',
            },
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          name: 'permit',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'removeFromBlacklist',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'removeFromWhitelist',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'renounceOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_governor',
              type: 'address',
            },
          ],
          name: 'setGovernor',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: '_interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'symbol',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'totalSupply',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'transfer',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'transferFrom',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'transferOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        allowance: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        approve: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        balanceOf: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        decimals: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        name: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        symbol: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        totalSupply: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        transfer: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        transferFrom: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        DOMAIN_SEPARATOR: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Permit.sol',
        eip712Domain: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        nonces: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        permit: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Permit.sol',
        CLOCK_MODE: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        checkpoints: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        clock: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        delegate: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        delegateBySig: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        delegates: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        getPastTotalSupply: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        getPastVotes: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        getVotes: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        numCheckpoints: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        cap: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        supportsInterface: '@openzeppelin/contracts/utils/introspection/ERC165.sol',
        owner: '@openzeppelin/contracts/access/Ownable.sol',
        renounceOwnership: '@openzeppelin/contracts/access/Ownable.sol',
        transferOwnership: '@openzeppelin/contracts/access/Ownable.sol',
      },
    },
    RakeManager: {
      address: '0x982EB8B24F331d93f49E42f7c78c108236F62dA4',
      abi: [
        {
          inputs: [
            {
              internalType: 'contract IERC20',
              name: '_usdtToken',
              type: 'address',
            },
            {
              internalType: 'contract IRevenueShareManager',
              name: '_revenueShareManager',
              type: 'address',
            },
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'AddressNotInWhitelist',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotRakeDistributor',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotWhitelistManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'EnforcedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ExpectedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotAllAgentsHaveBeenPaid',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotAllowedInCurrentState',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotAllowedWhenDistributingRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingAgentRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingNetRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRewards',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenFundingRewardsManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenIdle',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenSettingPHNMPrice',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringLeftoverRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringPHNM',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
          ],
          name: 'RecipientNotAllowed',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ReentrancyGuardReentrantCall',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [],
          name: 'SendTokensFailed',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Paused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'RakeSharePayout',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'revenueShareManager',
              type: 'address',
            },
          ],
          name: 'RevenueShareManagerUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'safeTreasury',
              type: 'address',
            },
          ],
          name: 'SafeTreasuryUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'newTokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'TokenFlowOrchestratorUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'TokensTransferred',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Unpaused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'bool',
              name: 'isWhitelisted',
              type: 'bool',
            },
          ],
          name: 'WhitelistUpdated',
          type: 'event',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'RAKE_DISTRIBUTOR_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'WHITELIST_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'addToWhitelist',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'allAgentsHaveBeenPaid',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'distributeNetRake',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'distributingNetRake',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'isWhitelisted',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          name: 'lastDistributionTimestamp',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'paused',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'payAgent',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address[]',
              name: 'recipients',
              type: 'address[]',
            },
            {
              internalType: 'uint256[]',
              name: 'amounts',
              type: 'uint256[]',
            },
          ],
          name: 'payAgentsBatch',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'removeFromWhitelist',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'revenueShareManager',
          outputs: [
            {
              internalType: 'contract IRevenueShareManager',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'safeTreasury',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract IRevenueShareManager',
              name: '_revenueShareManager',
              type: 'address',
            },
          ],
          name: 'setRevenueShareManager',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
          ],
          name: 'setSafeTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'setTokenFlowOrchestrator',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startDistributingAgentRake',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startDistributingNetRake',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'tokenFlowOrchestrator',
          outputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'unpause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'usdtToken',
          outputs: [
            {
              internalType: 'contract IERC20',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        tokenFlowOrchestrator: 'contracts/Orchestrated.sol',
        paused: '@openzeppelin/contracts/utils/Pausable.sol',
      },
    },
    Redemption: {
      address: '0xb52e266741CC514E1CB458C86985eC86B4d8025E',
      abi: [
        {
          inputs: [
            {
              internalType: 'contract IERC20',
              name: '_phnmToken',
              type: 'address',
            },
            {
              internalType: 'contract IRedemptionManager',
              name: '_redemptionManager',
              type: 'address',
            },
            {
              internalType: 'contract IRedemptionVault',
              name: '_redemptionVault',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'FailedToIncreaseVaultAllowance',
          type: 'error',
        },
        {
          inputs: [],
          name: 'InsufficientUsdtInContract',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
          ],
          name: 'OwnableInvalidOwner',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'OwnableUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'allowance',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'redeemAmount',
              type: 'uint256',
            },
          ],
          name: 'RedeemExceedsAllowance',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'balance',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'redeemAmount',
              type: 'uint256',
            },
          ],
          name: 'RedeemExceedsBalance',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ReentrancyGuardReentrantCall',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'InsiderTokenRedeemed',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'previousOwner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'OwnershipTransferred',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'TokenRedeemed',
          type: 'event',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'phnmToken',
          outputs: [
            {
              internalType: 'contract IERC20',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'phnmAmount',
              type: 'uint256',
            },
          ],
          name: 'redeemTokens',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'redemptionManager',
          outputs: [
            {
              internalType: 'contract IRedemptionManager',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'redemptionVault',
          outputs: [
            {
              internalType: 'contract IRedemptionVault',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'renounceOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract IRedemptionManager',
              name: '_redemptionManager',
              type: 'address',
            },
          ],
          name: 'setRedemptionManager',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract IRedemptionVault',
              name: '_redemptionVault',
              type: 'address',
            },
          ],
          name: 'setRedemptionVault',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'transferOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        owner: '@openzeppelin/contracts/access/Ownable.sol',
        renounceOwnership: '@openzeppelin/contracts/access/Ownable.sol',
        transferOwnership: '@openzeppelin/contracts/access/Ownable.sol',
      },
    },
    RedemptionManager: {
      address: '0xD6Bb3F4576f341Bbc8DbC9d0a302205C643783a8',
      abi: [
        {
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AmountMustBeGreaterThanZero',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotAllowanceManager',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotInternalTokenDealsManager',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotOwner',
          type: 'error',
        },
        {
          inputs: [],
          name: 'InsufficientAllowance',
          type: 'error',
        },
        {
          inputs: [],
          name: 'InvalidAddress',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'dealIndex',
              type: 'uint256',
            },
          ],
          name: 'InvalidDealIndex',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [],
          name: 'StartTimeMustBeGreaterThanZero',
          type: 'error',
        },
        {
          inputs: [],
          name: 'TotalTimeMustBeGreaterOrEqualToVestingPeriod',
          type: 'error',
        },
        {
          inputs: [],
          name: 'TotalTimeMustBeGreaterThanZero',
          type: 'error',
        },
        {
          inputs: [],
          name: 'VestingPeriodMustBeGreaterThanZero',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'totalTimeInSeconds',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'vestingPeriodInSeconds',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'startTimeInSeconds',
              type: 'uint256',
            },
          ],
          name: 'InternalTokensDealGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'dealIndex',
              type: 'uint256',
            },
          ],
          name: 'InternalTokensDealRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'dealIndex',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newRemainingAmount',
              type: 'uint256',
            },
          ],
          name: 'InternalTokensDealUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'RedemptionAllowanceDecreased',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'RedemptionAllowanceIncreased',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newAllowance',
              type: 'uint256',
            },
          ],
          name: 'RedemptionAllowanceUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'TokenDealRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newPrice',
              type: 'uint256',
            },
          ],
          name: 'TokenPriceSet',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'TokensRedeemed',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'TokensTransferred',
          type: 'event',
        },
        {
          inputs: [],
          name: 'ALLOWANCE_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'INTERNAL_TOKEN_DEALS_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'allowanceSchedule',
          outputs: [
            {
              internalType: 'uint256',
              name: 'timestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'allowance',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'dealTokenInfos',
          outputs: [
            {
              internalType: 'uint256',
              name: 'originalAmount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'remainingAmount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'startTime',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'endTime',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'vestingPeriod',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'lastUpdateTime',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'isRevoked',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'decreaseRedemptionAllowance',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
          ],
          name: 'getDealTokenInfo',
          outputs: [
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'originalAmount',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'remainingAmount',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'startTime',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'endTime',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'vestingPeriod',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'lastUpdateTime',
                  type: 'uint256',
                },
                {
                  internalType: 'bool',
                  name: 'isRevoked',
                  type: 'bool',
                },
              ],
              internalType: 'struct InternalTokensDealLib.InternalTokensDeal[]',
              name: '',
              type: 'tuple[]',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
          ],
          name: 'getRedemptionAllowance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'originalAmount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'totalTimeInSeconds',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'vestingPeriodInSeconds',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'startTimeInSeconds',
              type: 'uint256',
            },
          ],
          name: 'grantInternalTokensDeal',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
          ],
          name: 'hasInsiderTokens',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'increaseRedemptionAllowance',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          name: 'redemptionAllowance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'dealIndex',
              type: 'uint256',
            },
          ],
          name: 'revokeInternalTokensDeal',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
          ],
          name: 'updateRedemptionAllowance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        decreaseRedemptionAllowance: 'contracts/RedemptionManager/IRedemptionManager.sol',
        getDealTokenInfo: 'contracts/RedemptionManager/IRedemptionManager.sol',
        getRedemptionAllowance: 'contracts/RedemptionManager/IRedemptionManager.sol',
        hasInsiderTokens: 'contracts/RedemptionManager/IRedemptionManager.sol',
        increaseRedemptionAllowance: 'contracts/RedemptionManager/IRedemptionManager.sol',
        updateRedemptionAllowance: 'contracts/RedemptionManager/IRedemptionManager.sol',
      },
    },
    RedemptionVault: {
      address: '0xe1de6A1C89c626c40851FC80f8e2956B94c743ab',
      abi: [
        {
          inputs: [
            {
              internalType: 'contract IERC20Metadata',
              name: '_phnmToken',
              type: 'address',
            },
            {
              internalType: 'contract IERC20Metadata',
              name: '_usdtToken',
              type: 'address',
            },
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotAdmin',
          type: 'error',
        },
        {
          inputs: [],
          name: 'CallerNotRedemptionContract',
          type: 'error',
        },
        {
          inputs: [],
          name: 'CallerNotRevenueShareManager',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotVaultManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'EnforcedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ExpectedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'InsufficientUSDTBalance',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotAllowedWhenDistributingRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingAgentRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingNetRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRewards',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenFundingRewardsManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenIdle',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenSettingPHNMPrice',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringLeftoverRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringPHNM',
          type: 'error',
        },
        {
          inputs: [],
          name: 'PHNMTransferFailed',
          type: 'error',
        },
        {
          inputs: [],
          name: 'RedemptionExceedsDailyLimit',
          type: 'error',
        },
        {
          inputs: [],
          name: 'RedemptionExceedsLimit',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ReentrancyGuardReentrantCall',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [],
          name: 'TransferToTreasuryFailed',
          type: 'error',
        },
        {
          inputs: [],
          name: 'USDTTransferFailed',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'totalRedemption',
              type: 'uint256',
            },
          ],
          name: 'DailyRedemptionLimitExceeded',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Paused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'redemptionContract',
              type: 'address',
            },
          ],
          name: 'RedemptionContractUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'revenueShareManager',
              type: 'address',
            },
          ],
          name: 'RevenueShareManagerUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'safeTreasury',
              type: 'address',
            },
          ],
          name: 'SafeTreasuryUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'newTokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'TokenFlowOrchestratorUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newTokenPrice',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'maxRedemptionTokenPrice',
              type: 'uint256',
            },
          ],
          name: 'TokenPriceUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'phnmAmount',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'usdtAmount',
              type: 'uint256',
            },
          ],
          name: 'TokensRedeemed',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'treasury',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'TokensTransferredToTreasury',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'treasury',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'USDTTransferredToTreasury',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Unpaused',
          type: 'event',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'VAULT_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultDailyRedemptionLimit',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_user',
              type: 'address',
            },
          ],
          name: 'getDailyRedemptionLimit',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'getMaxRedemptionTokenPrice',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'getMonthlyRedemptionLimitInUSDT',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'getTokenPrice',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'timeFrame',
              type: 'uint256',
            },
          ],
          name: 'getTotalRedemptionInPHNM',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'timeFrame',
              type: 'uint256',
            },
          ],
          name: 'getTotalRedemptionInUSDT',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'maxRedemptionTokenPrice',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'paused',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'phnmToken',
          outputs: [
            {
              internalType: 'contract IERC20Metadata',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: '_phnmAmount',
              type: 'uint256',
            },
          ],
          name: 'redeemTokens',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'redemptionContract',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'revenueShareManager',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'safeTreasury',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_redemption',
              type: 'address',
            },
          ],
          name: 'setRedemptionContract',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_revenueShareManager',
              type: 'address',
            },
          ],
          name: 'setRevenueShareManager',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
          ],
          name: 'setSafeTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'setTokenFlowOrchestrator',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startPHNMTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'tokenFlowOrchestrator',
          outputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'tokenPrice',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_amount',
              type: 'uint256',
            },
          ],
          name: 'transferPHNMToTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_amount',
              type: 'uint256',
            },
          ],
          name: 'transferUSDTToTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'unpause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: '_newLimit',
              type: 'uint256',
            },
          ],
          name: 'updateDailyRedemptionLimit',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_newNaturalPrice',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: '_maxRedemptionTokenPrice',
              type: 'uint256',
            },
          ],
          name: 'updateTokenPrice',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'usdtToken',
          outputs: [
            {
              internalType: 'contract IERC20Metadata',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getTokenPrice: 'contracts/RedemptionVault/IRedemptionVault.sol',
        redeemTokens: 'contracts/RedemptionVault/IRedemptionVault.sol',
        updateTokenPrice: 'contracts/RedemptionVault/IRedemptionVault.sol',
        tokenFlowOrchestrator: 'contracts/Orchestrated.sol',
        paused: '@openzeppelin/contracts/utils/Pausable.sol',
      },
    },
    RevenueShareManager: {
      address: '0x90fd5219A633b0abcbD60eb771aE33DDC2077b07',
      abi: [
        {
          inputs: [
            {
              internalType: 'contract IERC20Metadata',
              name: '_phnmToken',
              type: 'address',
            },
            {
              internalType: 'contract IERC20Metadata',
              name: '_usdtToken',
              type: 'address',
            },
            {
              internalType: 'contract IRedemptionVault',
              name: '_redemptionVault',
              type: 'address',
            },
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AlreadyInitialized',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotContractManager',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotPriceManager',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotRevenueManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'EnforcedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ExpectedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'HoldersAndAmountsLengthMismatch',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotAllowedWhenDistributingRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotInitialized',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotRakeManagerContract',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingAgentRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingNetRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRewards',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenFundingRewardsManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenIdle',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenSettingPHNMPrice',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringLeftoverRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringPHNM',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ReentrancyGuardReentrantCall',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [],
          name: 'UsdtBalanceMismatch',
          type: 'error',
        },
        {
          inputs: [],
          name: 'WeeklyRakeCooldownNotMet',
          type: 'error',
        },
        {
          inputs: [],
          name: 'WeeklyUsdtTotalNotSet',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'holder',
              type: 'address',
            },
          ],
          name: 'DistributionCooldownNotMet',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'holder',
              type: 'address',
            },
          ],
          name: 'DistributionThresholdNotMet',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'gracePeriod',
              type: 'uint256',
            },
          ],
          name: 'GracePeriodUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'Initialized',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'LeftoverRevenueTransferred',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Paused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'price',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'maxPrice',
              type: 'uint256',
            },
          ],
          name: 'PriceCapped',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: '_rakeManager',
              type: 'address',
            },
          ],
          name: 'RakeManagerUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: '_redemptionVault',
              type: 'address',
            },
          ],
          name: 'RedemptionVaultUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'RevenueShareDistributed',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
          ],
          name: 'SafeTreasuryUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'newTokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'TokenFlowOrchestratorUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Unpaused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newTotal',
              type: 'uint256',
            },
          ],
          name: 'WeeklyNetRakeUpdated',
          type: 'event',
        },
        {
          inputs: [],
          name: 'BUFFER_SIZE',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'DISTRIBUTION_COOLDOWN',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'DISTRIBUTION_THRESHOLD',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'PRICE_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'REVENUE_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'WEEKLY_RAKE_UPDATE_COOLDOWN',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'WEEKLY_RAKE_UPDATE_GRACE_PERIOD',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address[]',
              name: 'holders',
              type: 'address[]',
            },
          ],
          name: 'distributeRevenueBatch',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'getTotalLastFourWeeksNetRake',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'gracePeriod',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_launchDate',
              type: 'uint256',
            },
          ],
          name: 'initialize',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'isInitialized',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          name: 'lastDistributionTimestamp',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'lastFourWeeksNetRake',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'lastNetRakeUpdateTimestamp',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'launchDate',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'paused',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'phnmToken',
          outputs: [
            {
              internalType: 'contract IERC20Metadata',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rakeManager',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'redemptionVault',
          outputs: [
            {
              internalType: 'contract IRedemptionVault',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'safeTreasury',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_newGracePeriod',
              type: 'uint256',
            },
          ],
          name: 'setGracePeriod',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_rakeManager',
              type: 'address',
            },
          ],
          name: 'setRakeManager',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract IRedemptionVault',
              name: '_redemptionVault',
              type: 'address',
            },
          ],
          name: 'setRedemptionVault',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
          ],
          name: 'setSafeTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'setTokenFlowOrchestrator',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startLeftoverRevenueTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startPHNMPriceUpdate',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startRevenueDistribution',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'tokenFlowOrchestrator',
          outputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'totalWeeks',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'transferLeftoverRevenueToTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'unpause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'updatePhnmTokenPrice',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_weeklyNetRake',
              type: 'uint256',
            },
          ],
          name: 'updateWeeklyNetRake',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'usdtToken',
          outputs: [
            {
              internalType: 'contract IERC20Metadata',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'weeklyNetRake',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        updateWeeklyNetRake: 'contracts/RevenueShareManager/IRevenueShareManager.sol',
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        paused: '@openzeppelin/contracts/utils/Pausable.sol',
        tokenFlowOrchestrator: 'contracts/Orchestrated.sol',
      },
    },
    RewardsManager: {
      address: '0x06842D94BD5208A44fB581189E8D2D7Da40ED781',
      abi: [
        {
          inputs: [
            {
              internalType: 'contract IERC20',
              name: '_rewardsToken',
              type: 'address',
            },
            {
              internalType: 'contract IRedemptionManager',
              name: '_redemptionManager',
              type: 'address',
            },
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotRewardsDistributor',
          type: 'error',
        },
        {
          inputs: [],
          name: 'EnforcedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ExpectedPause',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'balance',
              type: 'uint256',
            },
          ],
          name: 'InsufficientFundsForReward',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotAllowedWhenDistributingRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingAgentRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingNetRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRewards',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenFundingRewardsManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenIdle',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenSettingPHNMPrice',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringLeftoverRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringPHNM',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Paused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'redemptionManager',
              type: 'address',
            },
          ],
          name: 'RedemptionManagerUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'RewardPaid',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'safeTreasury',
              type: 'address',
            },
          ],
          name: 'SafeTreasuryUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'newTokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'TokenFlowOrchestratorUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'TokensWithdrawn',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Unpaused',
          type: 'event',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'REWARDS_DISTRIBUTOR_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'endRewardsDistribution',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'currentCycleStartTime',
              type: 'uint256',
            },
          ],
          name: 'isPaymentDue',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          name: 'lastRewardPayment',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'paused',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'payReward',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address[]',
              name: 'users',
              type: 'address[]',
            },
            {
              internalType: 'uint256[]',
              name: 'amounts',
              type: 'uint256[]',
            },
          ],
          name: 'payRewardsBatch',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'redemptionManager',
          outputs: [
            {
              internalType: 'contract IRedemptionManager',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          name: 'rewardDebt',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rewardsToken',
          outputs: [
            {
              internalType: 'contract IERC20',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'safeTreasury',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract IRedemptionManager',
              name: '_redemptionManager',
              type: 'address',
            },
          ],
          name: 'setRedemptionManager',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_treasury',
              type: 'address',
            },
          ],
          name: 'setSafeTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'setTokenFlowOrchestrator',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startRewardsDistribution',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startRewardsFunding',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'tokenFlowOrchestrator',
          outputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'unpause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'withdrawTokens',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        paused: '@openzeppelin/contracts/utils/Pausable.sol',
        tokenFlowOrchestrator: 'contracts/Orchestrated.sol',
      },
    },
    Tables: {
      address: '0xC10e197E783394D08310F5013F05362a64a891C4',
      abi: [
        {
          inputs: [
            {
              internalType: 'contract IERC20',
              name: '_usdtToken',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [],
          name: 'CallerNotAdmin',
          type: 'error',
        },
        {
          inputs: [],
          name: 'CallerNotTableManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'CollectRakeFailed',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
          ],
          name: 'InactiveTable',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'buyIn',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'minBuyIn',
              type: 'uint256',
            },
          ],
          name: 'InsufficientBuyIn',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
          ],
          name: 'InsufficientRake',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'stake',
              type: 'uint256',
            },
          ],
          name: 'InvalidStake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'InvalidTable',
          type: 'error',
        },
        {
          inputs: [],
          name: 'JoinTableFailed',
          type: 'error',
        },
        {
          inputs: [],
          name: 'LeaveTableFailed',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'buyIn',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'maxBuyIn',
              type: 'uint256',
            },
          ],
          name: 'MaxBuyInExceeded',
          type: 'error',
        },
        {
          inputs: [],
          name: 'MaxBuyInMustBeGreaterThanMinBuyIn',
          type: 'error',
        },
        {
          inputs: [],
          name: 'MinBuyInCantBeZero',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
          ],
          name: 'PlayerAlreadyInTable',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
          ],
          name: 'PlayerNotInTable',
          type: 'error',
        },
        {
          inputs: [],
          name: 'RakeManagerNotSet',
          type: 'error',
        },
        {
          inputs: [],
          name: 'RebuyFailed',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ReentrancyGuardReentrantCall',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'buyIn',
              type: 'uint256',
            },
          ],
          name: 'JoinTable',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'stake',
              type: 'uint256',
            },
          ],
          name: 'LeaveTable',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'rake',
              type: 'uint256',
            },
          ],
          name: 'RakeCollected',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'newRakeManager',
              type: 'address',
            },
          ],
          name: 'RakeManagerChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'Rebuy',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'time',
              type: 'uint256',
            },
          ],
          name: 'SessionTimeChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'minBuyIn',
              type: 'uint256',
            },
          ],
          name: 'TableCreated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
          ],
          name: 'TableResumed',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
          ],
          name: 'TableStopped',
          type: 'event',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'TABLE_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'rake',
              type: 'uint256',
            },
          ],
          name: 'collectRake',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'minBuyIn',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'maxBuyin',
              type: 'uint256',
            },
          ],
          name: 'createTable',
          outputs: [
            {
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
          ],
          name: 'getStack',
          outputs: [
            {
              internalType: 'uint256',
              name: 'stack',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'buyIn',
              type: 'uint256',
            },
          ],
          name: 'joinTable',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'leaveTable',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'playerInTable',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
          ],
          name: 'playerTableSession',
          outputs: [
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'stack',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'timestamp',
                  type: 'uint256',
                },
              ],
              internalType: 'struct Tables.Session',
              name: '',
              type: 'tuple',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rakeManager',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'rebuy',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
          ],
          name: 'resumeTable',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'sessionTime',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newRakeManager',
              type: 'address',
            },
          ],
          name: 'setRakeManager',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'time',
              type: 'uint256',
            },
          ],
          name: 'setSessionTime',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
          ],
          name: 'stopTable',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
          ],
          name: 'tableBalance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
          ],
          name: 'tablePlayers',
          outputs: [
            {
              internalType: 'address[]',
              name: '',
              type: 'address[]',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          name: 'tableSessions',
          outputs: [
            {
              internalType: 'uint256',
              name: 'stack',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'timestamp',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'tables',
          outputs: [
            {
              internalType: 'uint256',
              name: 'minBuyIn',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'maxBuyIn',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'active',
              type: 'bool',
            },
            {
              internalType: 'uint256',
              name: 'balance',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'usdtToken',
          outputs: [
            {
              internalType: 'contract IERC20',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
      },
    },
    TokenFlowOrchestrator: {
      address: '0x276d28120Bc89FDFf8E52f23B025A2cb53615e0A',
      abi: [
        {
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotStateManager',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'state',
              type: 'uint256',
            },
          ],
          name: 'InvalidState',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'currentState',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'newState',
              type: 'uint256',
            },
          ],
          name: 'InvalidStateTransition',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'previousState',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newState',
              type: 'uint256',
            },
          ],
          name: 'StateTransition',
          type: 'event',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'STATE_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'currentState',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'getCurrentState',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'nextStateMapping',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_newState',
              type: 'uint256',
            },
          ],
          name: 'setState',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'stateOrder',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_newState',
              type: 'uint256',
            },
          ],
          name: 'transitionToState',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        getCurrentState: 'contracts/TokenFlowOrchestrator/ITokenFlowOrchestrator.sol',
        transitionToState: 'contracts/TokenFlowOrchestrator/ITokenFlowOrchestrator.sol',
        currentState: 'contracts/StateMachine.sol',
        nextStateMapping: 'contracts/StateMachine.sol',
        stateOrder: 'contracts/StateMachine.sol',
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
      },
    },
  },
  31337: {
    MockUSDT: {
      address: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
      abi: [
        {
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'allowance',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'needed',
              type: 'uint256',
            },
          ],
          name: 'ERC20InsufficientAllowance',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'balance',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'needed',
              type: 'uint256',
            },
          ],
          name: 'ERC20InsufficientBalance',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'approver',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidApprover',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'receiver',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidReceiver',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidSender',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidSpender',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'Approval',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'Transfer',
          type: 'event',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
          ],
          name: 'allowance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'approve',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'balanceOf',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'decimals',
          outputs: [
            {
              internalType: 'uint8',
              name: '',
              type: 'uint8',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'name',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'symbol',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'totalSupply',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'transfer',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'transferFrom',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        allowance: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        approve: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        balanceOf: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        decimals: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        name: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        symbol: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        totalSupply: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        transfer: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        transferFrom: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
      },
    },
    PHNMToken: {
      address: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',
      abi: [
        {
          inputs: [
            {
              internalType: 'address',
              name: 'initialHolder',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Blacklisted',
          type: 'error',
        },
        {
          inputs: [],
          name: 'CheckpointUnorderedInsertion',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ECDSAInvalidSignature',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'length',
              type: 'uint256',
            },
          ],
          name: 'ECDSAInvalidSignatureLength',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          name: 'ECDSAInvalidSignatureS',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'increasedSupply',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'cap',
              type: 'uint256',
            },
          ],
          name: 'ERC20ExceededCap',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'increasedSupply',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'cap',
              type: 'uint256',
            },
          ],
          name: 'ERC20ExceededSafeSupply',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'allowance',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'needed',
              type: 'uint256',
            },
          ],
          name: 'ERC20InsufficientAllowance',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'balance',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'needed',
              type: 'uint256',
            },
          ],
          name: 'ERC20InsufficientBalance',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'approver',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidApprover',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'cap',
              type: 'uint256',
            },
          ],
          name: 'ERC20InvalidCap',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'receiver',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidReceiver',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidSender',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidSpender',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'deadline',
              type: 'uint256',
            },
          ],
          name: 'ERC2612ExpiredSignature',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'signer',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
          ],
          name: 'ERC2612InvalidSigner',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'timepoint',
              type: 'uint256',
            },
            {
              internalType: 'uint48',
              name: 'clock',
              type: 'uint48',
            },
          ],
          name: 'ERC5805FutureLookup',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ERC6372InconsistentClock',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'currentNonce',
              type: 'uint256',
            },
          ],
          name: 'InvalidAccountNonce',
          type: 'error',
        },
        {
          inputs: [],
          name: 'InvalidShortString',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
          ],
          name: 'NotWhitelisted',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
          ],
          name: 'OwnableInvalidOwner',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'OwnableUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'str',
              type: 'string',
            },
          ],
          name: 'StringTooLong',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'expiry',
              type: 'uint256',
            },
          ],
          name: 'VotesExpiredSignature',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'Approval',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'delegator',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'fromDelegate',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'toDelegate',
              type: 'address',
            },
          ],
          name: 'DelegateChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'delegate',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'previousVotes',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newVotes',
              type: 'uint256',
            },
          ],
          name: 'DelegateVotesChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'EIP712DomainChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'previousOwner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'OwnershipTransferred',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'Transfer',
          type: 'event',
        },
        {
          inputs: [],
          name: 'CLOCK_MODE',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'DOMAIN_SEPARATOR',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'addToBlacklist',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'addToWhitelist',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'adminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
          ],
          name: 'allowance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'approve',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'balanceOf',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cap',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'uint32',
              name: 'pos',
              type: 'uint32',
            },
          ],
          name: 'checkpoints',
          outputs: [
            {
              components: [
                {
                  internalType: 'uint48',
                  name: '_key',
                  type: 'uint48',
                },
                {
                  internalType: 'uint208',
                  name: '_value',
                  type: 'uint208',
                },
              ],
              internalType: 'struct Checkpoints.Checkpoint208',
              name: '',
              type: 'tuple',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'clock',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'decimals',
          outputs: [
            {
              internalType: 'uint8',
              name: '',
              type: 'uint8',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'delegatee',
              type: 'address',
            },
          ],
          name: 'delegate',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'delegatee',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'nonce',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'expiry',
              type: 'uint256',
            },
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          name: 'delegateBySig',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'delegates',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'eip712Domain',
          outputs: [
            {
              internalType: 'bytes1',
              name: 'fields',
              type: 'bytes1',
            },
            {
              internalType: 'string',
              name: 'name',
              type: 'string',
            },
            {
              internalType: 'string',
              name: 'version',
              type: 'string',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'verifyingContract',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'salt',
              type: 'bytes32',
            },
            {
              internalType: 'uint256[]',
              name: 'extensions',
              type: 'uint256[]',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'timepoint',
              type: 'uint256',
            },
          ],
          name: 'getPastTotalSupply',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'timepoint',
              type: 'uint256',
            },
          ],
          name: 'getPastVotes',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'getVotes',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'governor',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'isBlacklisted',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'isWhitelisted',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'mintAdditionalSupply',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'name',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
          ],
          name: 'nonces',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'numCheckpoints',
          outputs: [
            {
              internalType: 'uint32',
              name: '',
              type: 'uint32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'deadline',
              type: 'uint256',
            },
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          name: 'permit',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'removeFromBlacklist',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'removeFromWhitelist',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'renounceOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_governor',
              type: 'address',
            },
          ],
          name: 'setGovernor',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: '_interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'symbol',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'totalSupply',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'transfer',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'transferFrom',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'transferOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        allowance: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        approve: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        balanceOf: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        decimals: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        name: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        symbol: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        totalSupply: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        transfer: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        transferFrom: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        DOMAIN_SEPARATOR: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Permit.sol',
        eip712Domain: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        nonces: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        permit: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Permit.sol',
        CLOCK_MODE: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        checkpoints: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        clock: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        delegate: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        delegateBySig: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        delegates: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        getPastTotalSupply: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        getPastVotes: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        getVotes: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        numCheckpoints: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Votes.sol',
        cap: '@openzeppelin/contracts/token/ERC20/extensions/ERC20Capped.sol',
        supportsInterface: '@openzeppelin/contracts/utils/introspection/ERC165.sol',
        owner: '@openzeppelin/contracts/access/Ownable.sol',
        renounceOwnership: '@openzeppelin/contracts/access/Ownable.sol',
        transferOwnership: '@openzeppelin/contracts/access/Ownable.sol',
      },
    },
    RakeManager: {
      address: '0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6',
      abi: [
        {
          inputs: [
            {
              internalType: 'contract IERC20',
              name: '_usdtToken',
              type: 'address',
            },
            {
              internalType: 'contract IRevenueShareManager',
              name: '_revenueShareManager',
              type: 'address',
            },
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'AddressNotInWhitelist',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotRakeDistributor',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotWhitelistManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'EnforcedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ExpectedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotAllAgentsHaveBeenPaid',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotAllowedInCurrentState',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotAllowedWhenDistributingRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingAgentRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingNetRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRewards',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenFundingRewardsManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenIdle',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenSettingPHNMPrice',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringLeftoverRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringPHNM',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
          ],
          name: 'RecipientNotAllowed',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ReentrancyGuardReentrantCall',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [],
          name: 'SendTokensFailed',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Paused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'RakeSharePayout',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'revenueShareManager',
              type: 'address',
            },
          ],
          name: 'RevenueShareManagerUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'safeTreasury',
              type: 'address',
            },
          ],
          name: 'SafeTreasuryUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'newTokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'TokenFlowOrchestratorUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'TokensTransferred',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Unpaused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'bool',
              name: 'isWhitelisted',
              type: 'bool',
            },
          ],
          name: 'WhitelistUpdated',
          type: 'event',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'RAKE_DISTRIBUTOR_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'WHITELIST_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'addToWhitelist',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'allAgentsHaveBeenPaid',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'distributeNetRake',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'distributingNetRake',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'isWhitelisted',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          name: 'lastDistributionTimestamp',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'paused',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'payAgent',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address[]',
              name: 'recipients',
              type: 'address[]',
            },
            {
              internalType: 'uint256[]',
              name: 'amounts',
              type: 'uint256[]',
            },
          ],
          name: 'payAgentsBatch',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'removeFromWhitelist',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'revenueShareManager',
          outputs: [
            {
              internalType: 'contract IRevenueShareManager',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'safeTreasury',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract IRevenueShareManager',
              name: '_revenueShareManager',
              type: 'address',
            },
          ],
          name: 'setRevenueShareManager',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
          ],
          name: 'setSafeTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'setTokenFlowOrchestrator',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startDistributingAgentRake',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startDistributingNetRake',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'tokenFlowOrchestrator',
          outputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'unpause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'usdtToken',
          outputs: [
            {
              internalType: 'contract IERC20',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        tokenFlowOrchestrator: 'contracts/Orchestrated.sol',
        paused: '@openzeppelin/contracts/utils/Pausable.sol',
      },
    },
    Redemption: {
      address: '0x0165878A594ca255338adfa4d48449f69242Eb8F',
      abi: [
        {
          inputs: [
            {
              internalType: 'contract IERC20',
              name: '_phnmToken',
              type: 'address',
            },
            {
              internalType: 'contract IRedemptionManager',
              name: '_redemptionManager',
              type: 'address',
            },
            {
              internalType: 'contract IRedemptionVault',
              name: '_redemptionVault',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'FailedToIncreaseVaultAllowance',
          type: 'error',
        },
        {
          inputs: [],
          name: 'InsufficientUsdtInContract',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
          ],
          name: 'OwnableInvalidOwner',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'OwnableUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'allowance',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'redeemAmount',
              type: 'uint256',
            },
          ],
          name: 'RedeemExceedsAllowance',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'balance',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'redeemAmount',
              type: 'uint256',
            },
          ],
          name: 'RedeemExceedsBalance',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ReentrancyGuardReentrantCall',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'InsiderTokenRedeemed',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'previousOwner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'OwnershipTransferred',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'TokenRedeemed',
          type: 'event',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'phnmToken',
          outputs: [
            {
              internalType: 'contract IERC20',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'phnmAmount',
              type: 'uint256',
            },
          ],
          name: 'redeemTokens',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'redemptionManager',
          outputs: [
            {
              internalType: 'contract IRedemptionManager',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'redemptionVault',
          outputs: [
            {
              internalType: 'contract IRedemptionVault',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'renounceOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract IRedemptionManager',
              name: '_redemptionManager',
              type: 'address',
            },
          ],
          name: 'setRedemptionManager',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract IRedemptionVault',
              name: '_redemptionVault',
              type: 'address',
            },
          ],
          name: 'setRedemptionVault',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'transferOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        owner: '@openzeppelin/contracts/access/Ownable.sol',
        renounceOwnership: '@openzeppelin/contracts/access/Ownable.sol',
        transferOwnership: '@openzeppelin/contracts/access/Ownable.sol',
      },
    },
    RedemptionManager: {
      address: '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9',
      abi: [
        {
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AmountMustBeGreaterThanZero',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotAllowanceManager',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotInternalTokenDealsManager',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotOwner',
          type: 'error',
        },
        {
          inputs: [],
          name: 'InsufficientAllowance',
          type: 'error',
        },
        {
          inputs: [],
          name: 'InvalidAddress',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'dealIndex',
              type: 'uint256',
            },
          ],
          name: 'InvalidDealIndex',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [],
          name: 'StartTimeMustBeGreaterThanZero',
          type: 'error',
        },
        {
          inputs: [],
          name: 'TotalTimeMustBeGreaterOrEqualToVestingPeriod',
          type: 'error',
        },
        {
          inputs: [],
          name: 'TotalTimeMustBeGreaterThanZero',
          type: 'error',
        },
        {
          inputs: [],
          name: 'VestingPeriodMustBeGreaterThanZero',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'totalTimeInSeconds',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'vestingPeriodInSeconds',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'startTimeInSeconds',
              type: 'uint256',
            },
          ],
          name: 'InternalTokensDealGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'dealIndex',
              type: 'uint256',
            },
          ],
          name: 'InternalTokensDealRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'dealIndex',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newRemainingAmount',
              type: 'uint256',
            },
          ],
          name: 'InternalTokensDealUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'RedemptionAllowanceDecreased',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'RedemptionAllowanceIncreased',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newAllowance',
              type: 'uint256',
            },
          ],
          name: 'RedemptionAllowanceUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'TokenDealRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newPrice',
              type: 'uint256',
            },
          ],
          name: 'TokenPriceSet',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'TokensRedeemed',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'TokensTransferred',
          type: 'event',
        },
        {
          inputs: [],
          name: 'ALLOWANCE_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'INTERNAL_TOKEN_DEALS_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'allowanceSchedule',
          outputs: [
            {
              internalType: 'uint256',
              name: 'timestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'allowance',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'dealTokenInfos',
          outputs: [
            {
              internalType: 'uint256',
              name: 'originalAmount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'remainingAmount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'startTime',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'endTime',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'vestingPeriod',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'lastUpdateTime',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'isRevoked',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'decreaseRedemptionAllowance',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
          ],
          name: 'getDealTokenInfo',
          outputs: [
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'originalAmount',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'remainingAmount',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'startTime',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'endTime',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'vestingPeriod',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'lastUpdateTime',
                  type: 'uint256',
                },
                {
                  internalType: 'bool',
                  name: 'isRevoked',
                  type: 'bool',
                },
              ],
              internalType: 'struct InternalTokensDealLib.InternalTokensDeal[]',
              name: '',
              type: 'tuple[]',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
          ],
          name: 'getRedemptionAllowance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'originalAmount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'totalTimeInSeconds',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'vestingPeriodInSeconds',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'startTimeInSeconds',
              type: 'uint256',
            },
          ],
          name: 'grantInternalTokensDeal',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
          ],
          name: 'hasInsiderTokens',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'increaseRedemptionAllowance',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          name: 'redemptionAllowance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'dealIndex',
              type: 'uint256',
            },
          ],
          name: 'revokeInternalTokensDeal',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
          ],
          name: 'updateRedemptionAllowance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        decreaseRedemptionAllowance: 'contracts/RedemptionManager/IRedemptionManager.sol',
        getDealTokenInfo: 'contracts/RedemptionManager/IRedemptionManager.sol',
        getRedemptionAllowance: 'contracts/RedemptionManager/IRedemptionManager.sol',
        hasInsiderTokens: 'contracts/RedemptionManager/IRedemptionManager.sol',
        increaseRedemptionAllowance: 'contracts/RedemptionManager/IRedemptionManager.sol',
        updateRedemptionAllowance: 'contracts/RedemptionManager/IRedemptionManager.sol',
      },
    },
    RedemptionVault: {
      address: '0x5FC8d32690cc91D4c39d9d3abcBD16989F875707',
      abi: [
        {
          inputs: [
            {
              internalType: 'contract IERC20Metadata',
              name: '_phnmToken',
              type: 'address',
            },
            {
              internalType: 'contract IERC20Metadata',
              name: '_usdtToken',
              type: 'address',
            },
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotAdmin',
          type: 'error',
        },
        {
          inputs: [],
          name: 'CallerNotRedemptionContract',
          type: 'error',
        },
        {
          inputs: [],
          name: 'CallerNotRevenueShareManager',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotVaultManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'EnforcedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ExpectedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'InsufficientUSDTBalance',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotAllowedWhenDistributingRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingAgentRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingNetRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRewards',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenFundingRewardsManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenIdle',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenSettingPHNMPrice',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringLeftoverRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringPHNM',
          type: 'error',
        },
        {
          inputs: [],
          name: 'PHNMTransferFailed',
          type: 'error',
        },
        {
          inputs: [],
          name: 'RedeemExceedsLimit',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ReentrancyGuardReentrantCall',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [],
          name: 'TransferToTreasuryFailed',
          type: 'error',
        },
        {
          inputs: [],
          name: 'USDTTransferFailed',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Paused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'redemptionContract',
              type: 'address',
            },
          ],
          name: 'RedemptionContractUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'revenueShareManager',
              type: 'address',
            },
          ],
          name: 'RevenueShareManagerUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'safeTreasury',
              type: 'address',
            },
          ],
          name: 'SafeTreasuryUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'newTokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'TokenFlowOrchestratorUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newTokenPrice',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'maxRedemptionTokenPrice',
              type: 'uint256',
            },
          ],
          name: 'TokenPriceUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'phnmAmount',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'usdtAmount',
              type: 'uint256',
            },
          ],
          name: 'TokensRedeemed',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'treasury',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'TokensTransferredToTreasury',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'treasury',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'USDTTransferredToTreasury',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Unpaused',
          type: 'event',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'VAULT_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'getMaxRedemptionTokenPrice',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'getTokenPrice',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'maxRedemptionTokenPrice',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'paused',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'phnmToken',
          outputs: [
            {
              internalType: 'contract IERC20Metadata',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: '_phnmAmount',
              type: 'uint256',
            },
          ],
          name: 'redeemTokens',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'redemptionContract',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'revenueShareManager',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'safeTreasury',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_redemption',
              type: 'address',
            },
          ],
          name: 'setRedemptionContract',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_revenueShareManager',
              type: 'address',
            },
          ],
          name: 'setRevenueShareManager',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
          ],
          name: 'setSafeTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'setTokenFlowOrchestrator',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startPHNMTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'tokenFlowOrchestrator',
          outputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'tokenPrice',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_amount',
              type: 'uint256',
            },
          ],
          name: 'transferPHNMToTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_amount',
              type: 'uint256',
            },
          ],
          name: 'transferUSDTToTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'unpause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_newNaturalPrice',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: '_maxRedemptionTokenPrice',
              type: 'uint256',
            },
          ],
          name: 'updateTokenPrice',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'usdtToken',
          outputs: [
            {
              internalType: 'contract IERC20Metadata',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getTokenPrice: 'contracts/RedemptionVault/IRedemptionVault.sol',
        redeemTokens: 'contracts/RedemptionVault/IRedemptionVault.sol',
        updateTokenPrice: 'contracts/RedemptionVault/IRedemptionVault.sol',
        tokenFlowOrchestrator: 'contracts/Orchestrated.sol',
        paused: '@openzeppelin/contracts/utils/Pausable.sol',
      },
    },
    RevenueShareManager: {
      address: '0xa513E6E4b8f2a923D98304ec87F64353C4D5C853',
      abi: [
        {
          inputs: [
            {
              internalType: 'contract IERC20Metadata',
              name: '_phnmToken',
              type: 'address',
            },
            {
              internalType: 'contract IERC20Metadata',
              name: '_usdtToken',
              type: 'address',
            },
            {
              internalType: 'contract IRedemptionVault',
              name: '_redemptionVault',
              type: 'address',
            },
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AlreadyInitialized',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotContractManager',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotPriceManager',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotRevenueManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'EnforcedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ExpectedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'HoldersAndAmountsLengthMismatch',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotAllowedWhenDistributingRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotInitialized',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotRakeManagerContract',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingAgentRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingNetRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRewards',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenFundingRewardsManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenIdle',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenSettingPHNMPrice',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringLeftoverRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringPHNM',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ReentrancyGuardReentrantCall',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [],
          name: 'UsdtBalanceMismatch',
          type: 'error',
        },
        {
          inputs: [],
          name: 'WeeklyRakeCooldownNotMet',
          type: 'error',
        },
        {
          inputs: [],
          name: 'WeeklyUsdtTotalNotSet',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'holder',
              type: 'address',
            },
          ],
          name: 'DistributionCooldownNotMet',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'holder',
              type: 'address',
            },
          ],
          name: 'DistributionThresholdNotMet',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'gracePeriod',
              type: 'uint256',
            },
          ],
          name: 'GracePeriodUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'Initialized',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'LeftoverRevenueTransferred',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Paused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'price',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'maxPrice',
              type: 'uint256',
            },
          ],
          name: 'PriceCapped',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: '_rakeManager',
              type: 'address',
            },
          ],
          name: 'RakeManagerUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: '_redemptionVault',
              type: 'address',
            },
          ],
          name: 'RedemptionVaultUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'RevenueShareDistributed',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
          ],
          name: 'SafeTreasuryUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'newTokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'TokenFlowOrchestratorUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Unpaused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newTotal',
              type: 'uint256',
            },
          ],
          name: 'WeeklyNetRakeUpdated',
          type: 'event',
        },
        {
          inputs: [],
          name: 'BUFFER_SIZE',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'DISTRIBUTION_COOLDOWN',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'DISTRIBUTION_THRESHOLD',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'PRICE_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'REVENUE_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'WEEKLY_RAKE_UPDATE_COOLDOWN',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'WEEKLY_RAKE_UPDATE_GRACE_PERIOD',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address[]',
              name: 'holders',
              type: 'address[]',
            },
          ],
          name: 'distributeRevenueBatch',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'getTotalLastFourWeeksNetRake',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'gracePeriod',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'initialize',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'isInitialized',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          name: 'lastDistributionTimestamp',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'lastFourWeeksNetRake',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'lastNetRakeUpdateTimestamp',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'launchDate',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'paused',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'phnmToken',
          outputs: [
            {
              internalType: 'contract IERC20Metadata',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rakeManager',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'redemptionVault',
          outputs: [
            {
              internalType: 'contract IRedemptionVault',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'safeTreasury',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_newGracePeriod',
              type: 'uint256',
            },
          ],
          name: 'setGracePeriod',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_rakeManager',
              type: 'address',
            },
          ],
          name: 'setRakeManager',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract IRedemptionVault',
              name: '_redemptionVault',
              type: 'address',
            },
          ],
          name: 'setRedemptionVault',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
          ],
          name: 'setSafeTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'setTokenFlowOrchestrator',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startLeftoverRevenueTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startPHNMPriceUpdate',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startRevenueDistribution',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'tokenFlowOrchestrator',
          outputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'totalWeeks',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'transferLeftoverRevenueToTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'unpause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'updatePhnmTokenPrice',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_weeklyNetRake',
              type: 'uint256',
            },
          ],
          name: 'updateWeeklyNetRake',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'usdtToken',
          outputs: [
            {
              internalType: 'contract IERC20Metadata',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'weeklyNetRake',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        updateWeeklyNetRake: 'contracts/RevenueShareManager/IRevenueShareManager.sol',
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        paused: '@openzeppelin/contracts/utils/Pausable.sol',
        tokenFlowOrchestrator: 'contracts/Orchestrated.sol',
      },
    },
    RewardsManager: {
      address: '0x8A791620dd6260079BF849Dc5567aDC3F2FdC318',
      abi: [
        {
          inputs: [
            {
              internalType: 'contract IERC20',
              name: '_rewardsToken',
              type: 'address',
            },
            {
              internalType: 'contract IRedemptionManager',
              name: '_redemptionManager',
              type: 'address',
            },
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotRewardsDistributor',
          type: 'error',
        },
        {
          inputs: [],
          name: 'EnforcedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ExpectedPause',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'balance',
              type: 'uint256',
            },
          ],
          name: 'InsufficientFundsForReward',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotAllowedWhenDistributingRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingAgentRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingNetRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRewards',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenFundingRewardsManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenIdle',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenSettingPHNMPrice',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringLeftoverRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringPHNM',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Paused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'redemptionManager',
              type: 'address',
            },
          ],
          name: 'RedemptionManagerUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'RewardPaid',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'safeTreasury',
              type: 'address',
            },
          ],
          name: 'SafeTreasuryUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'newTokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'TokenFlowOrchestratorUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'TokensWithdrawn',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Unpaused',
          type: 'event',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'REWARDS_DISTRIBUTOR_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'endRewardsDistribution',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'currentCycleStartTime',
              type: 'uint256',
            },
          ],
          name: 'isPaymentDue',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          name: 'lastRewardPayment',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'paused',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'payReward',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address[]',
              name: 'users',
              type: 'address[]',
            },
            {
              internalType: 'uint256[]',
              name: 'amounts',
              type: 'uint256[]',
            },
          ],
          name: 'payRewardsBatch',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'redemptionManager',
          outputs: [
            {
              internalType: 'contract IRedemptionManager',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          name: 'rewardDebt',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rewardsToken',
          outputs: [
            {
              internalType: 'contract IERC20',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'safeTreasury',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract IRedemptionManager',
              name: '_redemptionManager',
              type: 'address',
            },
          ],
          name: 'setRedemptionManager',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_treasury',
              type: 'address',
            },
          ],
          name: 'setSafeTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'setTokenFlowOrchestrator',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startRewardsDistribution',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startRewardsFunding',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'tokenFlowOrchestrator',
          outputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'unpause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'withdrawTokens',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        paused: '@openzeppelin/contracts/utils/Pausable.sol',
        tokenFlowOrchestrator: 'contracts/Orchestrated.sol',
      },
    },
    Tables: {
      address: '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
      abi: [
        {
          inputs: [
            {
              internalType: 'contract IERC20',
              name: '_usdtToken',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [],
          name: 'CallerNotAdmin',
          type: 'error',
        },
        {
          inputs: [],
          name: 'CallerNotTableManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'CollectRakeFailed',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
          ],
          name: 'InactiveTable',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'buyIn',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'minBuyIn',
              type: 'uint256',
            },
          ],
          name: 'InsufficientBuyIn',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
          ],
          name: 'InsufficientRake',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'stake',
              type: 'uint256',
            },
          ],
          name: 'InvalidStake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'InvalidTable',
          type: 'error',
        },
        {
          inputs: [],
          name: 'JoinTableFailed',
          type: 'error',
        },
        {
          inputs: [],
          name: 'LeaveTableFailed',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'buyIn',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'maxBuyIn',
              type: 'uint256',
            },
          ],
          name: 'MaxBuyInExceeded',
          type: 'error',
        },
        {
          inputs: [],
          name: 'MaxBuyInMustBeGreaterThanMinBuyIn',
          type: 'error',
        },
        {
          inputs: [],
          name: 'MinBuyInCantBeZero',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
          ],
          name: 'PlayerAlreadyInTable',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
          ],
          name: 'PlayerNotInTable',
          type: 'error',
        },
        {
          inputs: [],
          name: 'RakeManagerNotSet',
          type: 'error',
        },
        {
          inputs: [],
          name: 'RebuyFailed',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ReentrancyGuardReentrantCall',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'buyIn',
              type: 'uint256',
            },
          ],
          name: 'JoinTable',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'stake',
              type: 'uint256',
            },
          ],
          name: 'LeaveTable',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'rake',
              type: 'uint256',
            },
          ],
          name: 'RakeCollected',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'newRakeManager',
              type: 'address',
            },
          ],
          name: 'RakeManagerChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'Rebuy',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'time',
              type: 'uint256',
            },
          ],
          name: 'SessionTimeChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'minBuyIn',
              type: 'uint256',
            },
          ],
          name: 'TableCreated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
          ],
          name: 'TableResumed',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
          ],
          name: 'TableStopped',
          type: 'event',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'TABLE_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'rake',
              type: 'uint256',
            },
          ],
          name: 'collectRake',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'minBuyIn',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'maxBuyin',
              type: 'uint256',
            },
          ],
          name: 'createTable',
          outputs: [
            {
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
          ],
          name: 'getStack',
          outputs: [
            {
              internalType: 'uint256',
              name: 'stack',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'buyIn',
              type: 'uint256',
            },
          ],
          name: 'joinTable',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'leaveTable',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'playerInTable',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
          ],
          name: 'playerTableSession',
          outputs: [
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'stack',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'timestamp',
                  type: 'uint256',
                },
              ],
              internalType: 'struct Tables.Session',
              name: '',
              type: 'tuple',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rakeManager',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'rebuy',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
          ],
          name: 'resumeTable',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'sessionTime',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newRakeManager',
              type: 'address',
            },
          ],
          name: 'setRakeManager',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'time',
              type: 'uint256',
            },
          ],
          name: 'setSessionTime',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
          ],
          name: 'stopTable',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
          ],
          name: 'tableBalance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
          ],
          name: 'tablePlayers',
          outputs: [
            {
              internalType: 'address[]',
              name: '',
              type: 'address[]',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          name: 'tableSessions',
          outputs: [
            {
              internalType: 'uint256',
              name: 'stack',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'timestamp',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'tables',
          outputs: [
            {
              internalType: 'uint256',
              name: 'minBuyIn',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'maxBuyIn',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'active',
              type: 'bool',
            },
            {
              internalType: 'uint256',
              name: 'balance',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'usdtToken',
          outputs: [
            {
              internalType: 'contract IERC20',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
      },
    },
    TokenFlowOrchestrator: {
      address: '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
      abi: [
        {
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotStateManager',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'state',
              type: 'uint256',
            },
          ],
          name: 'InvalidState',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'currentState',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'newState',
              type: 'uint256',
            },
          ],
          name: 'InvalidStateTransition',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'previousState',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newState',
              type: 'uint256',
            },
          ],
          name: 'StateTransition',
          type: 'event',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'STATE_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'currentState',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'getCurrentState',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'nextStateMapping',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_newState',
              type: 'uint256',
            },
          ],
          name: 'setState',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'stateOrder',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_newState',
              type: 'uint256',
            },
          ],
          name: 'transitionToState',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        getCurrentState: 'contracts/TokenFlowOrchestrator/ITokenFlowOrchestrator.sol',
        transitionToState: 'contracts/TokenFlowOrchestrator/ITokenFlowOrchestrator.sol',
        currentState: 'contracts/StateMachine.sol',
        nextStateMapping: 'contracts/StateMachine.sol',
        stateOrder: 'contracts/StateMachine.sol',
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
      },
    },
  },
  11155111: {
    MockUSDT: {
      address: '0xe789D2724f29D8De7FA00a4e133b9072d6A492D9',
      abi: [
        {
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'allowance',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'needed',
              type: 'uint256',
            },
          ],
          name: 'ERC20InsufficientAllowance',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'balance',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'needed',
              type: 'uint256',
            },
          ],
          name: 'ERC20InsufficientBalance',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'approver',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidApprover',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'receiver',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidReceiver',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidSender',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidSpender',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'Approval',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'Transfer',
          type: 'event',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
          ],
          name: 'allowance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'approve',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'balanceOf',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'decimals',
          outputs: [
            {
              internalType: 'uint8',
              name: '',
              type: 'uint8',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'name',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'symbol',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'totalSupply',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'transfer',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'transferFrom',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        allowance: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        approve: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        balanceOf: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        decimals: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        name: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        symbol: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        totalSupply: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        transfer: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        transferFrom: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
      },
    },
    PHNMToken: {
      address: '0x35F4a9FdcBddBaF7C3c3FF45f78Dc5BF70EeFDA1',
      abi: [
        {
          inputs: [
            {
              internalType: 'address',
              name: 'initialHolder',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'allowance',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'needed',
              type: 'uint256',
            },
          ],
          name: 'ERC20InsufficientAllowance',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'balance',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'needed',
              type: 'uint256',
            },
          ],
          name: 'ERC20InsufficientBalance',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'approver',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidApprover',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'receiver',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidReceiver',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidSender',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
          ],
          name: 'ERC20InvalidSpender',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
          ],
          name: 'OwnableInvalidOwner',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'OwnableUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'Approval',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'previousOwner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'OwnershipTransferred',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'Transfer',
          type: 'event',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'addToBlacklist',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'addToWhitelist',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'adminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
          ],
          name: 'allowance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'approve',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'balanceOf',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'decimals',
          outputs: [
            {
              internalType: 'uint8',
              name: '',
              type: 'uint8',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'isBlacklisted',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'isWhitelisted',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
          ],
          name: 'mintAdditionalSupply',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'name',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'removeFromBlacklist',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'removeFromWhitelist',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'renounceOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bool',
              name: 'approved',
              type: 'bool',
            },
          ],
          name: 'setDaoApproval',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'symbol',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'totalSupply',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'transfer',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'transferFrom',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'transferOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        allowance: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        approve: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        balanceOf: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        decimals: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        name: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        symbol: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        totalSupply: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        transfer: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        transferFrom: '@openzeppelin/contracts/token/ERC20/ERC20.sol',
        owner: '@openzeppelin/contracts/access/Ownable.sol',
        renounceOwnership: '@openzeppelin/contracts/access/Ownable.sol',
        transferOwnership: '@openzeppelin/contracts/access/Ownable.sol',
      },
    },
    RakeManager: {
      address: '0xB958929c3CFd26B70c0B3dB010802d2644c2B4B2',
      abi: [
        {
          inputs: [
            {
              internalType: 'contract IERC20',
              name: '_usdtToken',
              type: 'address',
            },
            {
              internalType: 'contract IRevenueShareManager',
              name: '_revenueShareManager',
              type: 'address',
            },
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'AddressNotInWhitelist',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotRakeDistributor',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotWhitelistManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'EnforcedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ExpectedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotAllAgentsHaveBeenPaid',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotAllowedInCurrentState',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotAllowedWhenDistributingRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingAgentRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingNetRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRewards',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenFundingRewardsManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenIdle',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenSettingPHNMPrice',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringLeftoverRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringPHNM',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
          ],
          name: 'RecipientNotAllowed',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ReentrancyGuardReentrantCall',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [],
          name: 'SendTokensFailed',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Paused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'RakeSharePayout',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'revenueShareManager',
              type: 'address',
            },
          ],
          name: 'RevenueShareManagerUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'safeTreasury',
              type: 'address',
            },
          ],
          name: 'SafeTreasuryUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'newTokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'TokenFlowOrchestratorUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'TokensTransferred',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Unpaused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'bool',
              name: 'isWhitelisted',
              type: 'bool',
            },
          ],
          name: 'WhitelistUpdated',
          type: 'event',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'RAKE_DISTRIBUTOR_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'WHITELIST_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'addToWhitelist',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'allAgentsHaveBeenPaid',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'distributeNetRake',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'distributingNetRake',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'isWhitelisted',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          name: 'lastDistributionTimestamp',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'paused',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'payAgent',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address[]',
              name: 'recipients',
              type: 'address[]',
            },
            {
              internalType: 'uint256[]',
              name: 'amounts',
              type: 'uint256[]',
            },
          ],
          name: 'payAgentsBatch',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'removeFromWhitelist',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'revenueShareManager',
          outputs: [
            {
              internalType: 'contract IRevenueShareManager',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'safeTreasury',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract IRevenueShareManager',
              name: '_revenueShareManager',
              type: 'address',
            },
          ],
          name: 'setRevenueShareManager',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
          ],
          name: 'setSafeTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'setTokenFlowOrchestrator',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startDistributingAgentRake',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startDistributingNetRake',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'tokenFlowOrchestrator',
          outputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'unpause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'usdtToken',
          outputs: [
            {
              internalType: 'contract IERC20',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        tokenFlowOrchestrator: 'contracts/Orchestrated.sol',
        paused: '@openzeppelin/contracts/utils/Pausable.sol',
      },
    },
    Redemption: {
      address: '0x1e26241d35e629001CFA17D344Ab72176b030937',
      abi: [
        {
          inputs: [
            {
              internalType: 'contract IERC20',
              name: '_phnmToken',
              type: 'address',
            },
            {
              internalType: 'contract IRedemptionManager',
              name: '_redemptionManager',
              type: 'address',
            },
            {
              internalType: 'contract IRedemptionVault',
              name: '_redemptionVault',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'FailedToIncreaseVaultAllowance',
          type: 'error',
        },
        {
          inputs: [],
          name: 'InsufficientUsdtInContract',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
          ],
          name: 'OwnableInvalidOwner',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'OwnableUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'allowance',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'redeemAmount',
              type: 'uint256',
            },
          ],
          name: 'RedeemExceedsAllowance',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'balance',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'redeemAmount',
              type: 'uint256',
            },
          ],
          name: 'RedeemExceedsBalance',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ReentrancyGuardReentrantCall',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'InsiderTokenRedeemed',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'previousOwner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'OwnershipTransferred',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'TokenRedeemed',
          type: 'event',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'phnmToken',
          outputs: [
            {
              internalType: 'contract IERC20',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'phnmAmount',
              type: 'uint256',
            },
          ],
          name: 'redeemTokens',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'redemptionManager',
          outputs: [
            {
              internalType: 'contract IRedemptionManager',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'redemptionVault',
          outputs: [
            {
              internalType: 'contract IRedemptionVault',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'renounceOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract IRedemptionManager',
              name: '_redemptionManager',
              type: 'address',
            },
          ],
          name: 'setRedemptionManager',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract IRedemptionVault',
              name: '_redemptionVault',
              type: 'address',
            },
          ],
          name: 'setRedemptionVault',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'transferOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        owner: '@openzeppelin/contracts/access/Ownable.sol',
        renounceOwnership: '@openzeppelin/contracts/access/Ownable.sol',
        transferOwnership: '@openzeppelin/contracts/access/Ownable.sol',
      },
    },
    RedemptionManager: {
      address: '0x37497992e0DE21FE62DDa19b3b513Fca87Aee06c',
      abi: [
        {
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AmountMustBeGreaterThanZero',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotAllowanceManager',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotInternalTokenDealsManager',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotOwner',
          type: 'error',
        },
        {
          inputs: [],
          name: 'InsufficientAllowance',
          type: 'error',
        },
        {
          inputs: [],
          name: 'InvalidAddress',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'dealIndex',
              type: 'uint256',
            },
          ],
          name: 'InvalidDealIndex',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [],
          name: 'StartTimeMustBeGreaterThanZero',
          type: 'error',
        },
        {
          inputs: [],
          name: 'TotalTimeMustBeGreaterOrEqualToVestingPeriod',
          type: 'error',
        },
        {
          inputs: [],
          name: 'TotalTimeMustBeGreaterThanZero',
          type: 'error',
        },
        {
          inputs: [],
          name: 'VestingPeriodMustBeGreaterThanZero',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'totalTimeInSeconds',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'vestingPeriodInSeconds',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'startTimeInSeconds',
              type: 'uint256',
            },
          ],
          name: 'InternalTokensDealGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'dealIndex',
              type: 'uint256',
            },
          ],
          name: 'InternalTokensDealRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'dealIndex',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newRemainingAmount',
              type: 'uint256',
            },
          ],
          name: 'InternalTokensDealUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'RedemptionAllowanceDecreased',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'RedemptionAllowanceIncreased',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newAllowance',
              type: 'uint256',
            },
          ],
          name: 'RedemptionAllowanceUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'TokenDealRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newPrice',
              type: 'uint256',
            },
          ],
          name: 'TokenPriceSet',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'TokensRedeemed',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'TokensTransferred',
          type: 'event',
        },
        {
          inputs: [],
          name: 'ALLOWANCE_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'INTERNAL_TOKEN_DEALS_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'allowanceSchedule',
          outputs: [
            {
              internalType: 'uint256',
              name: 'timestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'allowance',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'dealTokenInfos',
          outputs: [
            {
              internalType: 'uint256',
              name: 'originalAmount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'remainingAmount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'startTime',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'endTime',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'vestingPeriod',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'lastUpdateTime',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'isRevoked',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'decreaseRedemptionAllowance',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
          ],
          name: 'getDealTokenInfo',
          outputs: [
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'originalAmount',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'remainingAmount',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'startTime',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'endTime',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'vestingPeriod',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'lastUpdateTime',
                  type: 'uint256',
                },
                {
                  internalType: 'bool',
                  name: 'isRevoked',
                  type: 'bool',
                },
              ],
              internalType: 'struct InternalTokensDealLib.InternalTokensDeal[]',
              name: '',
              type: 'tuple[]',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
          ],
          name: 'getRedemptionAllowance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'originalAmount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'totalTimeInSeconds',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'vestingPeriodInSeconds',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'startTimeInSeconds',
              type: 'uint256',
            },
          ],
          name: 'grantInternalTokensDeal',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
          ],
          name: 'hasInsiderTokens',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'increaseRedemptionAllowance',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          name: 'redemptionAllowance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'dealIndex',
              type: 'uint256',
            },
          ],
          name: 'revokeInternalTokensDeal',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
          ],
          name: 'updateRedemptionAllowance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        decreaseRedemptionAllowance: 'contracts/RedemptionManager/IRedemptionManager.sol',
        getDealTokenInfo: 'contracts/RedemptionManager/IRedemptionManager.sol',
        getRedemptionAllowance: 'contracts/RedemptionManager/IRedemptionManager.sol',
        hasInsiderTokens: 'contracts/RedemptionManager/IRedemptionManager.sol',
        increaseRedemptionAllowance: 'contracts/RedemptionManager/IRedemptionManager.sol',
        updateRedemptionAllowance: 'contracts/RedemptionManager/IRedemptionManager.sol',
      },
    },
    RedemptionVault: {
      address: '0xD36c26C2E783715Fbe975B30171C696695F3581a',
      abi: [
        {
          inputs: [
            {
              internalType: 'contract IERC20',
              name: '_phnmToken',
              type: 'address',
            },
            {
              internalType: 'contract IERC20Extended',
              name: '_usdtToken',
              type: 'address',
            },
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotAdmin',
          type: 'error',
        },
        {
          inputs: [],
          name: 'CallerNotRedemptionContract',
          type: 'error',
        },
        {
          inputs: [],
          name: 'CallerNotRevenueShareManager',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotVaultManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'EnforcedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ExpectedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'InsufficientUSDTBalance',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotAllowedWhenDistributingRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingAgentRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingNetRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRewards',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenFundingRewardsManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenIdle',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenSettingPHNMPrice',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringLeftoverRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringPHNM',
          type: 'error',
        },
        {
          inputs: [],
          name: 'PHNMTransferFailed',
          type: 'error',
        },
        {
          inputs: [],
          name: 'RedeemExceedsLimit',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ReentrancyGuardReentrantCall',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [],
          name: 'TransferToTreasuryFailed',
          type: 'error',
        },
        {
          inputs: [],
          name: 'USDTTransferFailed',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Paused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'redemptionContract',
              type: 'address',
            },
          ],
          name: 'RedemptionContractUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'revenueShareManager',
              type: 'address',
            },
          ],
          name: 'RevenueShareManagerUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'safeTreasury',
              type: 'address',
            },
          ],
          name: 'SafeTreasuryUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'newTokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'TokenFlowOrchestratorUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newPrice',
              type: 'uint256',
            },
          ],
          name: 'TokenPriceUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'phnmAmount',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'usdtAmount',
              type: 'uint256',
            },
          ],
          name: 'TokensRedeemed',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'treasury',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'TokensTransferredToTreasury',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'treasury',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'USDTTransferredToTreasury',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Unpaused',
          type: 'event',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'VAULT_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'getTokenPrice',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'paused',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'phnmToken',
          outputs: [
            {
              internalType: 'contract IERC20',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: '_phnmAmount',
              type: 'uint256',
            },
          ],
          name: 'redeemTokens',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'redemptionContract',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'revenueShareManager',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'safeTreasury',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_redemption',
              type: 'address',
            },
          ],
          name: 'setRedemptionContract',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_revenueShareManager',
              type: 'address',
            },
          ],
          name: 'setRevenueShareManager',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
          ],
          name: 'setSafeTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'setTokenFlowOrchestrator',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startPHNMTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'tokenFlowOrchestrator',
          outputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'tokenPrice',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_amount',
              type: 'uint256',
            },
          ],
          name: 'transferPHNMToTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_amount',
              type: 'uint256',
            },
          ],
          name: 'transferUSDTToTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'unpause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_newPrice',
              type: 'uint256',
            },
          ],
          name: 'updateTokenPrice',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'usdtToken',
          outputs: [
            {
              internalType: 'contract IERC20Extended',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getTokenPrice: 'contracts/RedemptionVault/IRedemptionVault.sol',
        redeemTokens: 'contracts/RedemptionVault/IRedemptionVault.sol',
        updateTokenPrice: 'contracts/RedemptionVault/IRedemptionVault.sol',
        tokenFlowOrchestrator: 'contracts/Orchestrated.sol',
        paused: '@openzeppelin/contracts/utils/Pausable.sol',
      },
    },
    RevenueShareManager: {
      address: '0xff29d979772fEd1a64439Aa42F63fdfF60D20468',
      abi: [
        {
          inputs: [
            {
              internalType: 'contract IERC20Extended',
              name: '_phnmToken',
              type: 'address',
            },
            {
              internalType: 'contract IERC20Extended',
              name: '_usdtToken',
              type: 'address',
            },
            {
              internalType: 'contract IRedemptionVault',
              name: '_redemptionVault',
              type: 'address',
            },
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AlreadyInitialized',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotContractManager',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotPriceManager',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotRevenueManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'EnforcedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ExpectedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'HoldersAndAmountsLengthMismatch',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotAllowedWhenDistributingRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotInitialized',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotRakeManagerContract',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingAgentRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingNetRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRewards',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenFundingRewardsManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenIdle',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenSettingPHNMPrice',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringLeftoverRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringPHNM',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ReentrancyGuardReentrantCall',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [],
          name: 'UsdtBalanceMismatch',
          type: 'error',
        },
        {
          inputs: [],
          name: 'WeeklyRakeCooldownNotMet',
          type: 'error',
        },
        {
          inputs: [],
          name: 'WeeklyUsdtTotalNotSet',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'holder',
              type: 'address',
            },
          ],
          name: 'DistributionCooldownNotMet',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'holder',
              type: 'address',
            },
          ],
          name: 'DistributionThresholdNotMet',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'gracePeriod',
              type: 'uint256',
            },
          ],
          name: 'GracePeriodUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'Initialized',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'LeftoverRevenueTransferred',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Paused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: '_rakeManager',
              type: 'address',
            },
          ],
          name: 'RakeManagerUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: '_redemptionVault',
              type: 'address',
            },
          ],
          name: 'RedemptionVaultUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'recipient',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'RevenueShareDistributed',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
          ],
          name: 'SafeTreasuryUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'newTokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'TokenFlowOrchestratorUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Unpaused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newTotal',
              type: 'uint256',
            },
          ],
          name: 'WeeklyNetRakeUpdated',
          type: 'event',
        },
        {
          inputs: [],
          name: 'BUFFER_SIZE',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'DISTRIBUTION_COOLDOWN',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'DISTRIBUTION_THRESHOLD',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'PRICE_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'REVENUE_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'WEEKLY_RAKE_UPDATE_COOLDOWN',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'WEEKLY_RAKE_UPDATE_GRACE_PERIOD',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address[]',
              name: 'holders',
              type: 'address[]',
            },
          ],
          name: 'distributeRevenueBatch',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'gracePeriod',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'initialize',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          name: 'lastDistributionTimestamp',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'lastFourWeeksNetRake',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'lastNetRakeUpdateTimestamp',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'launchDate',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'paused',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'phnmToken',
          outputs: [
            {
              internalType: 'contract IERC20Extended',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rakeManager',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'redemptionVault',
          outputs: [
            {
              internalType: 'contract IRedemptionVault',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'safeTreasury',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_newGracePeriod',
              type: 'uint256',
            },
          ],
          name: 'setGracePeriod',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_rakeManager',
              type: 'address',
            },
          ],
          name: 'setRakeManager',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract IRedemptionVault',
              name: '_redemptionVault',
              type: 'address',
            },
          ],
          name: 'setRedemptionVault',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
          ],
          name: 'setSafeTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'setTokenFlowOrchestrator',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startLeftoverRevenueTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startPHNMPriceUpdate',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startRevenueDistribution',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'tokenFlowOrchestrator',
          outputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'totalWeeks',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'transferLeftoverRevenueToTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'unpause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'updatePhnmTokenPrice',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_weeklyNetRake',
              type: 'uint256',
            },
          ],
          name: 'updateWeeklyNetRake',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'usdtToken',
          outputs: [
            {
              internalType: 'contract IERC20Extended',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'weeklyNetRake',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        updateWeeklyNetRake: 'contracts/RevenueShareManager/IRevenueShareManager.sol',
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        paused: '@openzeppelin/contracts/utils/Pausable.sol',
        tokenFlowOrchestrator: 'contracts/Orchestrated.sol',
      },
    },
    RewardsManager: {
      address: '0xF64b66f4AC5A8D8f9C28bbDC020ff2F483B9128e',
      abi: [
        {
          inputs: [
            {
              internalType: 'contract IERC20',
              name: '_rewardsToken',
              type: 'address',
            },
            {
              internalType: 'contract IRedemptionManager',
              name: '_redemptionManager',
              type: 'address',
            },
            {
              internalType: 'address',
              name: '_safeTreasury',
              type: 'address',
            },
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotRewardsDistributor',
          type: 'error',
        },
        {
          inputs: [],
          name: 'EnforcedPause',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ExpectedPause',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'balance',
              type: 'uint256',
            },
          ],
          name: 'InsufficientFundsForReward',
          type: 'error',
        },
        {
          inputs: [],
          name: 'NotAllowedWhenDistributingRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingAgentRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingNetRake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenDistributingRewards',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenFundingRewardsManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenIdle',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenSettingPHNMPrice',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringLeftoverRevenue',
          type: 'error',
        },
        {
          inputs: [],
          name: 'OnlyAllowedWhenTransferringPHNM',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Paused',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'redemptionManager',
              type: 'address',
            },
          ],
          name: 'RedemptionManagerUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'RewardPaid',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'safeTreasury',
              type: 'address',
            },
          ],
          name: 'SafeTreasuryUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'newTokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'TokenFlowOrchestratorUpdated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'TokensWithdrawn',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'Unpaused',
          type: 'event',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'REWARDS_DISTRIBUTOR_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'endRewardsDistribution',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'currentCycleStartTime',
              type: 'uint256',
            },
          ],
          name: 'isPaymentDue',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          name: 'lastRewardPayment',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'paused',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'user',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'payReward',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address[]',
              name: 'users',
              type: 'address[]',
            },
            {
              internalType: 'uint256[]',
              name: 'amounts',
              type: 'uint256[]',
            },
          ],
          name: 'payRewardsBatch',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'redemptionManager',
          outputs: [
            {
              internalType: 'contract IRedemptionManager',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          name: 'rewardDebt',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rewardsToken',
          outputs: [
            {
              internalType: 'contract IERC20',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'safeTreasury',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract IRedemptionManager',
              name: '_redemptionManager',
              type: 'address',
            },
          ],
          name: 'setRedemptionManager',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '_treasury',
              type: 'address',
            },
          ],
          name: 'setSafeTreasury',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '_tokenFlowOrchestrator',
              type: 'address',
            },
          ],
          name: 'setTokenFlowOrchestrator',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startRewardsDistribution',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'startRewardsFunding',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'tokenFlowOrchestrator',
          outputs: [
            {
              internalType: 'contract ITokenFlowOrchestrator',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'unpause',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'withdrawTokens',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        paused: '@openzeppelin/contracts/utils/Pausable.sol',
        tokenFlowOrchestrator: 'contracts/Orchestrated.sol',
      },
    },
    Tables: {
      address: '0x099AB818447FDa5289141Ab7b9d67b3718A02622',
      abi: [
        {
          inputs: [
            {
              internalType: 'contract IERC20',
              name: '_usdtToken',
              type: 'address',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [],
          name: 'CallerNotAdmin',
          type: 'error',
        },
        {
          inputs: [],
          name: 'CallerNotTableManager',
          type: 'error',
        },
        {
          inputs: [],
          name: 'CollectRakeFailed',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
          ],
          name: 'InactiveTable',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'buyIn',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'minBuyIn',
              type: 'uint256',
            },
          ],
          name: 'InsufficientBuyIn',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
          ],
          name: 'InsufficientRake',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'stake',
              type: 'uint256',
            },
          ],
          name: 'InvalidStake',
          type: 'error',
        },
        {
          inputs: [],
          name: 'InvalidTable',
          type: 'error',
        },
        {
          inputs: [],
          name: 'JoinTableFailed',
          type: 'error',
        },
        {
          inputs: [],
          name: 'LeaveTableFailed',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'buyIn',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'maxBuyIn',
              type: 'uint256',
            },
          ],
          name: 'MaxBuyInExceeded',
          type: 'error',
        },
        {
          inputs: [],
          name: 'MaxBuyInMustBeGreaterThanMinBuyIn',
          type: 'error',
        },
        {
          inputs: [],
          name: 'MinBuyInCantBeZero',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
          ],
          name: 'PlayerAlreadyInTable',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
          ],
          name: 'PlayerNotInTable',
          type: 'error',
        },
        {
          inputs: [],
          name: 'RakeManagerNotSet',
          type: 'error',
        },
        {
          inputs: [],
          name: 'RebuyFailed',
          type: 'error',
        },
        {
          inputs: [],
          name: 'ReentrancyGuardReentrantCall',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'string',
              name: 'parameter',
              type: 'string',
            },
          ],
          name: 'ZeroAddress',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'buyIn',
              type: 'uint256',
            },
          ],
          name: 'JoinTable',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'stake',
              type: 'uint256',
            },
          ],
          name: 'LeaveTable',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'rake',
              type: 'uint256',
            },
          ],
          name: 'RakeCollected',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'address',
              name: 'newRakeManager',
              type: 'address',
            },
          ],
          name: 'RakeManagerChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'Rebuy',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'time',
              type: 'uint256',
            },
          ],
          name: 'SessionTimeChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'minBuyIn',
              type: 'uint256',
            },
          ],
          name: 'TableCreated',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
          ],
          name: 'TableResumed',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
          ],
          name: 'TableStopped',
          type: 'event',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'TABLE_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'rake',
              type: 'uint256',
            },
          ],
          name: 'collectRake',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'minBuyIn',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'maxBuyin',
              type: 'uint256',
            },
          ],
          name: 'createTable',
          outputs: [
            {
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
          ],
          name: 'getStack',
          outputs: [
            {
              internalType: 'uint256',
              name: 'stack',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'buyIn',
              type: 'uint256',
            },
          ],
          name: 'joinTable',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'leaveTable',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'playerInTable',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'player',
              type: 'address',
            },
          ],
          name: 'playerTableSession',
          outputs: [
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'stack',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'timestamp',
                  type: 'uint256',
                },
              ],
              internalType: 'struct Tables.Session',
              name: '',
              type: 'tuple',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rakeManager',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'rebuy',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
          ],
          name: 'resumeTable',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'sessionTime',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newRakeManager',
              type: 'address',
            },
          ],
          name: 'setRakeManager',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'time',
              type: 'uint256',
            },
          ],
          name: 'setSessionTime',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'id',
              type: 'uint256',
            },
          ],
          name: 'stopTable',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
          ],
          name: 'tableBalance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tableId',
              type: 'uint256',
            },
          ],
          name: 'tablePlayers',
          outputs: [
            {
              internalType: 'address[]',
              name: '',
              type: 'address[]',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          name: 'tableSessions',
          outputs: [
            {
              internalType: 'uint256',
              name: 'stack',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'timestamp',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'tables',
          outputs: [
            {
              internalType: 'uint256',
              name: 'minBuyIn',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'maxBuyIn',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'active',
              type: 'bool',
            },
            {
              internalType: 'uint256',
              name: 'balance',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'usdtToken',
          outputs: [
            {
              internalType: 'contract IERC20',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
      },
    },
    TokenFlowOrchestrator: {
      address: '0x435b908325cFb940653873Ad20408a979005Df95',
      abi: [
        {
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'AccessControlBadConfirmation',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          name: 'AccessControlEnforcedDefaultAdminDelay',
          type: 'error',
        },
        {
          inputs: [],
          name: 'AccessControlEnforcedDefaultAdminRules',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'defaultAdmin',
              type: 'address',
            },
          ],
          name: 'AccessControlInvalidDefaultAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              internalType: 'bytes32',
              name: 'neededRole',
              type: 'bytes32',
            },
          ],
          name: 'AccessControlUnauthorizedAccount',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotAdmin',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'caller',
              type: 'address',
            },
          ],
          name: 'CallerNotStateManager',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'state',
              type: 'uint256',
            },
          ],
          name: 'InvalidState',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'currentState',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'newState',
              type: 'uint256',
            },
          ],
          name: 'InvalidStateTransition',
          type: 'error',
        },
        {
          inputs: [
            {
              internalType: 'uint8',
              name: 'bits',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'SafeCastOverflowedUintDowncast',
          type: 'error',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminDelayChangeCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'effectSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminDelayChangeScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [],
          name: 'DefaultAdminTransferCanceled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint48',
              name: 'acceptSchedule',
              type: 'uint48',
            },
          ],
          name: 'DefaultAdminTransferScheduled',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'previousAdminRole',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'newAdminRole',
              type: 'bytes32',
            },
          ],
          name: 'RoleAdminChanged',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleGranted',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'sender',
              type: 'address',
            },
          ],
          name: 'RoleRevoked',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: 'previousState',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'newState',
              type: 'uint256',
            },
          ],
          name: 'StateTransition',
          type: 'event',
        },
        {
          inputs: [],
          name: 'DEFAULT_ADMIN_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'STATE_MANAGER_ROLE',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'acceptDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
          ],
          name: 'beginDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'cancelDefaultAdminTransfer',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
          ],
          name: 'changeDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'currentState',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'defaultAdminDelayIncreaseWait',
          outputs: [
            {
              internalType: 'uint48',
              name: '',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'getCurrentState',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
          ],
          name: 'getRoleAdmin',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'grantRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'hasRole',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'nextStateMapping',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdmin',
          outputs: [
            {
              internalType: 'address',
              name: 'newAdmin',
              type: 'address',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDefaultAdminDelay',
          outputs: [
            {
              internalType: 'uint48',
              name: 'newDelay',
              type: 'uint48',
            },
            {
              internalType: 'uint48',
              name: 'schedule',
              type: 'uint48',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'renounceRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes32',
              name: 'role',
              type: 'bytes32',
            },
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'revokeRole',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'rollbackDefaultAdminDelay',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_newState',
              type: 'uint256',
            },
          ],
          name: 'setState',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          name: 'stateOrder',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_newState',
              type: 'uint256',
            },
          ],
          name: 'transitionToState',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      inheritedFunctions: {
        getCurrentState: 'contracts/TokenFlowOrchestrator/ITokenFlowOrchestrator.sol',
        transitionToState: 'contracts/TokenFlowOrchestrator/ITokenFlowOrchestrator.sol',
        currentState: 'contracts/StateMachine.sol',
        nextStateMapping: 'contracts/StateMachine.sol',
        stateOrder: 'contracts/StateMachine.sol',
        DEFAULT_ADMIN_ROLE: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        acceptDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        beginDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        cancelDefaultAdminTransfer: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        changeDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        defaultAdminDelayIncreaseWait: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        getRoleAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        grantRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        hasRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        owner: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdmin: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        pendingDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        renounceRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        revokeRole: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        rollbackDefaultAdminDelay: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
        supportsInterface: '@openzeppelin/contracts/access/extensions/AccessControlDefaultAdminRules.sol',
      },
    },
  },
} as const;

export default deployedContracts satisfies GenericContractsDeclaration;
