import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';
import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  OnChangeFn,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { gettransactionsRest } from '@/services/transactions/transactions-service';
import DebouncedInput from '@/components/DebounceInput';
import { SortOrder } from '@/common/sortOrder';
import { GetTransactionDto } from '@/services/transactions/transactions-types';
import Paginator from '../common/paginator';
import ROUTES from '@/routes/routes-enums';
import { useNavigate } from 'react-router-dom';
import Flatpickr from 'react-flatpickr';
import TransactionsFilter from './transactions-filter';
import { trimCell } from '@/common/forms/TrimCell';
import config from '@/config/index';

interface TransactionCols {
  header: string;
  type: string;
  accessorKey?: string;
  accessor?: (row: any) => any;
  isVisible?: boolean;
}

const Transactions: React.FC = () => {
  const _queryClient = useQueryClient();
  const [take, setTake] = useState(25);
  const [skip, setSkip] = useState(0);
  const navigate = useNavigate();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const [isDebouncing, setIsDebouncing] = useState<boolean>(false);
  const [getTransactionDto, setGetTransactionDto] = useState<GetTransactionDto>({ skip, take });

  const [modal_backdrop, setmodal_backdrop] = useState<boolean>(false);

  const { data, isLoading, error } = useQuery({
    queryKey: ['transactionData', getTransactionDto],
    queryFn: () => gettransactionsRest(getTransactionDto),
    staleTime: 60000,
  });

  useEffect(() => {
    setGetTransactionDto((prev) => ({ ...prev, skip, take }));
  }, [skip, take]);

  const pageData = {
    transactions: data?.transactions.length || 0,
    totalCount: data?.totalCount || 0,
    skip: skip,
    take: take,
  };

  const columns: TransactionCols[] = useMemo(
    () => [
      { accessorKey: 'txId', header: 'Tx ID', type: 'string' },
      {
        accessor: (row: any) => row.table.name, // Corrected accessor function
        header: 'Table Name',
        type: 'string',
      },
      {
        accessor: (row: any) => row.user.alias, // Corrected accessor function
        header: 'User Alias',
        type: 'string',
      },
      { accessorKey: 'type', header: 'Type', type: 'string' },
      { accessorKey: 'status', header: 'Status', type: 'string' },
      { accessorKey: 'amount', header: 'Amount', type: 'number' },
      { accessorKey: 'createdAt', header: 'Date', type: 'string' },
      //{ accessorKey: "updatedAt", header: "Updated At", type: "string" },
    ],
    [],
  );

  const columnVisibility = useMemo(
    () =>
      columns.reduce((acc: VisibilityState, column: TransactionCols) => {
        if (typeof column.accessorKey === 'string') {
          acc[column.accessorKey] = column?.isVisible !== false;
        }
        return acc;
      }, {}),
    [columns],
  );

  const tableInstance = useReactTable({
    data: data?.transactions || [],
    columns,
    state: {
      columnFilters,
      columnVisibility,
      sorting: sortingState,
    },
    onColumnFiltersChange: setColumnFilters as OnChangeFn<ColumnFiltersState>,
    onSortingChange: setSortingState as OnChangeFn<SortingState>,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  const handleSearch = useCallback((value: string | number) => {
    setIsDebouncing(true);
    setSkip(0);
    setGetTransactionDto((prev: GetTransactionDto) => ({ ...prev, type: value.toString() }));
    setIsDebouncing(false);
  }, []);

  const handleSort = useCallback((columnId: string) => {
    setGetTransactionDto((prev) => {
      const newSorts = [...(prev.sorts || [])];
      const existingSortIndex = newSorts.findIndex((sort) => sort.field === columnId);
      if (existingSortIndex !== -1) {
        if (newSorts[existingSortIndex].order === SortOrder.asc) {
          newSorts[existingSortIndex].order = SortOrder.desc;
        } else {
          newSorts.splice(existingSortIndex, 1);
        }
      } else {
        newSorts.push({ field: columnId, order: SortOrder.asc });
      }
      return { ...prev, sorts: newSorts };
    });
  }, []);

  const [toDate, setToDate] = useState<string>(new Date().toISOString());

  // Correct way to set fromDate to yesterday's date
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const [fromDate, setFromDate] = useState<string>(yesterday.toISOString());

  const handleDateChange = (dates: Date[]) => {
    if (dates.length === 2) {
      const [start, end] = dates.map((date) => date.toISOString());
      setFromDate(start);
      setToDate(end);
    }
  };

  if (error) {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Alert color="danger">Error when fetching transaction data</Alert>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  const eventTypeColor = (eventType: string) => {
    switch (eventType) {
      case 'CREATE_TABLE':
        return 'primary';

      case 'LEAVE_TABLE':
      case 'REBUY':
        return 'secondary';

      default:
        return '';
    }
  };

  function formatISOToDate(isoString: Date | string | undefined, full = false) {
    if (!isoString || String(isoString) === '') return '';
    const newDate = new Date(String(isoString));
    return newDate.toISOString().split('T')[0] + (full ? ` ${newDate.toTimeString().split(' ')[0]}` : '');
  }

  const getSortIcon = (columnId: string) => {
    const sort = getTransactionDto.sorts?.find((sort) => sort.field === columnId);
    if (sort) {
      return sort.order === SortOrder.asc ? (
        <i className="ri-arrow-up-s-line" />
      ) : (
        <i className="ri-arrow-down-s-line" />
      );
    } else {
      return <i className="ri-arrow-up-down-line ms-1" />;
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="gy-2 mb-2">
            <div className="d-flex justify-content-between mb-3 flex-row gap-3">
              <div className="d-flex flex-rows gap-3">
                <DebouncedInput
                  type="text"
                  className="form-control"
                  placeholder="Search txId..."
                  onChange={handleSearch}
                  value={getTransactionDto?.tableId || ''}
                  isLoading={isDebouncing}
                />
                <button
                  type="button"
                  className="btn btn-soft-primary text-white"
                  onClick={() => setmodal_backdrop(true)}
                >
                  <i className="ri-filter-3-line me-1 align-middle"></i> Filter
                </button>
              </div>

              <TransactionsFilter
                modal_backdrop={modal_backdrop}
                tog_backdrop={() => setmodal_backdrop(!modal_backdrop)}
                getTransactionDto={getTransactionDto}
                setGetTransactionDto={setGetTransactionDto}
              />
              <Col lg={4}>
                <div className="input-group">
                  <Flatpickr
                    className="form-control dash-filter-time-picker border-0 shadow"
                    options={{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                      mode: 'range',
                      defaultDate: [fromDate, toDate],
                    }}
                    onChange={handleDateChange}
                  />
                  <div className="input-group-text bg-primary border-primary text-white">
                    <i className="ri-calendar-2-line"></i>
                  </div>
                </div>
              </Col>
            </div>

            {/* TransactionFilter component can be added here */}
            <Card className="card mb-2">
              <CardBody className="card-body">
                <table className="table-hover table-centered table-nowrap mb-0 table align-middle">
                  <thead>
                    <tr>
                      <th onClick={() => handleSort('txId')}>
                        <span>
                          Tx Id
                          {getSortIcon('txId')}
                        </span>
                      </th>
                      <th onClick={() => handleSort('table.name')}>
                        <span>
                          Table Name
                          {getSortIcon('table.name')}
                        </span>
                      </th>
                      <th onClick={() => handleSort('user.alias')}>
                        <span>
                          User Alias
                          {getSortIcon('user.alias')}
                        </span>
                      </th>
                      <th onClick={() => handleSort('type')}>
                        <span>
                          Type
                          {getSortIcon('type')}
                        </span>
                      </th>
                      <th onClick={() => handleSort('status')}>
                        <span>
                          Status
                          {getSortIcon('status')}
                        </span>
                      </th>
                      <th onClick={() => handleSort('amount')}>
                        <span>
                          Amount
                          {getSortIcon('amount')}
                        </span>
                      </th>
                      <th onClick={() => handleSort('createdAt')}>
                        <span>
                          Date
                          {getSortIcon('createdAt')}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading &&
                      tableInstance.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                          <td>
                            {row.original?.txId === undefined || row.original?.txId === null ? (
                              ''
                            ) : (
                              <a
                                href={`${config.blockExplorerUrl}/tx/${row.original.txId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-decoration-underline"
                              >
                                {trimCell(String(row.original.txId))}
                              </a>
                            )}
                          </td>
                          <td>
                            {row.original?.tableName === undefined || row.original?.tableName === null ? (
                              ''
                            ) : (
                              <p
                                onClick={() => navigate(`${ROUTES.TABLE}/${row.original.tableId}`)}
                                className="text-decoration-underline"
                              >
                                {trimCell(String(row.original?.tableName))}
                              </p>
                            )}
                          </td>
                          <td>
                            {row.original?.userAlias === undefined || row.original?.userAlias === null ? (
                              ''
                            ) : (
                              <p
                                onClick={() => navigate(`${ROUTES.USER}/${row.original.userId}`)}
                                className="text-decoration-underline"
                              >
                                {trimCell(String(row.original?.userAlias))}
                              </p>
                            )}
                          </td>
                          <td>
                            <span className={`badge badge-${eventTypeColor(row.original.type)}`}>
                              {row.original.type}
                            </span>
                          </td>
                          <td>
                            <span className={`text-${row.original.status === 'FAILED' ? 'danger' : 'white'}`}>
                              {row.original.status}
                            </span>
                          </td>
                          <td>{row.original.amount}</td>
                          <td>{formatISOToDate(row.original.createdAt, true)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Row>
          <Row className="g-3 text-sm-start mt-2 text-center align-middle">
            <Paginator
              take={take}
              skip={skip}
              showing={pageData.transactions}
              totalCount={pageData.totalCount}
              setTake={setTake}
              setSkip={setSkip}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Transactions;
