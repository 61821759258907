import avatar from '@/assets/avatar.svg';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { usePrivy } from '@privy-io/react-auth';
import ROUTES from '@/routes/routes-enums';

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const { authenticated, logout, user } = usePrivy();
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  const handleLogOut = async () => {
    await logout();
    navigate(ROUTES.AUTHENTICATION, { replace: true });
  };

  return !authenticated ? (
    <></>
  ) : (
    <React.Fragment>
      <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
        <DropdownToggle tag="button" type="button" className="btn">
          <img className="rounded-circle header-profile-user" src={avatar} alt="Header Avatar" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {user?.email && (
            <DropdownItem className="p-2">
              <i className="mdi mdi-account-circle text-muted fs-16 me-1 align-middle"></i>
              <span className="align-middle">{user.email.address}</span>
            </DropdownItem>
          )}
          <DropdownItem className="p-2">
            <button onClick={handleLogOut} className="btn btn-primary m-1">
              <i className="ri-logout-circle-r-line me-2"></i>
              Log Out
            </button>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
