import { AgreementsStrategyConfig } from './agreements-config.interface';
import { developConfig } from './develop.config';
import { localConfig } from './local.config';
import { productionConfig } from './production.config';
import { stagingConfig } from './staging.config';

let agreementsConfig: AgreementsStrategyConfig;

switch (process.env.REACT_APP_NODE_ENV) {
  case 'production':
    agreementsConfig = productionConfig;
    break;
  case 'staging':
    agreementsConfig = stagingConfig;
    break;
  case 'development':
    agreementsConfig = developConfig;
    break;
  default:
    // agreementsConfig = stagingConfig;
    agreementsConfig = localConfig;
}

export default agreementsConfig;
