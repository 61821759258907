import { api } from '@/services/api';

import { UpdateTransactionDto } from '../transactions/transactions-types';
import { GetTableDto, TableResponseDto } from './table-filters';
import { MixedTableDto, Session, TableDto, TableInfoDto, TableQueryDto } from './table-types';

export const updateTableTransactionRest = async (transactionId: string, params: UpdateTransactionDto) => {
  const response = await api.put(`/v1/admin/transactions/${transactionId}`, {
    params,
  });
  return response.data;
};

export const recoverTableRest = async (tableId: string, amount: string) => {
  const response = await api.post(`/v1/admin/table/${tableId}/recover`, {
    amount,
  });
  return response.data;
};

export const refundTableRest = async (tableId: string, amount: string) => {
  const response = await api.post(`/v1/admin/table/${tableId}/refund`, {
    amount,
  });
  return response.data;
};

export const fetchTablesAllRest = async (GetTableDto: GetTableDto): Promise<TableResponseDto> => {
  const response = await api.get<TableResponseDto>(`/v1/admin/table`, {
    params: { ...GetTableDto },
  });
  return response.data;
};

export const fetchTablesRest = async (tablesIds: string[]): Promise<TableResponseDto> => {
  const response = await api.get<TableResponseDto>('/v1/admin/table', {
    params: { tablesIds: tablesIds },
  });
  return response.data;
};

export const fetchTableByIdRest = async (id: string) => {
  const response = await api.get(`/v1/admin/table/${id}/details`);
  return response.data;
};

export const fetchTableByIdSessions = async (id: string) => {
  const response = await api.get(`/v1/admin/table/${id}/sessions`);
  return response.data;
};

export const deletePlayersContractRest = async (id: string) => {
  const response = await api.delete(`/v1/admin/table/${id}/contract-players`);
  return response.data;
};

export const deletePlayerContractRest = async (tableId: string, amount: string, address: string) => {
  const response = await api.post(`/v1/admin/table/${tableId}/players/kick`, { amount, address });
  return response.data;
};

export const getPlayersContractRest = async (id: string) => {
  const response = await api.get<string[]>(`/v1/admin/table/${id}/contract-players`);
  return response.data;
};

export const createTableRest = async (params: Omit<MixedTableDto, 'id'>) => {
  return await api.post<Session>('v1/admin/table', {
    params: params,
  });
};

export const updateTableRest = async (params: MixedTableDto) => {
  return await api.put<TableInfoDto>(`/v1/admin/table/${params.id}`, {
    params: params,
  });
};

export const restartTableRest = async (tableId: string) => {
  return await api.post<any>(`/v1/admin/table/${tableId}/restart`);
};

export const startTableRest = async (tableId: string) => {
  return await api.post<any>(`/v1/admin/table/${tableId}/start`);
};

export const stopTableRest = async (tableId: string, force: boolean) => {
  return await api.post<any>(`/v1/admin/table/${tableId}/stop`, {
    params: force,
  });
};

export const parseTableRestResponse = (table: any): TableDto => {
  return {
    id: table?.id,
    name: table?.name,
    game: table?.game,
    type: table?.type,
    maxPlayers: table?.maxPlayers,
    rakePercent: table?.rakePercent,
    rakeCap: table?.rakeCap,
    rakeCapHU: table?.rakeCapHU,
    timeoutInSeconds: table?.timeoutInSeconds,
    isRealMoney: table?.isRealMoney,
    templateId: table?.templateId,
    variants: table?.variants,
    ante: table?.ante,
    smallBlind: table?.smallBlind,
    bigBlind: table?.smallBlind,
    smallBet: table?.smallBet,
    bigBet: table?.bigBet,
    bettingCap: table?.bettingCap,
    bringIn: table?.bringIn,
    minBuyin: table?.minBuyin,
    maxBuyin: table?.maxBuyin,
    isPublic: table?.isPublic,
    handsPerGame: table?.handsPerGame,
  };
};

export const tablePlayersPerDayRest = async (query: TableQueryDto) => {
  const response = await api.get<any>(`/v1/admin/table/analytics/players-per-day`, {
    params: query,
  });
  return response.data;
};

export const tableActivePlayersStatsRest = async () => {
  const response = await api.get<any>(`/v1/admin/table/analytics/active-players-stats`);
  return response.data;
};

export const tableWaitingPlayersStatsRest = async () => {
  const response = await api.get<any>(`/v1/admin/table/analytics/waiting-players-stats`);
  return response.data;
};

export const fetchTableDiffsRest = async () => {
  const response = await api.get<any>(`/v1/admin/table/table-sync`);
  return response.data;
};

export const fetchContractDiffsRest = async () => {
  const response = await api.get<any>(`/v1/admin/table/contract-sync`);
  return response.data;
};

export const transferFromTableRest = async (params: {
  contractAddress: string;
  contractId: string;
  amount: number;
  transactionType: string;
}) => {
  const response = await api.post<any>(`/v1/admin/table/transfer-from-table`, {
    ...params,
  });
  return response.data;
};

export const fundTableRest = async (params: {
  contractAddress: string;
  contractId: string;
  amount: number;
  transactionType: string;
}) => {
  const response = await api.post<any>(`/v1/admin/table/fund-table`, {
    ...params,
    approve: true,
  });
  return response.data;
};
