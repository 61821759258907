import React, { useMemo } from 'react';
import { Placeholder, Spinner } from 'reactstrap';
import { formatTokenBalance } from '@/common/utils/formatTokenBalance';
import { formatCurrency } from '@/common/utils/formatCurrency';

interface TokenBalanceProps {
  balance?: bigint | undefined;
  simpleFormat?: boolean | undefined;
  tokenDecimals?: number | undefined;
  formatDecimals?: number | undefined;
  isLoading?: boolean | undefined;
  tokenSymbol?: string | undefined;
  onClickRefresh?: () => undefined;
}

const TokenBalance: React.FC<TokenBalanceProps> = ({
  balance,
  tokenDecimals = 18,
  formatDecimals = 2,
  simpleFormat = false,
  isLoading = false,
  tokenSymbol,
  onClickRefresh,
}) => {
  const formattedBalance = useMemo(() => {
    const formattedTokenBalance = formatTokenBalance(balance, tokenDecimals);
    return formatCurrency(Number(formattedTokenBalance), formatDecimals);
  }, [balance, formatDecimals, tokenDecimals]);

  if (simpleFormat) {
    return (
      <div className="d-flex justify-content-end">
        {isLoading ? <Spinner size="sm" /> : <p className="w-100 text-end">{formattedBalance}</p>}
      </div>
    );
  }

  return (
    <div>
      <div className="d-flex align-items-center gap-1">
        <p className="fw-bold mb-0">{tokenSymbol} Balance</p>
        {isLoading ? (
          <Spinner size="sm" />
        ) : (
          <i className="ri-refresh-line ri-lg cursor-pointer" aria-hidden="true" onClick={onClickRefresh} />
        )}
      </div>
      {isLoading ? (
        <Placeholder animation="glow" tag="p">
          <Placeholder xs={3} />
        </Placeholder>
      ) : (
        <p className="mb-0 break-all text-lg font-medium">{formattedBalance}</p>
      )}
    </div>
  );
};

export default TokenBalance;
