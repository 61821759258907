import { Navigate } from 'react-router-dom';

import Authentication from '@/pages/auth/Authentication';

import AnalyticsOverview from '@/pages/analytics/analytics-overview';

import Tables from '@/pages/table/tables';
import TableDetails from '@/pages/table/table-details';
import UserTable from '@/pages/user/users';

import ROUTES from './routes-enums';
import TableEdit from '@/pages/table/table-update';
import UserEdit from '@/pages/user/user-edit';

import RakeAnalytics from '@/pages/rake/rake-analytics';
import RakeTable from '@/pages/rake/rake-per-table';
import RakePlayer from '@/pages/rake/rake-per-player';

import Templates from '@/pages/template/templates';
import TemplateEdit from '@/pages/template/template-edit';
import TemplateCreate from '@/pages/template/template-create';

import BlindStructureCreate from '@/pages/template/blind-structure-create';

import UserAnalytics from '@/pages/user/user-analytics';

import TableAnalytics from '@/pages/table/table-analytics';
import TableCreate from '@/pages/table/table-create';

import PropPlayers from '@/pages/prop-players/prop-players';

import Transactions from '@/pages/transactions/transactions';
import GameStructure from '@/pages/template/mixed-game';
import MixedGameCreate from '@/pages/template/mixed-game-create';
import MixedGameUpdate from '@/pages/template/mixed-game-update';
import Agreement from '@/pages/rake/agreements';
import AgreementsCreate from '@/pages/rake/agreements-create';
import BlindStructure from '@/pages/template/blind-structure';
import BlindStructureUpdate from '@/pages/template/blind-structure-update';
import RakeSharePayout from '@/pages/rake/rake-share-payouts';
import RakeSharePayoutPerPlayer from '@/pages/rake/rake-share-payouts-player';
import RewardsPayouts from '@/pages/rake/rewards-payouts';
import RewardsPayoutsPerPlayers from '@/pages/rake/rewards-payouts-player';
import RakeBackPayout from '@/pages/rake/rake-back-payouts';
import RakeBackPayoutPerPlayer from '@/pages/rake/rake-back-payouts-player';
import InternalTokensDeals from '@/pages/internal-tokens-deals/internal-tokens-deals';
import TokenFlowOrchestrator from '@/pages/token-flow-orchestrator/token-flow-orchestrator';
import TableHands from '@/pages/table/table-hands';
import ContractBalances from '@/pages/token/ContractBalances';
import TableSync from '@/pages/table/table-sync';
import ContractSync from '@/pages/table/contract-sync';

const authRoutes = [
  { path: ROUTES.AUTHENTICATION, component: <Authentication /> },
  { path: ROUTES.LOGOUT, component: <Authentication /> },
];

const authProtectedRoutes = [
  { path: ROUTES.ROOT, component: <AnalyticsOverview /> },
  { path: ROUTES.TABLES, component: <Tables /> },
  { path: `${ROUTES.TABLE}/:tableId`, component: <TableDetails /> },

  { path: ROUTES.TABLE_CREATE, component: <TableCreate /> },
  { path: ROUTES.TABLE_ANALYTICS, component: <TableAnalytics /> },
  { path: ROUTES.TABLE_HANDS, component: <TableHands /> },

  { path: `${ROUTES.TABLE_EDIT}/:tableId`, component: <TableEdit /> },

  { path: `${ROUTES.TABLE_SYNC}`, component: <TableSync /> },
  { path: `${ROUTES.CONTRACT_SYNC}`, component: <ContractSync /> },

  { path: `${ROUTES.MIXED_GAME_CREATE}`, component: <MixedGameCreate /> },

  { path: `${ROUTES.PARAMETERS_MIXED_GAMES_STRUCTURE}`, component: <GameStructure /> },
  { path: `${ROUTES.PARAMETERS_MIXED_GAMES_STRUCTURE_CREATE}`, component: <MixedGameCreate /> },
  { path: `${ROUTES.PARAMETERS_MIXED_GAMES_STRUCTURE_UPDATE}/:gameId`, component: <MixedGameUpdate /> },

  { path: ROUTES.PARAMETERS_RAKE_STRUCTURE, component: <BlindStructure /> },
  { path: ROUTES.PARAMETERS_RAKE_STRUCTURE_CREATE, component: <BlindStructureCreate /> },
  { path: `${ROUTES.PARAMETERS_RAKE_STRUCTURE_UPDATE}/:rakeStructureId`, component: <BlindStructureUpdate /> },

  { path: ROUTES.TEMPLATES, component: <Templates /> },
  { path: ROUTES.TEMPLATE_CREATE, component: <TemplateCreate /> },
  { path: `${ROUTES.TEMPLATE}/:templateId`, component: <TemplateEdit /> },

  { path: ROUTES.PROP_PLAYERS, component: <PropPlayers /> },

  { path: ROUTES.USERS, component: <UserTable /> },
  { path: `${ROUTES.USER_EDIT}/:userId`, component: <UserEdit /> },
  { path: ROUTES.USER_ANALYTICS, component: <UserAnalytics /> },

  { path: ROUTES.USER_DEALS, component: <InternalTokensDeals /> },

  { path: ROUTES.AGREEMENTS, component: <Agreement /> },
  { path: ROUTES.AGREEMENTS_CREATE, component: <AgreementsCreate /> },

  { path: ROUTES.RAKE_SHARE, component: <RakeSharePayout /> },
  { path: `${ROUTES.RAKE_SHARE_PER_PLAYER}/:userId`, component: <RakeSharePayoutPerPlayer /> },

  { path: ROUTES.RAKE_BACK, component: <RakeBackPayout /> },
  { path: `${ROUTES.RAKE_BACK_PER_PLAYER}/:userId`, component: <RakeBackPayoutPerPlayer /> },

  { path: ROUTES.RAKE_REWARDS, component: <RewardsPayouts /> },
  { path: `${ROUTES.RAKE_REWARDS_PER_PLAYER}/:userId`, component: <RewardsPayoutsPerPlayers /> },

  { path: `${ROUTES.TOKEN_FLOW_ORCHESTRATOR_WIZARD}`, component: <TokenFlowOrchestrator /> },
  { path: ROUTES.CONTRACT_BALANCES, component: <ContractBalances /> },

  { path: ROUTES.RAKE_ANALYTICS, component: <RakeAnalytics /> },
  { path: ROUTES.RAKE_PLAYER, component: <RakePlayer /> },
  { path: ROUTES.RAKE_TABLE, component: <RakeTable /> },

  { path: ROUTES.TRANSACTIONS, component: <Transactions /> },

  { path: '*', component: <Navigate to={ROUTES.TABLE_ANALYTICS} /> },
];

const publicRoutes = [{ path: '*', component: <Navigate to={ROUTES.AUTHENTICATION} /> }];

export { publicRoutes, authRoutes, authProtectedRoutes };
